import { FC } from 'react'
import { MatterListItem } from './MatterListItem'
import { List, theme } from 'antd'
import {
  Matter,
  Nullable,
  sortByMostRecent,
  sortByMostRecentPinned,
} from '@skribe/web/core'
import { useNavigate } from 'react-router-dom'
import { useUserContext } from '../User/UserProvider'
import { generateEmptyPlaceholder } from '../components'

const { useToken } = theme
export type MatterListProps = {
  matters: Matter[]
  selected: Nullable<Matter>
  onDelete: (id: string | undefined) => Promise<void>
}

export const MatterList: FC<MatterListProps> = ({
  matters,
  selected,
  onDelete,
  ...props
}) => {
  const navigate = useNavigate()
  const { groups, user } = useUserContext()

  const sortedByMostRecent = sortByMostRecent(matters)
  const sortedMatters = sortByMostRecentPinned(
    sortedByMostRecent,
    user.pinnedMatters || [],
  )

  const { token } = useToken()

  return (
    <div className="matter-list-container">
      <List
        {...props}
        bordered
        rowKey="id"
        size="large"
        locale={generateEmptyPlaceholder('No Matters')}
        dataSource={sortedMatters}
        renderItem={matter => (
          <MatterListItem
            matter={matter}
            userGroups={groups}
            themeToken={token}
            selected={matter.id === selected?.id}
            onSelect={() => navigate(`/matters/${matter.id}`)}
            onMove={() => navigate(0)}
            onDelete={onDelete}
          />
        )}
      />
    </div>
  )
}
