import React from 'react'
import { Button, Drawer, Tabs } from 'antd'
import {
  useUpgradeTranscriptDrawer,
  TAB,
  upgradeTranscriptDrawerProps,
} from './useUpgradeTranscriptDrawer'
import {
  TrialComponent,
  defaultDrawerProps,
  defaultTabsProps,
} from '../../components'
import { useIsTrial } from '../../User/useIsTrial'
import { trackEvent } from '@skribe/web/tracking'
import { TAB as TRANSCRIPT_TYPES } from '../transcriptStrings'
import { UpgradeTranscriptionForm } from './UpgradeTranscriptionForm'
import { toTranscript } from '@skribe/web/core'

export const UpgradeTranscriptDrawer: React.FC<
  upgradeTranscriptDrawerProps
> = props => {
  const isTrial = useIsTrial()

  const {
    onSubmit,
    onClose,
    openUpgrade,
    tab,
    isLoading,
    isOrderFinished: isUpgradeDisabled,
  } = useUpgradeTranscriptDrawer(props)

  const onTrialUpgrade = () => {
    trackEvent('wantsHumanProofRead', { isTrial: true })
  }
  return (
    <>
      <Button
        data-testid="openUpgradeTranscriptDrawer"
        onClick={openUpgrade}
        type="primary"
        loading={isLoading}
        disabled={isUpgradeDisabled}
        style={{ margin: '13rem 0rem', width: '100%' }}
      >
        Order {TRANSCRIPT_TYPES.Human}
      </Button>
      <Drawer
        {...defaultDrawerProps}
        onClose={onClose}
        open={!!tab}
        className="tab-drawer"
      >
        <Tabs
          {...defaultTabsProps}
          defaultActiveKey={tab}
          items={[
            {
              key: TAB.UPGRADE,
              label: isTrial ? (
                <TrialComponent
                  onTooltip={onTrialUpgrade}
                  children={TAB.UPGRADE}
                />
              ) : (
                TAB.UPGRADE
              ),
              children: (
                <UpgradeTranscriptionForm
                  onSubmit={onSubmit}
                  onClose={onClose}
                  transcript={toTranscript(props.transcript)}
                  errorMessage="Something wrong happened upgrading your form"
                />
              ),
            },
          ]}
        />
      </Drawer>
    </>
  )
}
