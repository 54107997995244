import { Link } from 'react-router-dom'
import { Button, Tooltip, Image } from 'antd'
import { isBeforeDate } from '@skribe/web/date'

export const ZoomLiveMeetingButton: React.FC<{
  startUrl: string
  startDate: string | null | undefined
}> = ({ startUrl, startDate }) => {
  const isBeforeEvent = isBeforeDate(startDate)

  return (
    <Tooltip title={isBeforeEvent ? 'Join on event date' : 'Join as Host'}>
      <Link to={startUrl} data-testid="liveEventLink" target="_blank">
        <Button
          data-testid="liveEventButton"
          disabled={isBeforeEvent}
          type="primary"
        >
          <Image
            src="/zoom-logo-white.svg"
            preview={false}
            width="5rem"
            alt="Zoom Logo"
          />
        </Button>
      </Link>
    </Tooltip>
  )
}
