import { FC } from 'react'

import { Col, Row } from 'antd'
import { SelectedMatter } from './SelectedMatter'
import { useMatterPage } from './useMatterPage'
import { MatterSelector } from './MatterSelector'
import { LoadingComponent } from '../components'

export const MatterPage: FC = () => {
  const testId = 'matterPage'

  const { selectedMatter, matters, isLoading } = useMatterPage()
  const leftBreakpoints = { xs: 8, sm: 8, md: 8, lg: 12, xl: 14, xxl: 14 }
  const rightBreakpoints = { xs: 16, sm: 16, md: 16, lg: 12, xl: 10, xxl: 10 }

  return (
    <div data-testid={testId}>
      {isLoading ? (
        <LoadingComponent />
      ) : (
        <Row gutter={30}>
          <Col {...leftBreakpoints}>
            {matters && <MatterSelector matters={matters} />}
          </Col>
          <Col {...rightBreakpoints}>
            {selectedMatter && (
              <SelectedMatter
                data-testid="selectedMatter"
                selected={selectedMatter}
              />
            )}
          </Col>
        </Row>
      )}
    </div>
  )
}

export default MatterPage
