import { publicAPI, secureAPI } from './client'
type FeatureFlags = Record<string, boolean>

//TODO: Refactor args to just pass email, no need for complex object
export const fetchFeatureFlags = async (user?: Record<'username', string>) => {
  const api = user?.username ? secureAPI : publicAPI
  return (await api.post('rev', '/features/flags', {})) as FeatureFlags
}

export const fetchPublicFeatureFlags = async (email: string) => {
  return (await publicAPI.post('rev', '/features/v2/flags', {
    body: { email },
  })) as FeatureFlags
}
