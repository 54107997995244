import { DetailedEvent, Transcript } from '@skribe/web/core'
import { eventDb } from '@skribe/web/api'
import { useNavigate } from 'react-router-dom'
import orderBy from 'lodash/orderBy'
import { trackEvent } from '@skribe/web/tracking'
import { deleteEvent } from '../../edge/api/Amplify/event'
import { useInvalidateData } from '../state'

export type EventPageProps = {
  event: DetailedEvent
}

export function useEventPage({ event }: EventPageProps) {
  const { id, name, matterID, Transcripts } = event
  const navigate = useNavigate()
  const { refreshEvent } = useInvalidateData()

  const back = `/matters/${matterID}`
  const sortedTranscripts = orderBy(
    Transcripts,
    ['createdAt', 'name'],
    ['desc', 'asc'],
  )

  return {
    back,
    onDeleteClick,
    onNameChange,
    sortedTranscripts,
    navigateToTranscript,
  }

  async function onNameChange(value: string) {
    if (!value) return
    if (value === name) return
    trackEvent('renameEvent', { id: event.id })
    await eventDb.updateName(id, value)
    await refreshEvent()
  }

  async function onDeleteClick() {
    trackEvent('deleteEvent', { id: event.id })
    await deleteEvent(id)
    navigate(back)
  }

  function navigateToTranscript(transcript: Transcript) {
    navigate(`/transcripts/${transcript.id}`)
    trackEvent('selectTranscript', {
      id: transcript.id,
      status: transcript.status,
    })
  }
}
