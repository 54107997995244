import { useNavigate } from 'react-router-dom'
import { Result } from 'antd'
import {
  ERROR_LOGIN_USER,
  ERROR_PAGE_NOT_EXISTS,
  Nullable,
} from '@skribe/web/core'
import { useVendorUser } from 'src/app/Auth/useVendorUser'
import { ResultStatusType } from 'antd/es/result'

export type ErrorComponentProps = {
  error: Nullable<Error>
  showButton?: boolean
  status?: React.ComponentProps<typeof Result>['status']
}

export function useErrorComponent(props: ErrorComponentProps) {
  const { signOut } = useVendorUser()
  const navigate = useNavigate()

  return getErrorProps(props.error)

  function getErrorProps(baseError: Nullable<Error>) {
    let error: Error = baseError ?? new Error()
    let status: ResultStatusType = 500
    let resetErrorMessage = 'Back to Home'

    let onResetError = () => {
      navigate('/')
    }
    if (error?.message === ERROR_LOGIN_USER) {
      resetErrorMessage = 'Please Try Again'
      onResetError = () => {
        signOut()
        navigate('/')
      }
    } else if (error?.message === ERROR_PAGE_NOT_EXISTS) {
      status = 404
      error.name = 'Page not Found'
    } else {
      error.name = 'Bench Brief Break'
      error.message =
        "Your search took us by surprise! We're standing in recess while we figure out what's going on."
    }

    console.error(baseError)
    return {
      resetErrorMessage,
      onResetError,
      status,
      error,
    }
  }
}
