import { useEffect, useState } from 'react'
import { trackEvent } from '@skribe/web/tracking'
import { transcriptDb } from '@skribe/web/api'
import { useUserContext } from '../../User/UserProvider'
import {
  acceptedFileTypes,
  extractNameFromUniqueFileName,
} from '@skribe/web/core'
import { formatDefaultTitle } from '@skribe/web/date'
import { eventDb } from '@skribe/web/api'
import { progressTracker } from '@skribe/web'

export interface useLibraryFormProps {
  defaultMatterName?: string | null
  getEventId: (eventName: string) => Promise<string>
  onClose: () => void
  onSuccessfulSave: (eventId: string) => void
}

export function useLibraryForm(props: useLibraryFormProps) {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<any>(false)
  const [uploadInProgress, setUploadInProgress] = useState(false)
  const [uploadedFiles, setUploadedFiles] = useState<Array<string>>([])
  const [allowUpload, setAllowUpload] = useState<boolean>(false)
  const [disabledUploadWarning, setDisabledUploadWarning] = useState({
    message: '',
    description: '',
  })
  const [name, setName] = useState<string | undefined>(
    props.defaultMatterName
      ? formatDefaultTitle(props.defaultMatterName)
      : undefined,
  )
  const [hasTranscriptsInProgress, setHasTranscriptsInProgress] =
    useState(false)
  const { user } = useUserContext()

  const fileTypeMessage = `${acceptedFileTypes.join(
    ', ',
  )} files are currently supported.`

  useEffect(() => {
    ;(async () => {
      setHasTranscriptsInProgress(
        !!(await transcriptDb.listInProgressTranscripts()).length,
      )
    })()
  })

  useEffect(() => {
    setAllowUpload(
      user.trial
        ? !!user.trialRemainingSeconds && !hasTranscriptsInProgress
        : true,
    )

    setDisabledUploadWarning(
      !user.trialRemainingSeconds
        ? {
            message: 'Times up!',
            description: 'You have used up all your free trial time.',
          }
        : {
            message: 'Good things come to those who wait.',
            description: 'In trial mode, one upload at a time.',
          },
    )
  }, [user, hasTranscriptsInProgress])

  return {
    loading,
    isTrial: user.trial,
    fileTypeMessage,
    allowUpload,
    disabledUploadWarning,
    onUploadStart,
    onUploadSuccess,
    onSave,
    onClose,
    name,
    resetLoader,
    onNameChange,
    shouldShowForm: !error && !loading,
    shouldShowError: error && !loading,
    disableSave: uploadInProgress || uploadedFiles.length === 0,
    removeUploadedFiles,
  }

  function onUploadStart() {
    setUploadInProgress(true)
  }

  function onUploadSuccess(event: { key?: string | undefined }) {
    try {
      resetLoader()

      if (!event.key) throw Error('Error with Storage Manager upload')
      addUploadedFiles(event.key)
    } catch (error) {
      console.error(error)
      setError(error)
    }
  }

  function resetLoader() {
    setUploadInProgress(false)
  }

  function addUploadedFiles(file: string) {
    setUploadedFiles(l => [...l, file])
  }
  function removeUploadedFiles(file: string) {
    setUploadedFiles(l => l.filter(uF => uF !== file))
  }
  function onNameChange(e: React.ChangeEvent<HTMLInputElement>) {
    setName(e.target.value)
  }

  async function onSave() {
    try {
      setLoading(true)

      const eventName =
        name?.trim() ||
        extractNameFromUniqueFileName(uploadedFiles[0] ?? 'Untitled Event')
      const eventId = await props.getEventId(eventName)

      await Promise.all(
        uploadedFiles.map(async key => {
          await transcriptDb.createJob(key, eventId, user.id)
          trackEvent('uploadFile', { key, id: eventId })
        }),
      )

      props.onSuccessfulSave(eventId)
    } catch (error) {
      console.error(error)
      setError(error)
    } finally {
      setLoading(false)
      progressTracker.reset()
    }
  }

  async function onClose() {
    try {
      progressTracker.reset()
      await eventDb.removeFiles(
        uploadedFiles.map(fileName => `public/inbox/${fileName}`),
      )
    } catch (error) {
      console.error(error)
    } finally {
      props.onClose()
    }
  }
}
