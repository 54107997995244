import { LoadingComponent } from '../../../components'
import { RenderWhen } from '../../../components/RenderWhen'
import { Message } from '@skribe/web/api'
import { ChatAnswer } from './ChatAnswer'
import { ChatQuestion } from './ChatQuestion'
import { ChatPlaceholder } from './ChatPlaceholder'
import { ChatHistory } from './ChatHistory'

interface ChatBodyProps {
  error: string
  response: string
  showSpinner: boolean
  question: string
  history: Message[]
}

export function ChatBody(props: ChatBodyProps) {
  return (
    <div className="chat-body">
      <ChatContent {...props} />
      <RenderWhen condition={props.showSpinner}>
        <ChatSpinner />
      </RenderWhen>
    </div>
  )
}

function ChatSpinner() {
  return <LoadingComponent />
}

function ChatContent(props: Omit<ChatBodyProps, 'showSpinner'>) {
  const noContent =
    props.history.length === 0 && !props.response && !props.question
  if (noContent) return <ChatPlaceholder />
  return (
    <>
      <ChatHistory history={props.history} />
      <span
        className="scroll-position"
        style={{ height: '1px', width: '100%' }}
      />
      <ChatQuestion question={props.question} />
      <ChatAnswer
        question={props.question}
        response={props.response}
        error={props.error}
      />
    </>
  )
}
