import { useEffect, useState } from 'react'
import { Clip } from '@skribe/web/core'
import { copyToClipboard, scrollToElementWithClass } from '@skribe/web'
import { clipDb } from '@skribe/web/api'
import { orderBy } from 'lodash'
import { trackEvent } from '@skribe/web/tracking'
import { useInvalidateData } from '../../state'
import { useTranscriptPlayerContext } from '../../context'
import { useTranscriptPageContext } from '../../context/TranscriptPageContext'

export type useClipListProps = {
  onCopy?: () => void
  onUpdateSequence: (clips: Clip[]) => void
  clips: Clip[]
}

export function useClipList({
  onCopy,
  clips,
  onUpdateSequence,
}: useClipListProps) {
  const { refreshClips } = useInvalidateData()
  const {
    playClip,
    playing: isMediaPlaying,
    selectedClip,
    stoppedPlaying: stopPlaying,
    selectClip,
    createClip,
  } = useTranscriptPlayerContext()
  const { transcriptId } = useTranscriptPageContext()
  const [sortedClips, setSortedClips] = useState<Clip[]>()

  useEffect(() => {
    scrollToElementWithClass('selected-clip')
    scrollToElementWithClass('dialogue-handle')
  }, [selectedClip])

  useEffect(() => {
    setSortedClips(orderBy(clips, 'sequence'))
  }, [clips])

  return {
    onPlay,
    selectClip,
    copyClipUrl,
    onDragEnd,
    sortedClips,
    deleteClip,
    isMediaPlaying,
    selectedClip,
    stopPlaying,
    createClipAndTrack,
  }

  async function createClipAndTrack(start: number, end: number, name: string) {
    if (transcriptId) {
      trackEvent('createClip', {
        parent: transcriptId,
        start,
        end,
        name,
        type: 'manual',
      })
      await createClip(start, end, name, transcriptId)
    } else {
      console.error('No transcript id on create clip and track')
    }
  }

  function onPlay(clip: Clip) {
    selectClip(clip)
    playClip(clip)
    trackEvent('playClip', { clip })
  }

  function copyClipUrl(clip: Clip): void {
    let url = new URL(`${window.location.origin}/clip/${clip.id}`)
    copyToClipboard(url.toString())
    if (!clip.download_url) {
      clipDb.postClipDownload(clip.id)
    }
    trackEvent('copyClipLink', { clip })
    onCopy && onCopy()
  }

  function onDragEnd(fromIndex: number, toIndex: number) {
    if (outsideDesignatedArea()) return

    const items = reordered()
    resequence(items)
    setSortedClips(items)
    onUpdateSequence(items)

    function reordered() {
      const items = [...(sortedClips ?? [])]
      const item = items.splice(fromIndex, 1)[0]
      items.splice(toIndex, 0, item)
      return items
    }

    function outsideDesignatedArea() {
      return toIndex < 0
    }

    function resequence(clips: Clip[]) {
      for (let i = 0; i < clips.length; i++) clips[i].sequence = i
    }
  }

  async function deleteClip(id: string) {
    await clipDb.deleteClip(id)
    trackEvent('deleteClip', { clip: clips.find(clip => clip.id === id) })
    await refreshClips()
  }
}
