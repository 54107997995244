import React, { PropsWithChildren } from 'react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { isDevelopment } from '@skribe/web/core'

export function QueryClientContextProvider(props: PropsWithChildren) {
  return (
    <QueryClientProvider client={new QueryClient()}>
      {props.children}
      {isDevelopment && <ReactQueryDevtools initialIsOpen={false} />}
    </QueryClientProvider>
  )
}
