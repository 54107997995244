import { FronteggProvider } from '@frontegg/react'
import { ReactNode } from 'react'
import { useChangeAlertForForceMigratedUsers } from '../../../app/UseClearInterval'

const baseUrl = process.env.REACT_APP_FRONTEGG_BASE_URL ?? ''
const clientId = process.env.REACT_APP_FRONTEGG_CLIENT_ID ?? ''

function refreshOnUpdates() {
  const adminPortalShadowRoot = window.document.getElementById(
    'frontegg-admin-portal-container-default',
  )?.shadowRoot
  const closeAdminButton = adminPortalShadowRoot?.querySelector(
    '[aria-label="close admin-box"]',
  )
  closeAdminButton?.addEventListener('click', () => {
    window.location.reload()
  })
}

export const FronteggAuthProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  useChangeAlertForForceMigratedUsers()

  return (
    <FronteggProvider
      events={{
        adminBoxMenuClicked: refreshOnUpdates,
      }}
      contextOptions={{ baseUrl, clientId }}
      hostedLoginBox={false}
      authOptions={{ keepSessionAlive: true }}
      themeOptions={{
        adminPortal: { layout: { fullScreenMode: true } },
      }}
    >
      {children}
    </FronteggProvider>
  )
}
