import orderBy from 'lodash/orderBy'
import { Matter } from './types'
import { MatterPin } from './user'

export function sortByMostRecent<T>(
  matters: T[] & Record<'createdAt', string>[],
): T[] {
  return orderBy(matters, 'createdAt', 'desc')
}

export function sortByMostRecentPinned(
  matters: Matter[],
  pinnedMatters: MatterPin[],
): Matter[] {
  const mattersWithUserPinnedTimes = matters.map(matter => {
    const pinTime = pinnedMatters.find(p => p.id === matter.id)?.pinTime || 0
    return { ...matter, pinTime }
  })
  return orderBy(mattersWithUserPinnedTimes, 'pinTime', 'desc')
}

function fillInEmptyPinTimes(matter: Matter) {
  if (!matter.pinTime) {
    return { ...matter, pinTime: 0 }
  } else {
    return matter
  }
}
