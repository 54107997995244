import { useState } from 'react'
import { CustomVocabulary, GetTranscriptResponse } from '@skribe/web/core'
import { UpgradeTranscriptionFormShape } from './UpgradeTranscriptionFormTypes'
import { PostHumanTranscriptBody } from '@skribe/web/api'

export enum TAB {
  UPGRADE = 'Upgrade',
}

export type upgradeTranscriptDrawerProps = {
  orderHumanTranscript: (body: PostHumanTranscriptBody) => void
  transcript: GetTranscriptResponse
}

export const useUpgradeTranscriptDrawer = (
  props: upgradeTranscriptDrawerProps,
) => {
  const [tab, setTab] = useState<undefined | TAB>()
  const openUpgrade = () => setTab(TAB.UPGRADE)
  const onClose = () => setTab(undefined)

  const onSubmit = async (formData: UpgradeTranscriptionFormShape) => {
    props.orderHumanTranscript({
      customVocabularies: formData.custom
        ? toCustomVocabularies(formData.custom)
        : [],
      rush: formData.rush,
      reuseSpeakers: formData.includeNames,
    })
    onClose()
  }

  const orderHasCompleted = !!props?.transcript?.human_monologues_url
  const hasBeenOrdered = !!props?.transcript?.human_order_date
  const isLoading = hasBeenOrdered && !orderHasCompleted
  return {
    onSubmit,
    tab,
    openUpgrade,
    onClose,
    isOrderFinished: orderHasCompleted,
    isLoading,
  }
}
export const toCustomVocabularies = (data: string): CustomVocabulary[] => {
  if (!data.trim()) return []
  return [
    {
      phrases: data
        .split(/,/)
        .filter(Boolean)
        .map(s => s.trim()),
    },
  ]
}
