import { Clip, colors, MonologueElement } from '@skribe/web/core'
import { retrieveMatchingClip } from './retrieveMatchingClip'
import { useFeature } from 'flagged'
import { useInvalidateData } from '../../../state'
import { getHandlesManager } from './getHandlesManager'
import { Handle } from './Handle'
import { MonologueElementComponent } from './MonologueElementComponent'

import { useTranscriptPlayerContext } from '../../../context'

export function MonologueElementContainer(props: {
  clips: Clip[] | undefined
  elements: MonologueElement[]
  index: number
  element: MonologueElement
  onElementClick: ((clipID?: string) => void) | undefined
  previousMonologueElement?: MonologueElement
  nextMonologueElement?: MonologueElement
}) {
  const { selectedClip } = useTranscriptPlayerContext()
  const refreshedSelectedClip = props.clips?.find(
    c => c.id === selectedClip?.id,
  )

  const firstMatchingClip = retrieveMatchingClip(
    props.element,
    props.clips ?? [],
  )
  const showHandlesFlag = useFeature('showHandles')
  const { refreshClips } = useInvalidateData()

  const {
    shouldShowStartHandle,
    shouldShowEndHandle,
    expandStart,
    shrinkStart,
    expandEnd,
    shrinkEnd,
  } = getHandlesManager(
    props.index,
    props.elements,
    refreshClips,
    refreshedSelectedClip,
    props.previousMonologueElement,
    props.nextMonologueElement,
  )

  return (
    <>
      {showHandlesFlag && shouldShowStartHandle && (
        <Handle
          leftTooltip={'Add Prior Word'}
          rightTooltip={'Cut Next Word'}
          onLeft={expandStart}
          onRight={shrinkStart}
          leftStyle={{ color: colors.green }}
          rightStyle={{ color: colors.error }}
        />
      )}
      <MonologueElementComponent
        index={props.index}
        key={props.index}
        element={props.element}
        clip={firstMatchingClip}
        onElementClick={props.onElementClick}
      />
      {showHandlesFlag && shouldShowEndHandle && (
        <Handle
          leftTooltip={'Cut Prior Word'}
          rightTooltip={'Add Next Word'}
          onLeft={shrinkEnd}
          onRight={expandEnd}
          rightStyle={{ color: colors.green }}
          leftStyle={{ color: colors.error }}
        />
      )}
    </>
  )
}
