import { Clip } from '@skribe/web/core'
import { EditableClipInput } from './EditableClipInput'
import { clipTime } from '../../../utilities/timeUtilities'
import { Typography } from 'antd'

export function EditableClipTime(props: {
  clip: Clip
  shouldUseEditMode: boolean
  editModeSelected: boolean
  max: number
  onCancel: () => void
  onConfirm: (startTime: number, endTime: number) => Promise<void>
}) {
  if (props.shouldUseEditMode && props.editModeSelected) {
    return (
      <EditableClipInput
        clip={props.clip}
        max={props.max}
        onCancel={props.onCancel}
        onConfirm={props.onConfirm}
      />
    )
  }
  return <ClipTime clip={props.clip} />
}

export function ClipTime(props: { clip: Clip }) {
  return (
    <Typography.Text style={{ fontSize: 'smaller' }}>
      {clipTime(props.clip)}
    </Typography.Text>
  )
}
