import { PauseCircleOutlined, PlayCircleOutlined } from '@ant-design/icons'
import { Alert, Button } from 'antd'
import React from 'react'
import ReactPlayer from 'react-player/file'
import { ClipPlayerProps, useClipPlayer } from './useClipPlayer'

export const ClipPlayer: React.FC<ClipPlayerProps> = props => {
  const {
    wrapperRef,
    playing,
    playerRef,
    onProgress,
    onStart,
    togglePlaying,
    loading,
    setLoading,
    isAudio,
    url,
    showControls,
    canPlay,
    height,
  } = useClipPlayer(props)

  return (
    <div
      ref={wrapperRef}
      className={isAudio ? 'clip-player clip-player-audio' : 'clip-player'}
      onClick={togglePlaying}
    >
      {!canPlay && (
        <Alert
          type="warning"
          showIcon
          message="Potentially Incompatible Media"
          description="This media file might not be supported by your browser."
        />
      )}
      <ReactPlayer
        data-testid="clipPlayer"
        progressInterval={100}
        onProgress={onProgress}
        onStart={onStart}
        onReady={() => setLoading(false)}
        ref={playerRef}
        playing={playing}
        controls={showControls}
        url={url}
        width="100%"
        height={height}
        playsinline
        config={{
          attributes: {
            controlsList: 'nodownload',
          },
        }}
        onContextMenu={(e: any) => e.preventDefault()}
      />
      {!showControls &&
        !loading &&
        (isAudio ? (
          <Button
            ghost
            type="text"
            className="play-pause-btn"
            disabled={loading}
            icon={playing ? <PauseCircleOutlined /> : <PlayCircleOutlined />}
            onClick={togglePlaying}
          />
        ) : (
          !playing && (
            <Button
              ghost
              type="text"
              className="play-pause-btn"
              disabled={loading}
              icon={<PlayCircleOutlined />}
              onClick={togglePlaying}
            />
          )
        ))}
    </div>
  )
}
