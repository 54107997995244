import { createContext, ReactNode, useRef, useState } from 'react'
import ReactPlayer from 'react-player/file'
import { Clip } from '@skribe/web/core'

export const TranscriptPlayerContext = createContext<
  | {
      playing: boolean
      setPlaying: (n: boolean) => void
      playerRef: React.RefObject<ReactPlayer>
      clipEnd: number | undefined
      setClipEnd: (n: number | undefined) => void
      selectedClip: Clip | undefined
      setSelectedClip: (n: Clip | undefined) => void
      playlistClips: Clip[]
      setPlaylistClips: (p: Clip[]) => void
      selectedPlaylistId: string
      setSelectedPlaylistId: (n: string) => void
    }
  | undefined
>(undefined)

export const TranscriptPlayerProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [selectedPlaylistId, setSelectedPlaylistId] = useState<string>('')
  const [playlistClips, setPlaylistClips] = useState<Clip[]>([])
  const playerRef = useRef<ReactPlayer>(null)
  const [playing, setPlaying] = useState(false)
  const [clipEnd, setClipEnd] = useState<number | undefined>()
  const [selectedClip, setSelectedClip] = useState<Clip | undefined>()

  return (
    <TranscriptPlayerContext.Provider
      value={{
        playing,
        setPlaying,
        playerRef,
        clipEnd,
        setClipEnd,
        selectedClip,
        setSelectedClip,
        playlistClips,
        setPlaylistClips,
        selectedPlaylistId,
        setSelectedPlaylistId,
      }}
    >
      {children}
    </TranscriptPlayerContext.Provider>
  )
}
