import { secureAPI } from './client'

export type SearchItem = {
  id: string
  name: string
  createdAt: string
  parentId?: string
  group?: string
}

export type SearchResult = {
  matters: SearchItem[]
  events: SearchItem[]
  transcripts: SearchItem[]
  clips: SearchItem[]
}

export async function fetchSearchData(): Promise<SearchResult> {
  return await secureAPI.get('rev', '/search', {})
}
