/**@deprecated Not used anymore, TODO: Remove old user */
export type OldUser = {
  __typename: 'User'
  id: string
  email: string
  lowercaseEmail?: string
  trial?: boolean | null
  acknowledgementDate?: string | null
  trialRemainingSeconds?: number | null
  createdAt: string
  updatedAt: string
  isLiaison?: 'Y' | 'N' | null
  migrationReferences?: {
    events: string[]
    transcripts: string[]
    clips: string[]
    matters: string[]
    recorders: string[]
  } | null
  migrationDate?: String | null
}

export type SkribeUser = {
  __typename: 'User'
  id: string
  email: string
  trial?: boolean | null
  acknowledgementDate?: string | null
  trialRemainingSeconds?: number | null
  createdAt: string
  updatedAt: string
  isLiaison?: 'Y' | 'N' | null
  cognitoId?: string | null
  pinnedMatters?: MatterPin[]
}

export type MatterPin = {
  id: string
  pinTime: number
}

export type User = SkribeUser

export type UserGroup = {
  id: string
  name: string
}

export type DetailedUser = User & {
  groups: UserGroup[]
  exampleTranscriptId?: string
}
export enum ROLE {
  USER = 'user',
  LIAISON = 'liaison',
}

export function matchesRole(
  userRole: ROLE | undefined,
  whitelistedRoles: ROLE[],
) {
  return !!userRole && whitelistedRoles.includes(userRole)
}

export function isLiaison(user?: User) {
  return user?.isLiaison === 'Y'
}
