import { FC, ReactNode } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { UserProvider } from './User/UserProvider'
import { useVendorUser } from './Auth/useVendorUser'
import { useClearInterval } from './UseClearInterval'

export const RequireAuth: FC<{ children?: ReactNode }> = ({ children }) => {
  const { isAuthenticated } = useRequireAuth()

  return (
    <UserProvider isAuthenticated={isAuthenticated}>
      {children ? children : <Outlet />}
    </UserProvider>
  )
}
export function useRequireAuth() {
  const location = useLocation()
  const navigate = useNavigate()
  const { neitherIsLoggedIn, isAuthenticated } = useVendorUser()
  useClearInterval()

  if (neitherIsLoggedIn) {
    navigate('/account/login', { replace: true, state: { from: location } })
  }
  return { isAuthenticated }
}
