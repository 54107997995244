import { useEffect, useRef, useState } from 'react'
import ReactPlayer from 'react-player/file'
import { OnProgressProps } from 'react-player/base'
import { Nullable } from '@skribe/web/core'
import { scrollToElementWithClass, spotlightWord } from '@skribe/web'
export interface ClipPlayerProps {
  transcriptUrl: string
  clipUrl?: Nullable<string>
  start: number
  end: number
  onProgressUpdate?: (e: OnProgressProps) => void
}

export function useClipPlayer({
  start,
  end,
  transcriptUrl,
  clipUrl,
  onProgressUpdate,
}: ClipPlayerProps) {
  const wrapperRef = useRef<HTMLDivElement>(null)
  const [playing, setPlaying] = useState(false)
  const [playedSeconds, setPlayedSeconds] = useState(0)
  const playerRef = useRef<ReactPlayer>(null)
  const [loading, setLoading] = useState(true)
  const isVideoClipped = !!clipUrl

  useEffect(limitUserFromInteractingWithTheWholeVideo, [wrapperRef])

  const onProgress = (state: OnProgressProps) => {
    setPlayedSeconds(state.playedSeconds)
    onProgressUpdate && onProgressUpdate(state)
    if (!isVideoClipped && state.playedSeconds > end) {
      playerRef?.current?.seekTo(end)
      setPlaying(false)
    }

    const cursor = isVideoClipped
      ? state.playedSeconds + start
      : state.playedSeconds

    spotlightWord(cursor)
    scrollToElementWithClass('spotlight-word')
  }

  const onStart = () => {
    playerRef.current?.seekTo(isVideoClipped ? 0 : start)
  }

  const togglePlaying = () => {
    if (loading) return
    if (playedSeconds >= end) {
      playerRef?.current?.seekTo(start)
    }
    setPlaying(!playing)
  }

  const isAudio = isAudioUrl(mediaUrl())
  const height = isAudio ? (!isVideoClipped ? '100%' : '10rem') : 'auto'
  return {
    wrapperRef,
    playing,
    playerRef,
    height,
    onProgress,
    onStart,
    togglePlaying,
    loading,
    setLoading,
    isAudio,
    url: mediaUrl(),
    showControls: isVideoClipped,
    canPlay: canPlay(mediaUrl()),
  }

  function mediaUrl() {
    return !!clipUrl && canPlay(clipUrl) ? clipUrl : transcriptUrl
  }

  function canPlay(url: string) {
    return ReactPlayer.canPlay(url)
  }

  function isAudioUrl(url: string) {
    const path = url.split('?')[0]
    return path.endsWith('.mp3') || path.endsWith('.wav')
  }

  function limitUserFromInteractingWithTheWholeVideo() {
    const wrapper = wrapperRef
    const handleContextMenu = (e: MouseEvent) => {
      e.preventDefault()
    }

    wrapper.current?.addEventListener('contextmenu', handleContextMenu)

    return () => {
      wrapper.current?.removeEventListener('contextmenu', handleContextMenu)
    }
  }
}
