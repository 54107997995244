import { UserGroup } from '@skribe/web/core'
import { SubMenuType } from 'antd/lib/menu/hooks/useItems'
import { UsergroupAddOutlined } from '@ant-design/icons'
import { createAddToCategoryDropdownItem } from '../components'

export function createShareDropdownItem({
  userGroups,
  onShare,
  id,
}: {
  userGroups: UserGroup[]
  onShare: (groupId: string) => Promise<void>
  id: string
}): SubMenuType | null {
  if (userGroups?.length < 2) return null
  const filteredUserGroups = userGroups.filter(group => group.id !== id)
  return createAddToCategoryDropdownItem({
    icon: <UsergroupAddOutlined />,
    text: 'Share to',
    categories: filteredUserGroups,
    onSelect: onShare,
  })
}
