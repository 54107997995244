import { FC } from 'react'
import { Outlet } from 'react-router-dom'
import { Col, Result, Row, Image } from 'antd'
import { useVerifyChrome } from '../BrowserWarning'

export const CompatibilityChecker: FC = () => {
  useVerifyChrome()

  return (
    <>
      <div id="mobile">
        <Row style={{ height: '100vh' }} justify="center" align="middle">
          <Col>
            <Result
              icon={
                <Image src="/desktop.svg" preview={false} alt="Desktop Only" />
              }
              title="Skribe works best on a full desktop browser."
              subTitle="(You can still view clips anywhere)"
            />
          </Col>
        </Row>
      </div>
      <div id="desktop">
        {' '}
        <Outlet />
      </div>
    </>
  )
}
