import { monologueClient } from '@skribe/web/api'
import { backfillTimestamps, Monologue } from '@skribe/web/core'

export async function handleMonologue(url: string): Promise<any> {
  try {
    const result = await monologueClient.getMonologue(url)
    const monologues: Monologue[] = result?.data?.monologues

    if (!monologues) throw Error('Invalid Monologues')

    // TODO - call monologue::createSkribeMonologues

    return monologues.map((m: Monologue) => {
      return { ...m, elements: backfillTimestamps(m.elements ?? []) }
    })
  } catch (e) {
    console.error(e)
    throw new Error('Monologues', { cause: e })
  }
}
