import { Speaker } from './speakers'
import { Transcript } from './types'

export enum STATUS {
  Failed = 'failed',
  Processing = 'processing',
}

export type GetTranscriptResponse = Omit<
  Transcript,
  'speakers' | 'speakers_human' | 'speakers_machine'
> & {
  media_url: string | undefined
  monologues_url: string | undefined
  human_monologues_url: string | undefined
  /* Our custom endpoint is using DynamoDB directly, not GraphQL, so an array is returned instead of a JSON string. */
  speakers?: string[] | null
  speakers_machine?: Speaker[] | null
  speakers_human?: Speaker[] | null
}

export const toTranscript = (t: GetTranscriptResponse): Transcript => ({
  ...t,
  speakers: undefined,
  speakers_human: undefined,
  speakers_machine: undefined,
})
