import { ClipTime } from '@skribe/web/core'

export function timestamp(seconds: number | null | undefined): string {
  if (seconds == null) return '00:00:00'
  return new Date(seconds * 1000).toISOString().substring(11, 19)
}

export const parseTimestamp = (value: string | undefined) => {
  if (!value) return NaN
  const parts = value.split(':')
  return Number(parts[0]) * 60 * 60 + Number(parts[1]) * 60 + Number(parts[2])
}

// React Player does not handle partial seconds under 1
export const convertStartToPositiveNumber = (value?: number) => {
  if (value === undefined) return 0
  return value < 1 ? 0 : value
}

export const convertToPositiveNumber = (value?: number) => {
  if (value === undefined) return 0
  return value < 0 ? 0 : value
}

export const clipTime = (clip: ClipTime) =>
  `${timestamp(clip.start)}–${timestamp(clip.end)}`
