import { List, Row, Tabs } from 'antd'
import React from 'react'
import { generateEmptyPlaceholder } from '../components'
import { useLiaisonPage } from './useLiaisonPage'
import { LiaisonEventItem } from './LiaisonEvent'
import { orderBy } from 'lodash'
import { isAfterYesterday } from '@skribe/web/date'

export const LiaisonPage: React.FC = () => {
  const testId = 'liaisonPage'
  const { events } = useLiaisonPage()
  const sortedEvents = orderBy(events, 'start', 'asc')

  const upcomingEvents = sortedEvents.filter(event =>
    isAfterYesterday(event.start),
  )
  const previousEvents = sortedEvents.filter(
    event => !isAfterYesterday(event.start),
  )
  return (
    <div data-testid={testId}>
      <Row justify="space-between">
        <h1 className="subtitle-1">Future Events</h1>
      </Row>
      <div className="event-list-container">
        <Tabs
          items={[
            {
              key: 'upcoming',
              label: 'Upcoming Events',
              children: (
                <List
                  bordered
                  rowKey="id"
                  size="large"
                  locale={generateEmptyPlaceholder('No Events')}
                  dataSource={upcomingEvents}
                  renderItem={selected => <LiaisonEventItem item={selected} />}
                />
              ),
            },
            {
              key: 'past',
              label: 'Past Events',
              children: (
                <List
                  bordered
                  rowKey="id"
                  size="large"
                  locale={generateEmptyPlaceholder('No Events')}
                  dataSource={previousEvents}
                  renderItem={selected => <LiaisonEventItem item={selected} />}
                />
              ),
            },
          ]}
        />
      </div>
    </div>
  )
}
