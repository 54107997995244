import { useVendorUser } from '../../../Auth/useVendorUser'
import { ChatText } from './ChatText'
import { UserOutlined } from '@ant-design/icons'

export function ChatQuestion(props: { question: string }) {
  const { email } = useVendorUser()

  return (
    <ChatText
      cardClassName="chat-question-card"
      iconClassName="chat-question-avatar"
      icon={email?.charAt(0).toUpperCase() ?? <UserOutlined />}
      text={props.question}
    />
  )
}
