import { Clip } from '@skribe/web/core'
import { Col, List, Row } from 'antd'
import classnames from 'classnames'
import { HolderOutlined } from '@ant-design/icons'
import { PlayPauseButton } from './PlayPauseButton'
import { EditableText } from '../../../components'
import { useClipSelection } from './useClipSelection'

interface BaseClipItemProps {
  playing: boolean
  onPause: () => void
  onPlay: () => void
  clipTime: JSX.Element
  clipActionButtons: JSX.Element
  clip: Clip
  editText?: (value: any) => void
  selectedClipId: string | undefined
  onSelect: () => void
}

export function BaseClipItem(props: BaseClipItemProps) {
  const { isSelectedClip, showSelectedStyle } = useClipSelection({
    clip: props.clip,
    selectedClipId: props.selectedClipId,
  })
  return (
    <List.Item
      className={classnames({
        draggable: true,
        'selected-clip': isSelectedClip,
      })}
      style={showSelectedStyle()}
      onClick={props.onSelect}
    >
      <Row style={{ width: '100%' }}>
        <Col
          span={4}
          style={{ display: 'flex', justifyContent: 'space-around' }}
        >
          <Row wrap={false}>
            <HolderOutlined style={{ cursor: 'grab', marginRight: '1rem' }} />
            <PlayPauseButton
              isSelected={isSelectedClip}
              playing={props.playing}
              onPause={props.onPause}
              onPlay={props.onPlay}
            />
          </Row>
        </Col>
        <Col span={20}>
          <Row justify={'space-between'}>
            <Col style={{ alignSelf: 'center' }}>{props.clipTime}</Col>
            <Col>
              <Row>{props.clipActionButtons}</Row>
            </Col>
          </Row>
          <Row>
            <EditableText
              startingValue={props.clip.name ?? ''}
              onEdit={props.editText}
              testId="clipName"
            />
          </Row>
        </Col>
      </Row>
    </List.Item>
  )
}
