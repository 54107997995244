import { PropsWithChildren } from 'react'
import { Avatar, Card, Grid } from 'antd'
import { Converter } from 'showdown'

export function ChatText(
  props: PropsWithChildren<{
    iconClassName: string
    cardClassName?: string
    textClassName?: string
    icon: React.JSX.Element | string
    text: string
  }>,
) {
  const screens = Grid.useBreakpoint()
  if (!props.text) return <p />

  const content = convertMarkdownToHTML(props.text)

  return (
    <div className="chat-text-container">
      {screens.xxl && (
        <Avatar className={`chat-text-avatar ${props.iconClassName}`}>
          {props.icon}
        </Avatar>
      )}
      <Card className={`chat-text-card ${props.cardClassName ?? ''}`}>
        <div className="enable-select chat-text">
          <div
            className={`chat-text ${props.textClassName ?? ''}`}
            dangerouslySetInnerHTML={{ __html: content }}
          />
          {props.children}
        </div>
      </Card>
    </div>
  )
}

function convertMarkdownToHTML(text: string) {
  const converter = new Converter()
  converter.setFlavor('github')
  return converter.makeHtml(text)
}
