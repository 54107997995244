import { UploadFile } from 'antd'
export function extractFlagFromSession(key: string) {
  return !!JSON.parse(sessionStorage.getItem('flags') ?? '{}')[key]
}

export function persistFlagsToSession(flags: any) {
  sessionStorage.setItem('flags', JSON.stringify(flags))
}

interface ProgressTrack {
  [key: string]: number
}

function getProgressTracker(): ProgressTrack {
  const val = sessionStorage.getItem(`uploaderProgress`)
  return val ? JSON.parse(val) : {}
}
export const progressTracker = {
  get: getProgressTracker,
  set: (i: number, parts: number, uid: string) => {
    const step = i / parts
    sessionStorage.setItem(
      'uploaderProgress',
      JSON.stringify({ ...getProgressTracker(), [uid]: step }),
    )
  },
  reset: () => {
    return sessionStorage.removeItem('uploaderProgress')
  },
  calculatePercent: (uploadsProgress: ProgressTrack, file: UploadFile) => {
    const percent = uploadsProgress[file.uid] ?? file.percent
    return percent ? Math.floor(percent * 100) : undefined
  },
}
