import { Skeleton } from 'antd'
import { ManualClipCreator } from './ManualClipCreator'

export function ManualClipControls(props: {
  createClip: (start: number, end: number, name: string) => void
  durationInSeconds: number | undefined | null
}) {
  return (
    <div className="clip-controls">
      {props.durationInSeconds ? (
        <ManualClipCreator
          createClip={props.createClip}
          duration={props.durationInSeconds}
        />
      ) : (
        <Skeleton
          active
          paragraph={{ rows: 1 }}
          style={{ paddingTop: '1rem' }}
        />
      )}
    </div>
  )
}
