import React from 'react'
import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

export const LoadingComponent: React.FC = () => {
  return (
    <Spin
      data-testid="loading"
      indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
    />
  )
}
