import { secureAPI } from './client'
import { formatDefaultTitle } from '@skribe/web/date'
import { MatterWithEvents } from '@skribe/web/core'

export async function moveMatter(
  matterID: string,
  groupId: string | null,
): Promise<void> {
  try {
    await secureAPI.put('rev', `/matters/${matterID}/group`, {
      body: {
        groupId: groupId,
      },
    })
  } catch (e) {
    console.error(e)
  }
}

export async function list(): Promise<MatterWithEvents[]> {
  return await secureAPI.get('rev', '/matters', {})
}

export async function createMatter(group?: string) {
  const name = formatDefaultTitle('Untitled Matter')
  return await secureAPI.post('rev', '/matters', { body: { name, group } })
}

export async function deleteMatter(id: string | undefined) {
  return await secureAPI.del('rev', `/matters/${id}`, {})
}

export async function updateMatterName(id: string, name: string) {
  return await secureAPI.patch('rev', `/matters/${id}`, {
    body: { name },
  })
}
