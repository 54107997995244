import React from 'react'
import { Tooltip } from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons'

export const HelpTooltip: React.FC<{ title: string }> = ({ title }) => {
  return (
    <Tooltip title={title}>
      <QuestionCircleOutlined
        style={{
          cursor: 'help',
          color: 'var(--disabled-bg-color)',
        }}
      />
    </Tooltip>
  )
}
