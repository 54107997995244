import { ReactNode } from 'react'
import { Button, List, Popover, Typography } from 'antd'

export function SpeakersPopover(props: {
  speakers: {
    name: string
    index: number
  }[]
  onSpeakerChange: (
    speaker: number,
    value: string,
    type: 'onTranscript' | 'onSelector',
  ) => Promise<void>
  children: ReactNode
}) {
  const { speakers, onSpeakerChange, children } = props

  return (
    <>
      {!!speakers.length && (
        <Popover
          placement="bottom"
          title="Speakers"
          content={
            <List
              size="small"
              dataSource={speakers}
              renderItem={speaker => (
                <List.Item>
                  <Typography.Text
                    editable={{
                      onChange: value =>
                        onSpeakerChange(speaker.index, value, 'onSelector'),
                      text: speaker.name,
                    }}
                  >
                    {speaker.name}
                  </Typography.Text>
                </List.Item>
              )}
            />
          }
        >
          {speakers.length && (
            <Button type="link">{speakers.length} speakers</Button>
          )}
        </Popover>
      )}
      {children && <div className="extra-controls">{children}</div>}
    </>
  )
}
