import { ClipTime } from './ClipTime'
import { BaseClipItem } from './BaseClipItem'
import { Button, Tooltip } from 'antd'
import { RiDeleteBackLine } from '@remixicon/react'
import { usePlaylistClipItem } from './usePlaylistClipItem'
import { Clip } from '@skribe/web/core'

export type PlaylistClipItemProps = {
  playlistId: string
  clip: Clip
  isPlaying: boolean
  onPlay: () => void
  onPause: () => void
}
export function PlaylistClipItem(props: PlaylistClipItemProps) {
  const { selectedClip, selectClip, removeFromPlaylist } =
    usePlaylistClipItem(props)

  return (
    <BaseClipItem
      selectedClipId={selectedClip?.id}
      onSelect={() => selectClip(props.clip)}
      clip={props.clip}
      playing={props.isPlaying}
      onPause={props.onPause}
      onPlay={props.onPlay}
      clipTime={<ClipTime clip={props.clip} />}
      clipActionButtons={
        <>
          <Tooltip title="Remove Clip from Playlist">
            <Button
              onClick={removeFromPlaylist}
              icon={<RiDeleteBackLine size={'1.1em'} />}
              type="text"
            />
          </Tooltip>
        </>
      }
    />
  )
}
