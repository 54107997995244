import React from 'react'
import { Button, Drawer, Tabs } from 'antd'
import {
  ScheduleLiveMeetingForm,
  defaultDrawerProps,
  defaultTabsProps,
} from '../components'
import { useEventLiveMeetingDrawer, TAB } from './useEventLiveMeetingDrawer'
import { Event } from '@skribe/web/core'

export type EventLiveMeetingDrawerProps = {
  event: Event
}
export const EventLiveMeetingDrawer: React.FC<
  EventLiveMeetingDrawerProps
> = props => {
  const { closeDrawer, tab, onSubmit, openDrawer, calculateVerb } =
    useEventLiveMeetingDrawer(props)

  return (
    <>
      {props.event.start && (
        <Button data-testid="openLiveEventDrawer" onClick={openDrawer}>
          {calculateVerb(props.event.start)} Event
        </Button>
      )}
      <Drawer
        {...defaultDrawerProps}
        onClose={closeDrawer}
        open={!!tab}
        className="tab-drawer"
      >
        <Tabs
          {...defaultTabsProps}
          defaultActiveKey={tab}
          items={[
            {
              key: TAB.LIVE,
              label: TAB.LIVE,
              children: (
                <ScheduleLiveMeetingForm
                  event={props.event}
                  onSubmit={onSubmit}
                  onClose={closeDrawer}
                  errorMessage="Something wrong happened when scheduling your meeting"
                />
              ),
            },
          ]}
        />
      </Drawer>
    </>
  )
}
