import { Button, Dropdown, Grid } from 'antd'
import { EllipsisOutlined, FileOutlined } from '@ant-design/icons'

const generateSummaryTitle = 'Generate Summary'

export function QuickQuestionButtons(props: {
  askAi: (question: string) => () => void
  isLoading: boolean
}) {
  const screens = Grid.useBreakpoint()

  const { askAi, isLoading } = props
  const summaryRequest =
    'Draft a detailed summary of this deposition. Include list of speakers and topic ' +
    'headings for each topic discussed as well as bullets of the substance ' +
    'of what was discussed on each topic'
  return (
    <>
      {screens.xl ? (
        <GenerateSummaryButton
          onClick={askAi(summaryRequest)}
          isLoading={isLoading}
        />
      ) : (
        <Dropdown
          menu={{
            items: [
              {
                key: 'summary',
                label: generateSummaryTitle,
                onClick: askAi(summaryRequest),
              },
            ],
            triggerSubMenuAction: 'click',
          }}
          trigger={['click']}
        >
          <Button
            data-testid={'quickQuestionButtonMenu'}
            icon={<EllipsisOutlined rotate={90} />}
            type="text"
          />
        </Dropdown>
      )}
    </>
  )
}

function GenerateSummaryButton(props: {
  onClick: () => void
  isLoading: boolean
}) {
  return (
    <Button
      shape="round"
      icon={<FileOutlined />}
      onClick={props.onClick}
      disabled={props.isLoading}
    >
      {generateSummaryTitle}
    </Button>
  )
}
