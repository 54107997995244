import { FC, ReactNode } from 'react'
import { UserGroup, DetailedUser, createContext } from '@skribe/web/core'
import { useUserProvider } from './useUserProvider'
import { LoadingPage } from '../Layout/LoadingPage'

export const UserContext = createContext<{
  user: DetailedUser
  groups: UserGroup[]
  refreshUser: () => void
}>('Could not find user')

export const useUserContext = UserContext.useContext

export const UserProvider: FC<{
  children: ReactNode
  isAuthenticated: boolean
}> = ({ children, isAuthenticated }) => {
  const { user, groups, refreshUser, loadUserError } =
    useUserProvider(isAuthenticated)

  if (loadUserError) throw loadUserError
  if (!user) return <LoadingPage />

  return (
    <UserContext.Provider value={{ user, groups, refreshUser }}>
      {children}
    </UserContext.Provider>
  )
}
