import { eventDb } from '@skribe/web/api'
import { useState } from 'react'
import { OnSubmitLiveMeetingForm } from '../components'
import { Event } from '@skribe/web/core'
import { useInvalidateData } from '../state'

export enum TAB {
  LIVE = 'Live',
}

export const useEventLiveMeetingDrawer = (props: { event: Event }) => {
  const [tab, setTab] = useState<undefined | TAB>()
  const openDrawer = () => setTab(TAB.LIVE)
  const closeDrawer = () => setTab(undefined)
  const { refreshEvent } = useInvalidateData()

  const onSubmit: OnSubmitLiveMeetingForm = async formData => {
    if (!formData.start) throw Error('start required')

    if (!props.event.start) {
      await eventDb.createLiveMeeting(
        props.event.id,
        formData.start.toISOString(),
        formData.durationInMinutes,
      )
    } else {
      await eventDb.rescheduleLiveMeeting(
        props.event.id,
        formData.start.toISOString(),
        formData.durationInMinutes,
      )
    }

    closeDrawer()
    await refreshEvent()
  }

  return {
    tab,
    onSubmit,
    openDrawer,
    closeDrawer,
    calculateVerb,
  }

  function calculateVerb(start?: null | string) {
    return start ? 'Reschedule' : 'Schedule'
  }
}
