import { FC } from 'react'

import { BaseLayout } from './Layout'
import { LoadingComponent } from '../components'

export const LoadingPage: FC = () => (
  <BaseLayout>
    <LoadingComponent />
  </BaseLayout>
)
