import { isChrome } from '@skribe/web'
import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

function acceptWarning() {
  sessionStorage.setItem('browserWarningAccepted', 'true')
}
function hasWarningBeenAccepted() {
  return sessionStorage.getItem('browserWarningAccepted')
}

export function useUserAcknowledgeBrowserIsIncompatible() {
  const navigate = useNavigate()
  const location = useLocation()
  let from = location.state?.from || '/'
  if (hasWarningBeenAccepted()) {
    navigate(from)
  }
  return { onContinue }

  function onContinue() {
    acceptWarning()
    navigate(from)
  }
}

export function useVerifyChrome() {
  const location = useLocation()
  const navigate = useNavigate()
  let from = location.state?.from?.pathname || '/'

  useEffect(() => {
    if (isChrome() || hasWarningBeenAccepted()) return

    navigate('/chrome', { state: { from }, replace: true })
  }, [navigate, from])
}
