import { MonologueElement } from '../../../../core/monologue'
import { Clip } from '../../../../core/types'

export class HandlesVisibilityManager {
  private readonly lastElementIndex: number
  private readonly currentElement: MonologueElement

  constructor(
    private index: number,
    private elements: MonologueElement[],
    private clip?: Clip,
    private previousMonologueElement?: MonologueElement,
    private nextMonologueElement?: MonologueElement,
  ) {
    this.lastElementIndex = elements.length - 1
    this.currentElement = elements[index]
  }

  getPreviousValidElement(internalIndex = this.index): MonologueElement {
    if (internalIndex === 0) {
      return this.goToPreviousMonologueAndGetPreviousValidElement()
    }
    const previousElement = this.elements[internalIndex - 1]
    return previousElement.end_ts < this.currentElement.end_ts
      ? previousElement
      : this.getPreviousValidElement(internalIndex - 1)
  }

  getNextValidElement(internalIndex = this.index): MonologueElement {
    if (internalIndex === this.lastElementIndex) {
      return this.goToNextMonologueAndGetNextValidElement()
    }
    let nextElement = this.elements[internalIndex + 1]
    return nextElement.ts > this.currentElement.ts
      ? nextElement
      : this.getNextValidElement(internalIndex + 1)
  }

  calculateShouldShowStartHandle() {
    if (!this.clip || !this.currentElement) return false
    const firstElementInMonologue = this.index === 0
    if (this.currentElement.ts === this.clip.start) {
      if (firstElementInMonologue) return true
      const previousElement = this.getPreviousElement()
      return previousElement.ts !== this.clip.start
    } else if (this.currentElement.ts > this.clip.start) {
      if (firstElementInMonologue) return this.checkPreviousMonologue()
      const previousElement = this.getPreviousElement()
      if (previousElement.end_ts < this.clip.start) {
        return true
      }
    }
    return this.isWithinElementTimestamps(this.clip.start)
  }

  calculateShouldShowEndHandle() {
    if (!this.clip || !this.currentElement) return false
    const lastElementInMonologue = this.index + 1 === this.elements.length
    if (this.currentElement.end_ts === this.clip.end) {
      if (lastElementInMonologue) return true
      const nextElement = this.getNextElement()
      return nextElement.end_ts !== this.clip.end
    } else if (this.currentElement.end_ts < this.clip.end) {
      if (lastElementInMonologue) return this.checkNextMonologue()
      const nextElement = this.getNextElement()
      if (nextElement.ts > this.clip.end) return true
    }
    return this.isWithinElementTimestamps(this.clip.end)
  }

  private getPreviousElement() {
    if (this.index === 0) return this.elements[0]
    return this.elements[this.index - 1]
  }

  private goToPreviousMonologueAndGetPreviousValidElement() {
    if (this.previousMonologueElement) return this.previousMonologueElement
    else {
      return this.elements[0]
    }
  }

  private goToNextMonologueAndGetNextValidElement() {
    if (this.nextMonologueElement) return this.nextMonologueElement
    else {
      return this.elements[this.lastElementIndex]
    }
  }

  private getNextElement() {
    if (this.index === this.lastElementIndex)
      return this.goToNextMonologueAndGetNextValidElement()
    return this.elements[this.index + 1]
  }

  private checkPreviousMonologue() {
    if (!this.previousMonologueElement) return true
    return this.previousMonologueElement.end_ts < this.clip!.start
  }

  private checkNextMonologue() {
    if (!this.nextMonologueElement) return true
    return this.nextMonologueElement.ts > this.clip!.end
  }

  private isWithinElementTimestamps(clipTimeStamp: number) {
    return (
      clipTimeStamp > this.currentElement.ts &&
      clipTimeStamp < this.currentElement.end_ts
    )
  }
}
