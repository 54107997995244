import { Clip, MonologueElement } from '@skribe/web/core'

function clipTimestampIsWithinElement(
  clipTimestamp: number,
  element: MonologueElement,
) {
  const elementStart = element.ts
  const elementEnd = element.end_ts
  return elementStart < clipTimestamp && elementEnd > clipTimestamp
}

function elementIsWithinCLip(clip: Clip, element: MonologueElement) {
  return (
    clipStartIsLessThanElementStart(clip, element) &&
    clipEndIsGreaterThanElementEnd(clip, element)
  )
}

function clipStartIsLessThanElementStart(
  clip: Clip,
  element: MonologueElement,
) {
  return clip.start <= element.ts
}

function clipEndIsGreaterThanElementEnd(clip: Clip, element: MonologueElement) {
  return clip.end >= element.end_ts
}

export function retrieveMatchingClip(element: MonologueElement, clips: Clip[]) {
  return clips.find(
    clip =>
      elementIsWithinCLip(clip, element) ||
      clipTimestampIsWithinElement(clip.start, element) ||
      clipTimestampIsWithinElement(clip.end, element),
  )
}
