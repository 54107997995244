import React from 'react'

export function useClipControls(props: {
  initialRange: number[]
  duration: number
}) {
  const { initialRange, duration } = props
  const [range, setRange] = React.useState<number[]>(initialRange)
  const step = 1
  const startMax = Math.min(duration, range[1] - step)

  function onStartInputChange(value: number | null) {
    ;(value || value === 0) && setRange([value, range[1]])
  }

  function onEndInputChange(value: number | null) {
    value && setRange([range[0], value])
  }

  return {
    range,
    setRange,
    step,
    startMax,
    onStartInputChange,
    onEndInputChange,
  }
}
