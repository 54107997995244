import bytes from 'bytes'
import { nanoid } from 'nanoid'

export const acceptedFileTypes = ['.mov', '.mpg', '.mp4', '.mp3', '.wav']
export const MAX_FILE_SIZE = bytes('8GB')
export const NANOID_LENGTH = 21

export function makeFileNameUnique(key: string) {
  const originalKeyParts = key.split('.')
  const fileExtension = originalKeyParts.pop()
  const fileName = originalKeyParts.join('.')
  return `${fileName}-${nanoid(NANOID_LENGTH)}.${fileExtension}`
}

export const extractNameFromUniqueFileName = (key: string) => {
  const originalKeyParts = key.split('.')
  originalKeyParts.pop()
  const fullNameWithId = originalKeyParts.join('.')
  const parts = fullNameWithId.substring(
    0,
    fullNameWithId.length - '-'.length - NANOID_LENGTH,
  )
  return parts
}

export const getExtensionName = (filename: string) =>
  filename.split('.').pop() ?? ''

export function isAudioUrl(url: string) {
  const path = url.split('?')[0]
  return path.endsWith('.mp3') || path.endsWith('.wav')
}
