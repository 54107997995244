import React from 'react'
import { Button, Tooltip } from 'antd'
import { DownloadOutlined } from '@ant-design/icons'
import { DownloadStatus } from '@skribe/web/core'

import { ErrorButton, LoadingComponent } from '../../../components'

import { DownloadItemProps, useDownloadItem } from './useDownloadItem'

export const DownloadItem: React.FC<DownloadItemProps> = props => {
  const { downloadStatus, requestDownload, download, error } =
    useDownloadItem(props)
  if (error) return <ErrorButton />
  return (
    <Tooltip title={props.tooltipMessage ?? 'Download'}>
      {downloadStatus === DownloadStatus.notStarted && (
        <Button
          icon={<DownloadOutlined />}
          type="text"
          onClick={requestDownload}
        />
      )}
      {downloadStatus === DownloadStatus.processing && <LoadingComponent />}
      {downloadStatus === DownloadStatus.ready && (
        <Button icon={<DownloadOutlined />} type="text" onClick={download} />
      )}
    </Tooltip>
  )
}
