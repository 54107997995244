import React from 'react'
import { FC } from 'react'
import { ErrorComponent, LoadingComponent } from '../components'
import { TranscriptPage } from './TranscriptPage'
import { useTranscriptContainer } from './useTranscriptContainer'

export const TranscriptContainer: FC = () => {
  const { transcript, error } = useTranscriptContainer()

  if (error) return <ErrorComponent error={error} />
  if (!transcript) return <LoadingComponent />

  return <TranscriptPage transcript={transcript} />
}
