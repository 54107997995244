import { useEffect, useRef, useState } from 'react'
import { Message, aiClient } from '@skribe/web/api'
import { writeAnswer } from './answer'
import { trackEvent } from '@skribe/web/tracking'
import { scrollToElementWithClass } from '@skribe/web'

export function useAiChat(transcriptId: string) {
  const [answer, setAnswer] = useState('')
  const [question, setQuestion] = useState('')
  const [completeQuestion, setCompleteQuestion] = useState('')
  const [isLoading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const historyRef = useRef<Message[]>([])

  const noQuestion = !question

  useEffect(() => {
    historyRef.current = []
    setCompleteQuestion('')
    clearChat()
  }, [transcriptId])

  const addToHistory = (value: Message) => {
    if (value.content !== '') {
      historyRef.current = [...historyRef.current, value]
    }
  }

  async function askQuestion(directQuestion?: string) {
    const finalQuestion = directQuestion ?? question
    if (!finalQuestion) return
    initializeChat(finalQuestion)

    scrollToElementWithClass('scroll-position', {
      block: 'start',
      inline: 'start',
      behavior: 'auto',
    })
    await withLoading(() => executeAskQuestion(finalQuestion))
  }

  function initializeChat(finalQuestion: string) {
    setCompleteQuestion(finalQuestion)
    trackEvent('sendAIQuestion', { question: finalQuestion })
    addToHistory({ content: completeQuestion, role: 'user' })
    addToHistory({ content: answer, role: 'assistant' })
    clearChat()
  }

  function clearChat() {
    setQuestion('')
    setAnswer('')
    setError('')
  }

  async function executeAskQuestion(finalQuestion: string) {
    try {
      const result = await aiClient.askTranscriptQuestion(
        transcriptId,
        finalQuestion,
        'gpt-4o',
        historyRef.current,
      )
      const reader = result.body!.getReader()
      await writeAnswer(reader, setAnswer)
    } catch (e: any) {
      setError(e.message)
    }
  }

  async function withLoading(func: () => Promise<void>) {
    setLoading(true)
    await func()
    setLoading(false)
  }

  return {
    response: answer,
    question,
    setQuestion,
    completeQuestion,
    isLoading,
    noQuestion,
    askQuestion,
    error,
    setError,
    history: historyRef.current,
  }
}
