import { DetailedUser, MatterPin } from '@skribe/web/core'
import { secureAPI } from './client'
import { Credentials } from '@aws-sdk/types'

export async function fetchUser(): Promise<DetailedUser> {
  return await secureAPI.get('rev', '/user', {}).catch(e => {
    console.error('Error on get User', { v: e })
    throw e
  })
}

export async function getUploadCredentials(): Promise<Credentials> {
  return await secureAPI.post('rev', `/user/credentials`, {}).then(c => ({
    accessKeyId: c.AccessKeyId,
    secretAccessKey: c.SecretAccessKey,
    sessionToken: c.SessionToken,
  }))
}

export async function upgradeUser() {
  return await secureAPI.post('rev', `/user/upgrade`, {})
}

export async function updateMatterPinTime(pinnedMatters: MatterPin[]) {
  return await secureAPI.patch('rev', `/user`, {
    body: { pinnedMatters },
  })
}
