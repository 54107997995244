import { FC } from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { matchesRole, ROLE } from '@skribe/web/core'
import { useGetRole } from './User/useGetRole'

export const RoleRoute: FC = () => {
  const userRole = useGetRole()
  const isLiaison = userRole === ROLE.LIAISON

  return Redirect(isLiaison ? '/liaison' : '/matters')
}

export const ProtectedRoute: FC<{
  role: ROLE[]
}> = ({ role }) => {
  const userRole = useGetRole()

  return matchesRole(userRole, role) ? <Outlet /> : Redirect('/')
}

function Redirect(route: string) {
  const from = useLocation()
  return <Navigate to={route} state={{ from }} replace />
}
