import ReactPlayer from 'react-player/file'
import { OnProgressProps } from 'react-player/base'
import { Alert, Skeleton } from 'antd'
import { PlayCircleOutlined } from '@ant-design/icons'
import { colors, isAudioUrl } from '@skribe/web/core'
import { useState } from 'react'

import { useTranscriptPlayerContext } from '../context'

export function MediaPlayer(props: {
  mediaUrl: string | undefined
  onProgressUpdate: (e: OnProgressProps) => void
  setTranscriptDuration: (d: number) => void
}) {
  const [buffering, setBuffering] = useState(true)
  const { playerRef, playing, stoppedPlaying } = useTranscriptPlayerContext()
  const { mediaUrl, onProgressUpdate } = props

  if (!mediaUrl) {
    return (
      <Alert
        type="warning"
        showIcon
        message="Good things come to those who wait"
        description="This media is not available yet."
      />
    )
  }

  if (!ReactPlayer.canPlay(mediaUrl)) {
    return (
      <Alert
        type="warning"
        showIcon
        message="Incompatible Media"
        description="This media is not supported by your browser."
      />
    )
  }

  return (
    <>
      {buffering && (
        <Skeleton.Node active style={{ width: '100%', height: '30vh' }}>
          <PlayCircleOutlined
            style={{ fontSize: 40, color: colors.disabled }}
          />
        </Skeleton.Node>
      )}
      <div
        className={`transcript-media ${
          isAudioUrl(mediaUrl) ? 'transcript-media-audio' : ''
        }`}
        style={{ display: buffering ? 'none' : 'flex' }}
      >
        <ReactPlayer
          controls
          width={'100%'}
          ref={playerRef}
          playing={playing}
          url={mediaUrl}
          progressInterval={100}
          onProgress={onProgressUpdate}
          onEnded={stoppedPlaying}
          onPause={stoppedPlaying}
          onDuration={props.setTranscriptDuration}
          onReady={() => {
            setBuffering(false)
          }}
        />
      </div>
    </>
  )
}
