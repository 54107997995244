import { INVALID_COST } from '../transcriptStrings'

const HUMAN_TRANSCRIPTION_COST_PER_MIN = 2
const RUSH_COST_PER_MIN = 1.5

export function calculateUpgradeCost(props: {
  durationInSeconds: number
  rush?: boolean
}): number | string {
  if (props.durationInSeconds) {
    const cost =
      HUMAN_TRANSCRIPTION_COST_PER_MIN + (props.rush ? RUSH_COST_PER_MIN : 0)
    return Math.ceil(props.durationInSeconds / 60) * cost
  }
  return NaN
}

export const formatCost = (cost: number | string) =>
  typeof cost === 'number' && !Number.isNaN(cost) ? `$${cost}` : INVALID_COST

export const rushDescription = `Rush will deliver your files up to 5x faster. Requesting Rush adds ${formatCost(
  RUSH_COST_PER_MIN,
)} / minute to the cost of your orders.`
