import React from 'react'
import { InputNumber } from 'antd'
import { parseTimestamp, timestamp } from '../../utilities/timeUtilities'

export const ClipStartInput: React.FC<{
  max: number
  range: number[]
  step: number
  onChange: (value: number | null) => void
}> = ({ max, range, onChange, step }) => {
  return (
    <InputNumber
      min={0}
      max={max}
      value={range[0]}
      onChange={onChange}
      data-testid="min-input"
      size="small"
      step={step}
      className="manual-clip-creator "
      formatter={timestamp}
      parser={parseTimestamp}
    />
  )
}

export const ClipEndInput: React.FC<{
  step: number
  range: number[]
  max: number
  onChange: (value: number | null) => void
}> = ({ max, range, onChange, step }) => {
  const min = Math.max(step, range[0] + step)
  return (
    <InputNumber
      min={min}
      max={max}
      value={range[1]}
      step={step}
      size="small"
      data-testid="max-input"
      className="manual-clip-creator"
      onChange={onChange}
      formatter={timestamp}
      parser={parseTimestamp}
    />
  )
}
