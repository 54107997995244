import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { trackEvent } from '@skribe/web/tracking'
import { Nullable } from '@skribe/web/core'
import { getSpeakers } from '@skribe/web/core'
import { ClipResponse, clipDb } from '@skribe/web/api'

export function useClipPage() {
  const { id } = useParams()
  const [clip, setClip] = useState<ClipResponse | undefined>(undefined)
  const [error, setError] = useState<unknown>()

  useEffect(() => {
    clipDb
      .fetchClip(id)
      .then(clipResponse => {
        setClip({
          ...clipResponse,
          speakers: getSpeakers(clipResponse),
        })
        trackEvent('viewClip', { clip })
      })
      .catch(e => {
        setError(e)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, setClip, setError])

  if (error) throw error
  return { clip, getUrl }

  function getUrl(_url: Nullable<string>) {
    return clip?.download_url ? clip?.download_url : clip?.media_url
  }
}
