import { FC } from 'react'
import { Link } from 'react-router-dom'
import { Card, Col, List, Row, Space, Typography } from 'antd'
import { TranscriptItem } from './TranscriptItem'

import { EventPageProps, useEventPage } from './useEventPage'
import { truncateText } from '@skribe/web/core'
import { EventLiveMeetingDrawer } from './EventLiveMeetingDrawer'
import {
  ActionMenu,
  createDeleteDropdownItem,
  generateEmptyPlaceholder,
} from '../components'
import { EventLibraryDrawer } from './EventLibraryDrawer'
import { LiveEventDetails } from './LiveEventDetails'

export const EventPage: FC<EventPageProps> = props => {
  const testId = 'eventPage'
  const { event } = props

  const {
    back,
    sortedTranscripts,
    onDeleteClick,
    onNameChange,
    navigateToTranscript,
  } = useEventPage({
    event: props.event,
  })

  return (
    <div data-testid={testId}>
      <Link to={back}>Back to matters</Link>
      <Card style={{ marginTop: '1rem' }}>
        <Space
          direction="vertical"
          size={event.zoom_event ? 'middle' : 'small'}
          style={{ display: 'flex' }}
        >
          <Row justify={'space-between'} align={'middle'}>
            <Col>
              <Typography.Title
                level={3}
                className="subtitle-1 d-block"
                editable={{
                  onChange: onNameChange,
                  text: event.name ?? '',
                }}
              >
                {truncateText(event.name)}
              </Typography.Title>
            </Col>
            <Col>
              <Space size={10}>
                <EventLiveMeetingDrawer event={props.event} />
                <EventLibraryDrawer event={props.event} />
                <ActionMenu
                  id="eventActionMenu"
                  items={[createDeleteDropdownItem(onDeleteClick)]}
                />
              </Space>
            </Col>
          </Row>
          {event.zoom_event && <LiveEventDetails event={event} />}
        </Space>
      </Card>
      <Typography.Title
        level={5}
        style={{
          paddingTop: '1rem',
          marginBottom: '0.4rem',
        }}
      >
        Transcripts
      </Typography.Title>
      <Row gutter={30}>
        <Col span={24}>
          <List
            bordered
            size="large"
            dataSource={sortedTranscripts}
            renderItem={item => (
              <TranscriptItem
                item={item}
                navigateToTranscript={() => navigateToTranscript(item)}
              />
            )}
            locale={generateEmptyPlaceholder('Create your first Transcript')}
          />
        </Col>
      </Row>
    </div>
  )
}

export default EventPage
