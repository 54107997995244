import {
  CreateMultipartUploadCommand,
  UploadPartCommand,
  CompleteMultipartUploadCommand,
  AbortMultipartUploadCommand,
  S3Client,
  UploadPartCommandOutput,
} from '@aws-sdk/client-s3'
import bytes from 'bytes'
import { progressTracker } from '@skribe/web'

export async function uploadMultipartFile(
  s3Client: S3Client,
  bucketName: string,
  key: string,
  file: File,
  Metadata: Record<string, string>,
) {
  let uploadId

  try {
    uploadId = await initializeUpload(s3Client, bucketName, key, Metadata)
    const uploadResults = await uploadParts(
      file,
      s3Client,
      bucketName,
      key,
      uploadId,
    )

    await confirmUpload(s3Client, bucketName, key, uploadId, uploadResults)
  } catch (err) {
    console.error(err)

    if (uploadId) {
      await abortUpload(s3Client, bucketName, key, uploadId)
    }

    throw err
  }
}
async function abortUpload(
  s3Client: S3Client,
  bucketName: string,
  key: string,
  uploadId: string,
) {
  await s3Client.send(
    new AbortMultipartUploadCommand({
      Bucket: bucketName,
      Key: key,
      UploadId: uploadId,
    }),
  )
}

async function confirmUpload(
  s3Client: S3Client,
  bucketName: string,
  key: string,
  uploadId: string | undefined,
  uploadResults: UploadPartCommandOutput[],
) {
  await s3Client.send(
    new CompleteMultipartUploadCommand({
      Bucket: bucketName,
      Key: key,
      UploadId: uploadId,
      MultipartUpload: {
        Parts: uploadResults.map(({ ETag }, i) => ({
          ETag,
          PartNumber: i + 1,
        })),
      },
    }),
  )
}

export const PART_SIZE = bytes('10mb')

export function calculatePartsNumber(fileBytes: number) {
  return Math.ceil(fileBytes / PART_SIZE)
}

async function uploadParts(
  file: File,
  s3Client: S3Client,
  bucketName: string,
  key: string,
  uploadId: string,
): Promise<UploadPartCommandOutput[]> {
  const partsNumber = calculatePartsNumber(file.size)

  const uploadResults: UploadPartCommandOutput[] = []

  for (let i = 0; i < partsNumber; i++) {
    const start = i * PART_SIZE
    const end = start + PART_SIZE
    uploadResults.push(
      await setupPart(
        s3Client,
        bucketName,
        key,
        uploadId,
        file,
        start,
        end,
        i,
        partsNumber,
      ),
    )
  }

  return uploadResults
}

async function setupPart(
  s3Client: S3Client,
  bucketName: string,
  key: string,
  uploadId: string,
  file: File,
  start: number,
  end: number,
  i: number,
  partNumber: number,
) {
  const buffer = new Uint8Array(await file.slice(start, end).arrayBuffer())
  const PartNumber = i + 1
  const d = await s3Client.send(
    new UploadPartCommand({
      Bucket: bucketName,
      Key: key,
      UploadId: uploadId,
      Body: buffer,
      PartNumber,
    }),
  )
  console.log(`PartNumber ${PartNumber} is uploaded`, { d, file })
  if (uploadId) {
    progressTracker.set(PartNumber, partNumber, (file as any)?.uid)
  }
  return d
}

async function initializeUpload(
  s3Client: S3Client,
  bucketName: string,
  key: string,
  Metadata: Record<string, string>,
) {
  const multipartUpload = await s3Client.send(
    new CreateMultipartUploadCommand({
      Bucket: bucketName,
      Key: key,
      Metadata,
    }),
  )
  console.log({ multipartUpload })
  const UploadId = multipartUpload.UploadId
  if (!UploadId) throw Error('Upload failed to initialize')
  return UploadId
}
