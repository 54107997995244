import { useCallback, useEffect, useState } from 'react'
import { DownloadStatus, statusFor } from '@skribe/web/core'
import { downloadPresignedUrl } from '@skribe/web'

export interface DownloadItemProps {
  item: {
    download_url?: string | null
    id: string
  }
  requestDownload: () => Promise<void>
  trackDownload?: () => void
  tooltipMessage?: string
}

export function useDownloadItem(props: DownloadItemProps) {
  const [downloadStatus, setDownloadStatus] = useState(
    statusFor(props.item.download_url),
  )
  const [shouldDownloadWhenReady, setShouldDownloadWhenReady] = useState(false)
  const [error, setError] = useState<Error | undefined>(undefined)
  const download = useCallback(() => {
    if (props.trackDownload) props.trackDownload()
    if (props.item.download_url) {
      downloadPresignedUrl(props.item.download_url)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.item.download_url])

  useEffect(() => {
    setDownloadStatus(statusFor(props.item.download_url))
  }, [props.item.download_url])

  useEffect(() => {
    if (
      shouldDownloadWhenReady &&
      statusFor(props.item.download_url) === DownloadStatus.ready
    ) {
      download()
      setShouldDownloadWhenReady(false)
    }
  }, [props.item.download_url, shouldDownloadWhenReady, download])

  return {
    downloadStatus,
    requestDownload,
    download,
    downloadOnReady: shouldDownloadWhenReady,
    error,
  }

  async function requestDownload() {
    try {
      await props.requestDownload()
      setDownloadStatus(DownloadStatus.processing)
      setShouldDownloadWhenReady(true)
    } catch (error) {
      setError(error as any as Error)
    }
  }
}
