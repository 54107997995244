import { NavigateFunction } from 'react-router-dom'
import { TabTitle } from '../utilities'
import { MatterWithEvents, UserGroup } from '@skribe/web/core'
import AddMatterButton from './AddMatterButton'
import { MatterList } from './MatterList'
import { PRIVATE } from './useMatterSelector'

export function displayGroupTabs(
  matters: MatterWithEvents[],
  viewModel: {
    navigate: NavigateFunction
    tabTitle: TabTitle | undefined
    onDeleteMatter: (id: string | undefined) => Promise<void>
    defaultTab: string
    groups: UserGroup[]
    onCreateMatter: (groupId?: string) => Promise<void>
    selectedGroup: string | null | undefined
    selectedMatter: MatterWithEvents | null
    isCognitoUser: boolean
  },
  privateMatters: JSX.Element,
) {
  const cognitoUser = viewModel.isCognitoUser
  const groupTabs = [
    ...viewModel.groups
      .sort((a, b) => a.name.localeCompare(b.name))
      .map(group => ({
        key: group.id,
        label: <div data-testid={`${group.id}Tab`}>{group.name}</div>,
        children: (
          <>
            <AddMatterButton
              data-testid="addMatter"
              groupId={group.id}
              onCreateMatter={viewModel.onCreateMatter}
            />
            <MatterList
              data-testid={`${group.id}Matters`}
              matters={matters?.filter(matter =>
                matter.groups?.includes(group.id),
              )}
              selected={viewModel.selectedMatter}
              onDelete={viewModel.onDeleteMatter}
            />
          </>
        ),
      })),
  ]

  if (cognitoUser) {
    const privateTab = {
      key: PRIVATE,
      label: <div data-testid="PrivateTab">{PRIVATE}</div>,
      children: privateMatters,
    }
    groupTabs.unshift(privateTab)
  }

  return groupTabs
}
