import React, { useEffect } from 'react'
import {
  DatePicker,
  Form,
  Row,
  Tooltip,
  theme,
  List,
  Switch,
  Typography,
  Select,
  Alert,
  Input,
} from 'antd'
import { useScheduleLiveMeetingForm } from './useScheduleLiveMeetingForm'
import {
  OnSubmitLiveMeetingForm,
  ScheduleLiveMeetingFormShape,
} from './ScheduleLiveMeetingFormTypes'
import { toLocalDateTime, toHourString } from '@skribe/web/date'
import { LoadingComponent } from '../LoadingComponent'
import { InternalSelectProps } from 'antd/es/select'
import { duration } from 'dayjs'
import './ScheduleLiveMeetingForm.css'
import { FormError } from '../error/FormError'
import { FormButtons } from '../FormButtons'
import { Event } from '@skribe/web/core'

type ScheduleLiveMeetingFormProps = {
  event?: Event
  onClose: () => void
  onSubmit: OnSubmitLiveMeetingForm
  errorMessage: string
  defaultEventName?: string | null
}
export const ScheduleLiveMeetingForm: React.FC<
  ScheduleLiveMeetingFormProps
> = ({ event, onClose, onSubmit, errorMessage, defaultEventName }) => {
  const {
    scheduleMeeting,
    isOutOfBounds,
    loading,
    shouldShowForm,
    shouldShowError,
    isLimit,
    initialFormValues,
    isSubmitEnabled,
    setIsSubmitEnabled,
  } = useScheduleLiveMeetingForm({
    event,
    onSubmit,
    defaultEventName,
  })

  const [form] = Form.useForm<ScheduleLiveMeetingFormShape>()
  const { token } = theme.useToken()
  const values = Form.useWatch([], form)
  useEffect(() => {
    if (form.isFieldsTouched(['durationInMinutes', 'start'])) {
      form
        .validateFields({ validateOnly: true })
        .then(() => {
          setIsSubmitEnabled(true)
        })
        .catch(() => {
          setIsSubmitEnabled(false)
        })
    }
  }, [form, values, setIsSubmitEnabled])

  function getTimeDurationOptions() {
    const arr = Array.from({ length: 8 }, (_, i) =>
      duration(30, 'minute').add(i * 30, 'minute'),
    )
    return arr
  }

  const durationOptions: InternalSelectProps['options'] =
    getTimeDurationOptions().map(d => ({
      label: toHourString(d),
      value: d.asMinutes(),
    }))

  return (
    <>
      {loading && <LoadingComponent />}
      {shouldShowForm && (
        <Form
          form={form}
          onFinish={scheduleMeeting}
          initialValues={initialFormValues}
          disabled={loading}
          labelAlign="left"
          className="schedule-form"
        >
          {defaultEventName && (
            <Form.Item
              labelCol={{ span: 24 }}
              name="eventName"
              label="Event Name"
              rules={[
                { required: true, message: 'Please enter a name.' },
                {
                  type: 'string',
                  whitespace: true,
                  message: 'Please enter a name.',
                },
              ]}
            >
              <Input />
            </Form.Item>
          )}
          <Form.Item
            labelCol={{ span: 24 }}
            name="start"
            label="Start Date/Time"
            rules={[
              { required: true, message: 'Please pick a valid date/time.' },
            ]}
          >
            <DatePicker
              data-testid="scheduleDatePicker"
              disabledDate={date => isOutOfBounds(date as any)}
              showNow
              style={{ width: '100%' }}
              allowClear={false}
              showTime={{ minuteStep: 15, use12Hours: true, format: 'hh:mm A' }}
              cellRender={(current, info) => {
                if (info.type !== 'date') return info.originNode

                if (typeof current === 'number')
                  return <div className="ant-picker-cell-inner">{current}</div>

                const limit = isLimit(current)
                return limit ? (
                  <Tooltip title="90 Day schedule limit">
                    <div
                      className="ant-picker-cell-inner"
                      style={{
                        border: `1px solid ${token.colorPrimary}`,
                        borderRadius: '50%',
                      }}
                    >
                      {current.date()}
                    </div>
                  </Tooltip>
                ) : (
                  <div className="ant-picker-cell-inner">{current.date()}</div>
                )
              }}
              format={toLocalDateTime}
            />
          </Form.Item>
          <Form.Item
            name="durationInMinutes"
            label="Estimated Duration"
            labelCol={{ span: 24 }}
            style={{
              marginBottom: '1rem',
            }}
          >
            <Select options={durationOptions} />
          </Form.Item>
          <Alert
            style={{ marginBottom: '2rem' }}
            message="The final bill will reflect actual recorded time."
            type="info"
          />
          <Row>
            <Typography.Text className="form-title">Options</Typography.Text>
          </Row>
          <List
            bordered
            size="small"
            dataSource={[
              { name: 'hasLiaison', label: 'Liaison' },
              { name: 'hasAI', label: 'Rough AI' },
              { name: 'hasHumanProofreading', label: 'Professional Proofread' },
            ]}
            renderItem={({ name, label }) => (
              <List.Item className="switch-list">
                <Row justify={'space-between'} style={{ width: '100%' }}>
                  <Typography.Text style={{ alignSelf: 'center' }}>
                    {label}
                  </Typography.Text>
                  <Form.Item
                    name={name}
                    valuePropName="checked"
                    style={{ marginBottom: 0 }}
                  >
                    <Switch disabled />
                  </Form.Item>
                </Row>
              </List.Item>
            )}
          />
          <FormButtons
            onClose={onClose}
            saveDisabled={!isSubmitEnabled}
            saveTestId="liveScheduleEventButton"
            onSave={form.submit}
          />
        </Form>
      )}
      {shouldShowError && (
        <FormError onClose={onClose} message={errorMessage} />
      )}
    </>
  )
}
