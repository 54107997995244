import { createContext, FC, ReactNode } from 'react'
import {
  AuthContextType,
  defaultAuthContext,
  useAuthProvider,
} from './useAuthProvider'

export const AuthContext = createContext<AuthContextType>(defaultAuthContext)

export const AuthProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const value = useAuthProvider()

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}
