import { useEffect } from 'react'

export function useClearInterval() {
  useEffect(() => {
    const intervalId = sessionStorage.getItem('intervalId')
    if (intervalId) {
      clearInterval(Number(intervalId))
    }
  }, [])
}

export function changeAlertForForcedMigratedUsers() {
  const intervalId = window.setInterval(() => {
    const loginBoxShadowRoot = window.document.getElementById(
      'frontegg-login-box-container-default',
    )?.shadowRoot
    const loginErrorDiv = loginBoxShadowRoot?.querySelector(
      '[data-test-id="login-error"]',
    )
    if (loginErrorDiv) {
      console.log('Login Error Div was there')
      const alertText = loginErrorDiv.children[1]
      if (
        alertText.textContent ===
        'You must reset your password in order to log in - please check your email.'
      ) {
        alertText.textContent =
          'Your account password was reset as part of the enhanced login changes.\n' +
          'Please check your email for a link to reset or choose "Forgot Password" above.'
      }
    }
  }, 200)
  sessionStorage.setItem('intervalId', intervalId.toString())
}

export function useChangeAlertForForceMigratedUsers() {
  useEffect(() => {
    changeAlertForForcedMigratedUsers()
  }, [])
}
