import { userDb } from '@skribe/web/api'
import {
  Image,
  message,
  theme,
  UploadFile as AntdUploadFile,
  UploadProps,
  Progress,
} from 'antd'
import { makeFileNameUnique, MAX_FILE_SIZE } from '@skribe/web/core'
import { ReactNode, useEffect, useState } from 'react'
import { ExclamationOutlined, LoadingOutlined } from '@ant-design/icons'
import { putObject } from '@skribe/web/storage'
import bytes from 'bytes'
import { useQuery } from '@tanstack/react-query'
import { progressTracker } from '@skribe/web'

export function useUploader(props: UploaderProps) {
  const [fileList, setFileList] = useState<UploadFile[]>([])

  const { data: uploadsProgress } = useQuery({
    queryKey: ['upload', props.metadata?.matterId],
    queryFn: () => {
      return progressTracker.get()
    },
    refetchInterval: fileList.length && 200,
  })

  useEffect(() => {
    if (uploadsProgress) {
      setFileList(list =>
        list.map(file => {
          return {
            ...file,
            percent: progressTracker.calculatePercent(uploadsProgress, file),
          }
        }),
      )
    }
  }, [uploadsProgress, setFileList])

  function addItem(file: AntdUploadFile) {
    setFileList(l => [...l, convert(file)])
  }

  function updateItem(uid: string, changes: Partial<UploadFile>) {
    setFileList(l => l.map(f => (f.uid === uid ? { ...f, ...changes } : f)))
  }

  function removeFromFileList(file: UploadFile) {
    setFileList(l => l.filter(f => f.uid !== file.uid))
    props.onRemove(file.fileName)
  }

  const { token } = theme.useToken()
  const beforeUpload: UploadProps['beforeUpload'] = file => {
    if (file.size > MAX_FILE_SIZE) {
      message.error(
        `File size greater than max file size (${bytes.format(MAX_FILE_SIZE, {
          unitSeparator: ' ',
        })})`,
      )
    } else {
      addItem(file)
    }
    return false
  }

  const onChange: UploadProps['onChange'] = async ({ file: refFile }) => {
    const file = fileList.find(f => f.uid === refFile.uid)
    if (!file) return

    try {
      if (file.status === 'removed') {
        removeFromFileList(file)
        return
      }

      props.onUploadStart()

      const credentials = await userDb.getUploadCredentials()
      await putObject(
        refFile as any as File,
        credentials,
        file.fileName,
        props.metadata,
      )

      updateItem(file.uid, { status: 'done' })
      props.onUploadSuccess({ key: file.fileName })
    } catch (e) {
      console.error(e)
      updateItem(file.uid, { status: 'error' })
      props.resetLoader()
    }
  }

  const iconRender: UploadProps['iconRender'] = file => {
    if (file.status === 'uploading') {
      if (file.percent)
        return (
          <Progress
            type="circle"
            percent={file.percent}
            size={20}
            style={{ paddingLeft: '0.5rem' }}
          />
        )
      return <LoadingOutlined style={{ fontSize: 24 }} />
    } else if (file.status === 'error')
      return <ExclamationOutlined style={{ color: token.colorError }} />
    else
      return (
        <Image
          data-testid="uploaded-icon"
          src="/transcript-item.svg"
          alt="Transcript"
          preview={false}
        />
      )
  }

  return {
    beforeUpload,
    onChange,
    iconRender,
    token,
    fileList,
  }
}
export interface UploaderProps {
  maxCount?: number
  children: ReactNode
  onUploadStart: () => void
  onUploadSuccess: (event: { key?: string | undefined }) => void
  onRemove: (key: string) => void
  resetLoader: () => void
  metadata?: Record<string, string>
}
export type UploadFile = AntdUploadFile<any> & {
  fileName: string
}
function convert(file: AntdUploadFile): UploadFile {
  return {
    uid: file.uid,
    status: 'uploading',
    name: file.name,
    size: file.size,
    lastModified: file.lastModified,
    lastModifiedDate: file.lastModifiedDate,
    fileName: makeFileNameUnique(file.name),
  }
}
