import { Link } from 'react-router-dom'
import { Modal, Typography } from 'antd'
import { useUpgrade } from './useUpgrade'
const { Paragraph } = Typography

export function useUpgradeModal() {
  const props = useUpgrade()

  const upgradeModalContext = props.isTrial && (
    <Modal
      title="Upgrade to usage based billing"
      open={props.modalOpen}
      okText="Confirm"
      cancelText="Not Yet"
      centered
      onOk={props.upgrade}
      onCancel={props.closeModal}
      width={600}
    >
      <Paragraph>
        By clicking 'Confirm' below, you understand that you will be billed
        according to how much you use Skribe from this point forward.
      </Paragraph>
      <Paragraph>
        Please review our{' '}
        <Link to="/pricing" onClick={props.closeModal}>
          payment
        </Link>{' '}
        options for using our product.
      </Paragraph>
      <Paragraph>
        If you have any questions, feel free to email us at{' '}
        <a href="mailto:contact@skribe.ai?subject=Tell me more about Skribe">
          contact@skribe.ai
        </a>
        .
      </Paragraph>
      <br />
    </Modal>
  )

  return { ...props, upgradeModalContext }
}
