import { FC } from 'react'
import { List, Space, Typography } from 'antd'
import { MatterWithEvents } from '@skribe/web/core'
import { useSelectedMatter } from './useSelectedMatter'
import { truncateText } from '@skribe/web/core'
import { EventItem } from '../Events/EventListItem'
import { OrderEventDrawer } from './OrderEventDrawer'
import {
  AddMeetingAssistantDrawer,
  generateEmptyPlaceholder,
} from '../components'
import { useFeature } from 'flagged'

type SelectedMatterProps = {
  selected: MatterWithEvents
}

export const SelectedMatter: FC<SelectedMatterProps> = ({
  selected,
  ...props
}) => {
  const { onMatterNameChange, onDeleteEvent } = useSelectedMatter(selected)
  const meetingAssistant = useFeature('externalMeetingAssistant')

  return (
    <div {...props} className="selected-matter">
      <Typography.Title
        level={3}
        className="subtitle-1 d-block"
        style={{ marginBottom: '0.5em' }}
        editable={{
          onChange: value => onMatterNameChange(selected?.id, value),
          text: selected.name ?? '',
        }}
      >
        {truncateText(selected.name)}
      </Typography.Title>
      <Space size={10} style={{ marginBottom: '1.5rem' }}>
        <OrderEventDrawer matter={selected} />
        {meetingAssistant && <AddMeetingAssistantDrawer />}
      </Space>
      <List
        bordered
        rowKey="id"
        size="large"
        renderItem={event => (
          <EventItem event={event} onDelete={onDeleteEvent} />
        )}
        dataSource={selected.Events}
        locale={generateEmptyPlaceholder('No Events')}
      />
    </div>
  )
}
