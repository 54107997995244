import { Matter } from '@skribe/web/core'
import { trackEvent } from '@skribe/web/tracking'
import { eventDb } from '@skribe/web/api'
import { ScheduleLiveMeetingFormShape } from '../components'
import { useNavigate } from 'react-router-dom'
import { useUserContext } from '../User/UserProvider'

export const useOrderEventDrawer = ({ matter }: { matter: Matter }) => {
  const navigate = useNavigate()
  const { user } = useUserContext()

  const onLiveEventSave = async (
    formData: ScheduleLiveMeetingFormShape,
  ): Promise<void> => {
    if (!formData.eventName || !formData.eventName.trim())
      throw Error('name required')
    if (!formData.start) throw Error('start required')

    const event = await eventDb.createEvent(matter, formData.eventName)
    if (!event) throw Error('Event could not be created')
    trackEvent('createEvent', { parent: matter.id, type: 'live' })

    const meetingDate = formData.start.toISOString()
    await eventDb.createLiveMeeting(
      event.id,
      meetingDate,
      formData.durationInMinutes,
    )

    navigate(`/events/${event.id}`)
  }

  const createLibraryEvent = async (eventName: string) => {
    const event = await eventDb.createEvent(matter, eventName)

    if (!event) throw Error('Event could not be created')
    trackEvent('createEvent', { parent: matter.id, type: 'library' })

    return event.id
  }

  return {
    isTrial: user.trial ?? undefined,
    createLibraryEvent,
    onSubmit: onLiveEventSave,
    onTrialLiveEvent,
  }

  function onTrialLiveEvent(isVisible: boolean): void {
    if (isVisible) trackEvent('wantsLiveEvent')
  }
}
