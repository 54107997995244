import { Popover } from 'antd'
import { ReactNode } from 'react'
import { useUpgradeModal } from '../upgrade/useUpgradeModal'

export function TrialComponent({
  children,
  onTooltip,
}: {
  children: ReactNode
  onTooltip: (isVisible: boolean) => void
}) {
  const { openModal, upgradeModalContext } = useUpgradeModal()

  return (
    <>
      {upgradeModalContext}
      <Popover
        content={
          <>
            Upgrade your account to unlock all features.
            <br />
            <a href="#/" onClick={openModal}>
              Click here to upgrade.
            </a>
          </>
        }
        trigger="hover"
        onOpenChange={onTooltip}
      >
        {children}
      </Popover>
    </>
  )
}
