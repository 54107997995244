import { useContext } from 'react'
import { useInvalidateData } from '../state'
import { convertStartToPositiveNumber } from '../utilities/timeUtilities'
import { Clip, ClipTime } from '@skribe/web/core'
import { OnProgressProps } from 'react-player/base'
import { TranscriptPlayerContext } from './TranscriptPlayerContext'
import { clipDb } from '@skribe/web/api'
import { orderBy } from 'lodash'

export const useTranscriptPlayerContext = () => {
  const context = useContext(TranscriptPlayerContext)
  const { refreshClips } = useInvalidateData()

  if (!context)
    throw Error('Trying to use context outside of TranscriptPlayerContext')
  const { setPlaying, setClipEnd, setSelectedClip, ...ctx } = context

  function seek(amount: number) {
    if (!context?.playerRef?.current) return

    context?.playerRef.current.seekTo(convertStartToPositiveNumber(amount))
    setPlaying(true)
  }

  function _startMediaPlayer(c: ClipTime) {
    seek(c.start)
    setClipEnd(c.end)
  }

  function _selectAndPlayClip(c: Clip) {
    selectClip(c)
    _startMediaPlayer(c)
  }
  function _resetState() {
    context?.setPlaylistClips([])
    context?.setSelectedClip(undefined)
    context?.setSelectedPlaylistId('')
  }

  function playClipTime(c: ClipTime) {
    _resetState()
    _startMediaPlayer(c)
  }

  function playClips(_clips: Clip[]) {
    const clips = orderBy(_clips, 'sequence')
    context?.setPlaylistClips(clips)
    _selectAndPlayClip(clips[0])
  }

  function playClip(c: Clip) {
    playClips([c])
  }

  function selectClip(clip: Clip) {
    if (context?.selectedClip && clip.id !== context?.selectedClip?.id)
      stoppedPlaying()
    setSelectedClip(clip)
  }

  function _playNextClipInPlaylist() {
    if (!context) return
    const selectedClipId = context.selectedClip?.id ?? ''
    const currentClipIndex = context.playlistClips.findIndex(
      clip => clip.id === selectedClipId,
    )
    if (currentClipIndex < 0) {
      console.log('current clip not found in playlist clip list')
      return
    }
    if (currentClipIndex < context.playlistClips.length - 1) {
      const nextClip = context.playlistClips[currentClipIndex + 1]
      playClip(nextClip)
    }
  }

  function checkForClipEnd(progress: OnProgressProps) {
    const reachedClipEnd =
      context?.clipEnd && context?.clipEnd <= progress.playedSeconds
    if (reachedClipEnd) {
      setPlaying(false)
      setClipEnd(undefined)
      _playNextClipInPlaylist()
    }
  }

  function stoppedPlaying() {
    setPlaying(false)
  }

  async function createClip(
    start: number,
    end: number,
    name: string,
    transcriptId: string,
  ) {
    const created = await clipDb.addClip(transcriptId, start, end, name)
    await refreshClips()
    if (!created) return
    selectClip(created)
    playClip(created)
  }

  return {
    ...ctx,
    seek,
    playClip,
    playClipTime,
    createClip,
    checkForClipEnd,
    stoppedPlaying,
    selectClip,
    playClips,
  }
}
