import { Link } from 'react-router-dom'
import { Image } from 'antd'

function Logo() {
  return (
    <Link to="/">
      <Image
        src="/skribe-logo.svg"
        style={{ height: '2rem' }}
        alt="Skribe Logo"
        preview={false}
      />
    </Link>
  )
}

export default Logo
