import { Alert } from 'antd'
import { useUpgradeModal } from '../upgrade/useUpgradeModal'

const TRIAL_USAGE_LIMIT_MINUTES = 30

export const TrialBanner: React.FC = () => {
  const { isTrialExpired, openModal, isTrial, remaining, upgradeModalContext } =
    useUpgradeModal()

  if (!isTrial) return null

  return (
    <>
      {upgradeModalContext}
      <Alert
        data-testid="trialBanner"
        type="info"
        className="trial-banner"
        message={
          isTrialExpired ? (
            `Trial done! You've Skribed for ${TRIAL_USAGE_LIMIT_MINUTES} minutes. Click here to upgrade.`
          ) : (
            <>
              You can upload{' '}
              <strong>
                {remaining.minutes}m {remaining.seconds}s
              </strong>{' '}
              more content on your free trial. Click this banner to upgrade!
            </>
          )
        }
        onClick={openModal}
      />
    </>
  )
}

export default TrialBanner
