import { secureAPI } from './client'
import {
  CustomVocabulary,
  extractNameFromUniqueFileName,
  GetTranscriptResponse,
  Speaker,
  Transcriber,
  Transcript,
} from '@skribe/web/core'

export async function getTranscript(
  id: string | undefined,
): Promise<GetTranscriptResponse> {
  return await secureAPI.get('rev', `/transcripts/${id}`, {}).catch(e => {
    console.error('Error on get Transcript', { v: e })
    throw e
  })
}

export const createJob = async (
  fileName: string,
  eventID: string,
  userId: string,
) => {
  try {
    await secureAPI.post('rev', '/transcripts', {
      body: {
        eventID,
        fileName: `public/inbox/${fileName}`,
        owner: userId,
        transcriptName: extractNameFromUniqueFileName(fileName),
      },
    })
  } catch (error) {
    console.error('Error on POST /transcripts', { error })
  }
}

export async function regeneratePdf(
  id: string | undefined,
  transcriber: Transcriber,
) {
  await secureAPI.post('rev', `/transcripts/${id}/regenerate-pdf`, {
    body: { transcriber },
  })
}

export type PostHumanTranscriptBody = {
  rush: boolean
  customVocabularies?: CustomVocabulary[] | undefined
  reuseSpeakers: boolean
}

export async function postHumanTranscript(
  id: string | undefined,
  body?: PostHumanTranscriptBody,
) {
  await secureAPI.post('rev', `/transcripts/${id}/order-human-transcript`, {
    body,
  })
}

export async function fetchTranscripts(eventID: string) {
  if (!eventID) return []

  return (await secureAPI.get(
    'rev',
    `/transcripts?eventId=${eventID}`,
    {},
  )) as Transcript[]
}

export async function listInProgressTranscripts(): Promise<Transcript[]> {
  return await secureAPI
    .get('rev', `/orders?filter[status]=in_progress`, {})
    .then(v => v.transcripts)
}

export async function updateName(
  id: string,
  name: string,
): Promise<Transcript> {
  return await secureAPI.patch('rev', `/transcripts/${id}`, {
    body: { name },
  })
}

export async function updateSpeakerNamesMachine(
  id: string,
  speakers: Speaker[],
) {
  return await secureAPI.patch('rev', `/transcripts/${id}`, {
    body: { speakers_machine: speakers },
  })
}

export async function updateSpeakerNamesHuman(id: string, speakers: Speaker[]) {
  return await secureAPI.patch('rev', `/transcripts/${id}`, {
    body: { speakers_human: speakers },
  })
}

export async function deleteTranscript(id: string | undefined) {
  return await secureAPI.del('rev', `/transcripts/${id}`, {})
}
