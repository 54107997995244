import { useEffect, useRef, useState } from 'react'
import debounce from 'lodash/debounce'
import { truncateText } from '@skribe/web/core'
import {
  addEventListener,
  getSelectedText,
  getSelection,
  removeEventListener,
} from './dom'
import { TimeSpan, extractTimeSpanForWord, getTimeSpanOf } from './domTimeSpans'

export const useSelectionV1 = () => {
  const [transcriptSelection, setTranscriptSelection] =
    useState<null | TimeSpan>(null)
  const ref = useRef<HTMLDivElement>(null)
  const [text, setText] = useState<null | string>(null)

  function resetSelection() {
    setTranscriptSelection(null)
    setText(null)
  }

  const handleSelection = debounce(() => {
    const sel = getSelection()
    if (!ref.current) {
      resetSelection()
      return
    }
    if (!sel || sel.isCollapsed) {
      resetSelection()
      return
    }

    const range = sel.getRangeAt(0)
    if (!range) {
      console.warn("Range doesn't exist")
      resetSelection()
      return
    }

    const children = ref?.current?.getElementsByTagName('*')
    let allSelectedElements = Array.from(children).reduce<Element[]>(
      (elements, element) =>
        sel.containsNode(element, true) ? [...elements, element] : elements,
      [],
    )
    const validSelectedElements = allSelectedElements
      .map(extractTimeSpanForWord)
      .filter(ts => ts !== null)

    let selectedText = getSelectedText(allSelectedElements)
    selectedText = truncateText(selectedText)

    const { start, end } = getTimeSpanOf(validSelectedElements)

    if (start < end) {
      setTranscriptSelection({
        start: start,
        end: end,
      })
      setText(selectedText)
    } else {
      resetSelection()
    }
  }, 250)

  useEffect(() => {
    addEventListener('selectionchange', handleSelection)
    return () => removeEventListener('selectionchange', handleSelection)
  })

  return { selection: transcriptSelection, transcriptText: text, ref }
}
