import { STATUS } from './api'

export enum DownloadStatus {
  notStarted,
  processing,
  ready,
}

export function statusFor(download_url: string | null | undefined) {
  if (!download_url) return DownloadStatus.notStarted
  if (download_url.includes(STATUS.Processing)) return DownloadStatus.processing
  return DownloadStatus.ready
}

export function areAnyProcessing(
  items: { download_url?: string | null | undefined }[],
) {
  return items.some(
    item => statusFor(item.download_url) === DownloadStatus.processing,
  )
}
