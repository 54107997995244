import { FC, ReactNode, useContext } from 'react'
import { Layout as AntLayout } from 'antd'

import { Outlet } from 'react-router-dom'
import { TrialBanner, LoadingComponent } from '../components'
import { MatterProvider } from '../Matters/MatterProvider'
import { AuthContext } from '../User/AuthProvider'
import { Header } from './Header'
import { matchesRole, ROLE } from '@skribe/web/core'
import { ChatPanel } from '../TranscriptPage/Ai/ChatPanel'

export type LayoutProps = {
  children?: ReactNode
  userRole?: ROLE
}

export const BaseLayout: FC<LayoutProps> = ({ children, userRole }) => {
  return (
    <MatterProvider>
      <AntLayout className="Layout" data-testid={'layout'}>
        <Header userRole={userRole} />
        <AntLayout>
          <AntLayout.Content className="content">{children}</AntLayout.Content>
          {matchesRole(userRole, [ROLE.USER]) && <ChatPanel />}
        </AntLayout>
      </AntLayout>
    </MatterProvider>
  )
}

export const Layout: FC<LayoutProps> = ({ children }) => {
  const { isLoading, role } = useContext(AuthContext)

  const content = children ? children : <Outlet />

  return (
    <BaseLayout userRole={role}>
      {matchesRole(role, [ROLE.USER]) && <TrialBanner />}
      {isLoading ? <LoadingComponent /> : content}
    </BaseLayout>
  )
}
