import { Col, Row, Typography } from 'antd'
import { YoutubeOutlined } from '@ant-design/icons'
import { colors } from '@skribe/web/core'

export function ClipListPlaceholder() {
  return (
    <Col style={{ textAlign: 'center', marginTop: '20px' }}>
      <Row justify={'center'} style={{ color: colors.disabled }}>
        <YoutubeOutlined style={{ fontSize: '5rem' }} />
      </Row>
      <Row justify={'center'}>
        <Typography.Text strong type="secondary">
          Create Your First Clip
        </Typography.Text>
      </Row>
      <Row style={{ textAlign: 'start' }}>
        <Typography.Text type="secondary">
          <ul style={{ listStyleType: 'disc' }}>
            <li>Highlight the text you want to save.</li>
            <li>Click 'Create clip' that pops up near your selection.</li>
            <li>Your clip will show up here.</li>
          </ul>
        </Typography.Text>
      </Row>
    </Col>
  )
}
