import { useQueryClient } from '@tanstack/react-query'

export const useInvalidateData = () => {
  const queryClient = useQueryClient()
  return {
    refreshMatters: () =>
      queryClient.invalidateQueries({ queryKey: ['matters'] }),
    refreshClips: () => queryClient.invalidateQueries({ queryKey: ['clips'] }),
    refreshEvent: () => queryClient.invalidateQueries({ queryKey: ['event'] }),
    refreshTranscript: () =>
      queryClient.invalidateQueries({ queryKey: ['transcript'] }),
    refreshPlaylists: () =>
      queryClient.invalidateQueries({ queryKey: ['playlists'] }),
  }
}
