const SECONDS_PER_MINUTE = 60
const LARGE_CLIP_THRESHOLD = 3 * SECONDS_PER_MINUTE
const SMALL_CLIP_SIZE = 15

export function getDefaultClipRangeEnd(durationSecs: number) {
  if (durationSecs > LARGE_CLIP_THRESHOLD) {
    return durationSecs * 0.2
  }
  return Math.min(durationSecs, SMALL_CLIP_SIZE)
}
