import { Button } from 'antd'
import { ReactComponent as AiSendButton } from '../icons/ai-send.svg'

export function ChatButton(props: { onClick: () => void; disabled: boolean }) {
  return (
    <Button
      onClick={props.onClick}
      type="primary"
      shape="circle"
      style={{ display: 'block' }}
      icon={<AiSendButton />}
      disabled={props.disabled}
    />
  )
}
