import { Button, Select, Space } from 'antd'
import { DetailedEvent } from '@skribe/web/core'
import { useAddLiaisonField, sortAlphabetically } from './UseAddLiaisonField'

export const AddLiaisonField: React.FC<{ event: DetailedEvent }> = ({
  event,
}) => {
  const {
    notificationComponent,
    availableLiaisons,
    liaison,
    setLiaison,
    submitAddLiaison,
    placeholder,
  } = useAddLiaisonField(event)

  return (
    <>
      {notificationComponent}
      <Space.Compact style={{ minWidth: '30rem', paddingLeft: '1rem' }}>
        <Select
          showSearch
          filterOption={(input, option) =>
            (option?.label ?? '').includes(input)
          }
          filterSort={sortAlphabetically}
          placeholder={placeholder}
          labelInValue
          style={{ width: '100%' }}
          onChange={newValue => {
            setLiaison(newValue)
          }}
          value={liaison}
          options={availableLiaisons}
        />
        <Button type="primary" onClick={submitAddLiaison}>
          Submit
        </Button>
      </Space.Compact>
    </>
  )
}
