import React from 'react'
import { Button, Col, Row } from 'antd'

export const FormButtons: React.FC<{
  onClose: () => void
  saveDisabled: boolean
  saveTestId: string
  onSave: () => void
}> = props => {
  return (
    <Row justify="space-between" style={{ paddingTop: '2.5rem' }}>
      <Col span={10}>
        <Button block onClick={props.onClose}>
          Cancel
        </Button>
      </Col>
      <Col span={10}>
        <Button
          block
          type="primary"
          htmlType="submit"
          data-testid={props.saveTestId}
          disabled={props.saveDisabled}
          onClick={props.onSave}
        >
          Order
        </Button>
      </Col>
    </Row>
  )
}
