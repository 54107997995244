import { FC, KeyboardEventHandler, useState } from 'react'
import './EditableText.css'

export const EditableText: FC<{
  startingValue: string
  onEdit?: (text: string) => void
  testId?: string
}> = props => {
  const [editMode, setEditMode] = useState<boolean>(false)
  const [value, setValue] = useState<string | undefined>()

  const allowEdit = !!props.onEdit
  function updateValue() {
    setEditMode(false)
    if (value && value !== props.startingValue && allowEdit) {
      props.onEdit!(value)
    }
  }

  const onKeyUp: KeyboardEventHandler<any> = e => {
    if (e.key === 'Enter') {
      updateValue()
    } else if (e.key === 'Escape') {
      setValue(undefined)
      setEditMode(false)
    }
  }
  if (editMode && allowEdit) {
    return (
      <input
        type="text"
        data-testid={props.testId}
        className="no-overflow editable-text editable-text-edit"
        defaultValue={props.startingValue}
        autoFocus
        onChange={e => setValue(e.target.value)}
        onBlur={updateValue}
        onKeyUp={onKeyUp}
      />
    )
  }

  return (
    <div
      data-testid={props.testId}
      className={`no-overflow editable-text ${
        allowEdit ? 'editable-text-read' : ''
      }`}
      onClick={() => setEditMode(true)}
    >
      {props.startingValue}
    </div>
  )
}
