import { List } from 'antd'
import { BasicErrorComponent } from '../../../components'
import { PlaylistManagerProps, usePlaylistManager } from './usePlaylistManager'
import { CreatePlaylistItem } from './CreatePlaylistItem'
import { PlaylistItem } from './PlaylistItem'

import './PlaylistManager.css'
import { Playlist } from '@skribe/web/core'

const CREATE_PLAYLIST_BUTTON = 'create'

export function PlaylistManager(props: PlaylistManagerProps) {
  const {
    createPlaylist,
    requestDownload,
    playlists,
    error,
    deletePlaylist,
    update,
    findMatchingClips,
  } = usePlaylistManager(props)

  if (error) return <BasicErrorComponent message="Unable to load Playlists" />

  return (
    <div className="list-container">
      <List
        className="playlist-manager"
        rowKey="id"
        size="large"
        dataSource={[{ id: CREATE_PLAYLIST_BUTTON } as Playlist, ...playlists]}
        renderItem={playlist =>
          playlist.id === CREATE_PLAYLIST_BUTTON ? (
            <CreatePlaylistItem onCreate={createPlaylist} />
          ) : (
            <PlaylistItem
              requestDownload={
                props.canDownloadPlaylist ? requestDownload : undefined
              }
              playlist={playlist}
              delete={deletePlaylist}
              update={update}
              clips={findMatchingClips(playlist.clipIds)}
            />
          )
        }
      />
    </div>
  )
}
