import { GetTranscriptResponse } from '@skribe/web/core'
import { ReactNode } from 'react'
import { PostHumanTranscriptBody } from '@skribe/web/api'
import { Button, Card, Row } from 'antd'
import { PaintedDoor, TrialComponent } from '../../components'
import { trackEvent } from '@skribe/web/tracking'
import { TAB } from '../transcriptStrings'
import { OrderHumanTranscription } from './OrderHumanTranscription'

export function HumanTab(props: {
  useHumanAddon: boolean
  transcript: GetTranscriptResponse
  children: ReactNode
  isTrial: boolean
  orderHumanTranscript: (body?: PostHumanTranscriptBody) => Promise<void>
}) {
  const { useHumanAddon, transcript, children, isTrial, orderHumanTranscript } =
    props
  return useHumanAddon ? (
    <Card className="transcript-dialogue">
      {transcript.human_monologues_url ? (
        children
      ) : (
        <Row justify="center" align="middle">
          {isTrial ? (
            <TrialComponent
              onTooltip={() => {
                trackEvent('wantsHumanProofRead', { isTrial: true })
              }}
              children={<Button disabled>Order {TAB.Human}</Button>}
            />
          ) : (
            <OrderHumanTranscription
              transcript={transcript}
              date={transcript?.human_order_date}
              loading={!transcript?.media_url}
              upgrade={orderHumanTranscript}
            />
          )}
        </Row>
      )}
    </Card>
  ) : (
    <PaintedDoor />
  )
}
