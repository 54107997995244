/**
 * Please note, that IE11 now returns undefined again for window.chrome and new
 * Opera 30 outputs true for window.chrome but needs to check if window.opr is
 * not undefined and new IE Edge outputs to true now for window.chrome and if
 * not iOS Chrome check so use the below updated condition
 *
 * @see https://stackoverflow.com/a/13348618
 */
export function isChrome() {
  // @ts-ignore
  var isChromium = window.chrome
  var winNav = window.navigator
  var vendorName = winNav.vendor
  // @ts-ignore
  var isOpera = typeof window.opr !== 'undefined'
  var isIEedge = winNav.userAgent.indexOf('Edg') > -1
  var isIOSChrome = winNav.userAgent.match('CriOS')

  if (isIOSChrome) {
    // is Google Chrome on IOS
    return true
  } else
    return (
      isChromium !== null &&
      typeof isChromium !== 'undefined' &&
      vendorName === 'Google Inc.' &&
      !isOpera &&
      !isIEedge
    )
}
export const externalNavigate = (url: string) => window.location.assign(url)
export function addCacheBuster(url: string): string {
  return `${url}?v=${Date.now()}`
}

export function promptEmail(to: string, subject: string) {
  window.location.assign(`mailto:${to}?subject=${subject}`)
}

export function downloadPresignedUrl(url: string) {
  const a = document.createElement('a')
  a.href = url
  const clickHandler = () => {
    setTimeout(() => {
      URL.revokeObjectURL(url)
      a.removeEventListener('click', clickHandler)
    }, 150)
  }
  a.addEventListener('click', clickHandler, false)
  a.click()
  return a
}
