import { PlusOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import { ReactElement } from 'react'

export type AddMatterButtonProps = {
  groupId?: string
  onCreateMatter: (groupId?: string) => void
}

export default function AddMatterButton({
  groupId,
  onCreateMatter,
  ...props
}: AddMatterButtonProps): ReactElement {
  return (
    <Button
      {...props}
      onClick={() => onCreateMatter(groupId)}
      block
      icon={<PlusOutlined />}
      style={{ marginBottom: '1.6rem' }}
    >
      Add Matter
    </Button>
  )
}
