import { Card, Layout } from 'antd'
import { useTranscriptPageContext } from '../../context/TranscriptPageContext'
import './ChatPanel.css'
import { AiChat } from './AiChat'
import { useChatPanel } from '../../Layout/UseChatPanel'

export const ChatPanel = () => {
  const shouldUseChatPanel = useChatPanel()
  const { transcriptId, isDrawerOpen } = useTranscriptPageContext()

  if (!shouldUseChatPanel || !transcriptId) return <></>

  return (
    <Layout.Sider
      defaultCollapsed
      collapsed={!isDrawerOpen}
      collapsedWidth={0}
      id="ai-sider"
      width="30%"
    >
      <Card>
        <AiChat transcriptId={transcriptId} />
      </Card>
    </Layout.Sider>
  )
}
