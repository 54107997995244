import { Skeleton } from 'antd'
import { Dialogue } from './Dialogue'
import { ErrorComponent } from '../../components'
import { Clip, Monologue, OnSpeakerChange, Speaker } from '@skribe/web/core'

export const DialogueContainer: React.FC<{
  monologues: Monologue[] | undefined
  monologuesError: Error | undefined
  speakers: Speaker[]
  clips: Clip[] | undefined
  seek: (amount: number) => void
  onSpeakerChange: OnSpeakerChange
  onClip: (
    type: 'manual' | 'drag',
  ) => (start: number, end: number, name: string) => void
  onElementClick: (clipID?: string | undefined) => void
}> = props => {
  if (props.monologuesError) {
    return (
      <ErrorComponent
        showButton={false}
        status="warning"
        error={{
          message: 'Unable to load Dialogue',
          name: '',
        }}
      />
    )
  }

  if (!props.monologues) {
    return (
      <Skeleton
        paragraph={{ rows: 10 }}
        active
        data-testid="dialogue-skeleton"
      />
    )
  }
  return (
    <Dialogue
      monologues={props.monologues}
      clips={props.clips}
      speakers={props.speakers}
      seek={props.seek}
      clip={props.onClip('drag')}
      onSpeakerChange={props.onSpeakerChange}
      onElementClick={props.onElementClick}
    />
  )
}
