import { useState } from 'react'
import { ROLE } from '@skribe/web/core'

export type AuthContextType = {
  setIsLoading: (loading: boolean) => void
  isLoading: boolean
  setRole: (role: ROLE | undefined) => void
  role: ROLE | undefined
}

export const defaultAuthContext: AuthContextType = {
  setIsLoading: () => {},
  setRole: () => {},
  isLoading: false,
  role: undefined,
}

export function useAuthProvider(): AuthContextType {
  const [isLoading, setIsLoading] = useState(false)
  const [role, setRole] = useState<ROLE | undefined>(undefined)

  return {
    isLoading,
    setIsLoading,
    setRole,
    role,
  }
}
