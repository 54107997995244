import { secureAPI } from './client'
import { DetailedEvent, Event, Nullable } from '@skribe/web/core'

export async function getEvent(id: string) {
  return (await secureAPI.get('rev', `/events/${id}`, {})) as DetailedEvent
}

export async function fetchEvents(matterID: string) {
  if (!matterID) return []

  return (await secureAPI.get(
    'rev',
    `/events?matterId=${matterID}`,
    {},
  )) as Event[]
}

export const createEvent = async (
  matter: {
    id: string
    groups?: Nullable<Nullable<string>[]>
  },
  name: string,
) => {
  return await secureAPI.post('rev', '/events', {
    body: {
      name,
      matterId: matter.id,
    },
  })
}
export const removeFiles = async (fileKeys: string[]) => {
  return await secureAPI.post('rev', '/events/clean-uploaded-files', {
    body: {
      fileKeys,
    },
  })
}

export async function updateName(id: string, name: string) {
  return await secureAPI.patch('rev', `/events/${id}`, {
    body: { name },
  })
}
export async function addLiaison(id: string, liaisonEmail: string) {
  return await secureAPI.patch('rev', `/events/${id}`, {
    body: { liaisonEmail },
  })
}
export async function deleteEvent(id: string) {
  return await secureAPI.del('rev', `/events/${id}`, {})
}

export async function createLiveMeeting(
  eventId: string,
  startDate: string,
  durationInMinutes: number,
) {
  return (await secureAPI.post('rev', `/events/${eventId}/meeting`, {
    body: { startDate, durationInMinutes },
  })) as Promise<{
    userId: string
    meetingNumber: number
    password: string
    joinUrl: string
    startUrl: string
  }>
}

export async function rescheduleLiveMeeting(
  eventId: string,
  startDate: string,
  durationInMinutes: number,
) {
  return await secureAPI.patch('rev', `/events/${eventId}/meeting`, {
    body: { startDate, durationInMinutes },
  })
}
