import { Button, Col, Input, Row, Typography } from 'antd'
import { DownOutlined, UpOutlined } from '@ant-design/icons'

export function SearchBar(props: {
  value: string
  onSearch: (value: string) => void
  setValue: (value: ((prevState: string) => string) | string) => void
  hasUserSearched: boolean
  searchPrevious: () => void
  searchNext: () => void
  searchItemCount: number
  resultCount: number | undefined
}) {
  const {
    value,
    onSearch,
    setValue,
    hasUserSearched,
    searchPrevious,
    searchNext,
    searchItemCount,
    resultCount,
  } = props
  return (
    <Row data-testid="searchRow" align="middle" gutter={8}>
      <Col data-testid="searchControl" flex="1 0 auto">
        <Input.Search
          value={value}
          placeholder="Search in Transcript"
          onSearch={() => onSearch(value)}
          onChange={e => setValue(e.target.value)}
        />
      </Col>
      {hasUserSearched && (
        <>
          <Col>
            <Button
              data-testid="searchPrevious"
              onClick={searchPrevious}
              icon={<UpOutlined />}
            />
          </Col>
          <Col>
            <Button
              data-testid="searchNext"
              onClick={searchNext}
              icon={<DownOutlined />}
            />
          </Col>
          <Col>
            <Typography.Text
              data-testid="searchResultCount"
              style={{ textAlign: 'center', display: 'block' }}
            >
              {searchItemCount} of {resultCount}
            </Typography.Text>
          </Col>
        </>
      )}
    </Row>
  )
}
