import { type FC, type ReactNode } from 'react'
import { ConfigProvider } from 'antd'
import { ThemeConfig } from 'antd/es/config-provider'
import { colors } from '../core/Colors'

/**
 * Customize the appearance of UI components from ant design. Wrap the whole application in this component.
 *
 * @see https://ant.design/docs/react/customize-theme
 */
setColors()

const AntTheme: FC<{ children: ReactNode }> = ({ children }) => {
  const theme: ThemeConfig = {
    token: {
      colorPrimary: colors.primary,
      fontFamily: 'Poppins',
      fontSize: 16,
      colorBgTextActive: colors.activeBackground,
      colorInfo: colors.hyperlink,
      colorInfoBg: colors.infoBackground,
      colorInfoText: colors.hyperlink,
      colorLinkHover: colors.primary,
    },
    components: {
      Layout: {
        headerBg: 'white',
        colorBgContainer: colors.grey,
        bodyBg: colors.grey,
      },
      Typography: {
        titleMarginBottom: 0,
      },
    },
  }
  return <ConfigProvider theme={theme}>{children}</ConfigProvider>
}

function setColors() {
  document.documentElement.style.setProperty('--primary-color', colors.primary)
  document.documentElement.style.setProperty('--link-color', colors.hyperlink)
  document.documentElement.style.setProperty(
    '--disabled-bg-color',
    colors.disabled,
  )
  document.documentElement.style.setProperty('--gray-list-item', colors.grey)
  document.documentElement.style.setProperty('--delete-text', colors.delete)
  document.documentElement.style.setProperty('--error-color', colors.error)
}

export default AntTheme
