import {
  CustomVocabulary,
  Element as _Element,
  Monologue,
} from 'revai-node-sdk'
import * as timeUtilities from '../app/utilities/timeUtilities'
import { Speaker } from './speakers'
import { backfillTimestamps } from './transformers/monologue.transformer'
import last from 'lodash/last'

/**
 * Version of MonologueElement with `ts` and `end_ts` required
 */
export type MonologueElement = Required<Pick<_Element, 'end_ts' | 'ts'>> &
  _Element

type RawMonologueElement = _Element

export type { Monologue, RawMonologueElement, CustomVocabulary }

export type Transcriber = 'human' | 'machine'

export type SkribeMonologue = {
  speaker: number
  speakerName: string
  elements: MonologueElement[]
  timestamp: string
  start: number
  end: number
}

export type MonologueTriad = {
  previousMonologue: undefined | SkribeMonologue
  currentMonologue: SkribeMonologue
  nextMonologue: undefined | SkribeMonologue
}

export function buildSkribeMonologues(
  index: number,
  dialogueMonologues: SkribeMonologue[],
) {
  function getPreviousMonologue() {
    if (index === 0) return undefined
    return dialogueMonologues[index - 1]
  }

  function getNextMonologue() {
    if (index + 1 === dialogueMonologues.length) return undefined
    return dialogueMonologues[index + 1]
  }

  const previousMonologue = getPreviousMonologue()
  const nextMonologue = getNextMonologue()
  const skribeMonologues: MonologueTriad = {
    previousMonologue,
    currentMonologue: dialogueMonologues[index],
    nextMonologue,
  }
  return skribeMonologues
}

export type OnSpeakerChange = (
  speaker: number,
  value: string,
  type: 'onTranscript' | 'onSelector',
) => Promise<void>
export function createSkribeMonologues(
  monologues: Monologue[],
  speakers: Speaker[],
): SkribeMonologue[] {
  return monologues.map(monologue => createSkribeMonologue(monologue, speakers))
}

function createSkribeMonologue(monologue: Monologue, speakers: Speaker[]) {
  const sanitizedElements = backfillTimestamps(monologue.elements ?? [])
  const start = sanitizedElements[0]?.ts ?? 0
  const end = last(sanitizedElements)?.end_ts ?? 0
  const skribeMonologue: SkribeMonologue = {
    speaker: monologue.speaker,
    speakerName:
      speakers.find(s => s.index === monologue.speaker)?.name ??
      'Unknown Speaker',
    timestamp: timeUtilities.timestamp(start),
    start,
    end,
    elements: sanitizedElements,
  }
  return skribeMonologue
}

export function extractTextElements(monologues: Monologue[] | undefined) {
  return (
    monologues?.flatMap(e => e?.elements).filter(v => v?.type === 'text') ?? []
  )
}
