import { Message } from '@skribe/web/api'
import { ChatAnswer } from './ChatAnswer'
import { ChatQuestion } from './ChatQuestion'

export function ChatHistory(props: { history: Message[] }) {
  return (
    <>
      {props.history.map((message, index) => {
        if (message.role === 'user') {
          return <ChatQuestion key={index} question={message.content} />
        } else {
          return (
            <ChatAnswer
              question={props.history[index - 1]?.content}
              key={index}
              response={message.content}
              error={''}
            />
          )
        }
      })}
    </>
  )
}
