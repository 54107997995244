import { createContext, FC, ReactNode, useState } from 'react'
import { MatterWithEvents } from '@skribe/web/core'

export const MatterContext = createContext<{
  selectedMatter: MatterWithEvents | null
  selectMatter: (matter: MatterWithEvents | null) => void
}>({ selectedMatter: null, selectMatter: () => {} })

export const MatterProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [selectedMatter, setSelectedMatter] = useState<MatterWithEvents | null>(
    null,
  )

  return (
    <MatterContext.Provider
      value={{
        selectedMatter,
        selectMatter: (m: MatterWithEvents | null) => {
          setSelectedMatter(m)
        },
      }}
    >
      {children}
    </MatterContext.Provider>
  )
}
