import { Col, Row } from 'antd'
import { LoadingComponent } from '../components'
import { useDocumentTitle } from '../utilities'
import { usePlaylistPage } from './usePlaylistPage'

export function PlaylistPage() {
  const { playlist } = usePlaylistPage()
  useDocumentTitle({ name: playlist?.name, type: 'Playlist' })

  if (!playlist) return <LoadingComponent />

  return (
    <Row className="playlist-page" style={{ backgroundColor: 'red', flex: 1 }}>
      <Col span={6} style={{ backgroundColor: 'pink' }}>
        Left Column
      </Col>
      <Col offset={1} flex="auto" style={{ backgroundColor: 'orange' }}>
        Center Column
      </Col>
    </Row>
  )
}
