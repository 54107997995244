import { notification } from 'antd'

export function useOnCopyNotification(message: string = 'Link') {
  const [notificationApi, notificationContext] = notification.useNotification()

  const onCopy = () => {
    notificationApi.open({
      message: 'Copied',
      description: `${message} has been copied to clipboard!`,
      placement: 'top',
    })
  }

  return { onCopy, notificationContext }
}
