import { trackEvent } from '@skribe/web/tracking'
import { Matter } from '@skribe/web/core'
import { matterDb, eventDb } from '@skribe/web/api'
import { useInvalidateData } from '../state'

export function useSelectedMatter(selected: Matter) {
  const { refreshMatters } = useInvalidateData()
  const onMatterNameChange = async (id: string | undefined, name: any) => {
    if (!id || !name) return
    trackEvent('renameMatter', { id })
    await matterDb.updateMatterName(id, name)

    await refreshMatters()
  }

  const onDeleteEvent = async (eventId: string | undefined) => {
    if (!eventId) return console.error('need an eventId')

    trackEvent('delete_event', { parent: selected, id: eventId })
    await eventDb.deleteEvent(eventId)
    await refreshMatters()
  }

  return {
    onMatterNameChange,
    onDeleteEvent,
  }
}
