import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Drawer, Tabs } from 'antd'
import {
  ScheduleLiveMeetingForm,
  TrialComponent,
  defaultDrawerProps,
  defaultTabsProps,
} from '../components'
import { Matter } from '@skribe/web/core'
import { useOrderEventDrawer } from './useOrderEventDrawer'
import { LibraryForm } from '../components/library/LibraryForm'

export type OrderEventDrawerProps = {
  matter: Matter
}

export const OrderEventDrawer: React.FC<OrderEventDrawerProps> = ({
  matter,
}) => {
  const navigate = useNavigate()
  const { isTrial, createLibraryEvent, onSubmit, onTrialLiveEvent } =
    useOrderEventDrawer({
      matter,
    })

  enum TAB {
    LIVE = 'Live',
    LIBRARY = 'Library',
  }
  const [tab, setTab] = useState<undefined | TAB>()
  const openLive = () => setTab(TAB.LIVE)
  const openLibrary = () => setTab(TAB.LIBRARY)
  const onClose = () => setTab(undefined)

  return (
    <>
      {isTrial ? (
        <TrialComponent
          onTooltip={onTrialLiveEvent}
          children={<Button disabled>Schedule Live Event</Button>}
        />
      ) : (
        <Button type="primary" onClick={openLive} data-testid="openLiveDrawer">
          Schedule Live Event
        </Button>
      )}
      <Button
        onClick={openLibrary}
        data-testid="openLibraryDrawer"
        style={{ marginLeft: '1rem' }}
      >
        Upload to Library
      </Button>
      <Drawer
        {...defaultDrawerProps}
        onClose={onClose}
        open={!!tab}
        className="tab-drawer two-tab-drawer"
      >
        <Tabs
          {...defaultTabsProps}
          defaultActiveKey={tab}
          items={[
            {
              key: TAB.LIVE,
              label: isTrial ? (
                <TrialComponent
                  onTooltip={onTrialLiveEvent}
                  children={TAB.LIVE}
                />
              ) : (
                TAB.LIVE
              ),
              disabled: isTrial,
              children: (
                <ScheduleLiveMeetingForm
                  onSubmit={onSubmit}
                  onClose={onClose}
                  defaultEventName={matter.name}
                  errorMessage="Something wrong happened when creating your event"
                />
              ),
            },
            {
              key: TAB.LIBRARY,
              label: TAB.LIBRARY,
              children: (
                <LibraryForm
                  defaultMatterName={matter.name}
                  getEventId={createLibraryEvent}
                  onClose={onClose}
                  onSuccessfulSave={eventId => navigate(`/events/${eventId}`)}
                  metadata={{ matterId: matter.id }}
                />
              ),
            },
          ]}
        />
      </Drawer>
    </>
  )
}
