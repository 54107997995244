import { useEffect } from 'react'
import { truncateText } from '@skribe/web/core'

export interface TabTitle {
  name?: string | null
  type?: string | null
}

export const useDocumentTitle = (props?: TabTitle) => {
  useEffect(() => {
    let title = 'Skribe.ai'

    if (props?.name) {
      title = truncateText(props.name, 20)

      if (props?.type) {
        title = `${title} • ${props.type}`
      }
    }
    window.document.title = title
  }, [props])
}
