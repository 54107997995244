import { DetailedUser } from '@skribe/web/core'
import ReactGA from 'react-ga4'

export function initialize() {
  ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID || '')
}

export const trackPageView = () => {
  trackEvent('page_view', {
    page_location: window.location.href,
    page_title: window.document.title,
  })
}

export const trackEvent = (eventName: string, properties = {}) => {
  ReactGA.event(eventName, properties)
}

export function setTrackedUser(user?: DetailedUser) {
  if (!user) return

  ReactGA.set({ userId: user.id, ...user })
}
