import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import * as APITypes from '@skribe/web/core'
import { useUserContext } from '../User/UserProvider'
import { DetailedEvent } from '@skribe/web/core'
import { useDocumentTitle } from '../utilities'
import { useQuery } from '@tanstack/react-query'
import { eventDb } from '@skribe/web/api'
import { useIsTrial } from '../User/useIsTrial'

export function useEventContainer() {
  const { id } = useParams()

  const { refreshUser } = useUserContext()
  const isTrial = useIsTrial()

  const [shouldRefreshUser, setShouldRefreshUser] = useState(false)
  const [transcriptsInProgress, setTranscriptsInProgress] = useState(false)

  const {
    data: event,
    error,
    isFetching,
  } = useQuery({
    queryKey: ['event', id],
    queryFn: async (): Promise<DetailedEvent | undefined> => {
      if (!id) throw new Error('Not Found')
      const retrievedEvent = await eventDb.getEvent(id)
      const inProgress = anyInProgress(retrievedEvent.Transcripts)
      setTranscriptsInProgress(inProgress)
      if (isTrial && inProgress) setShouldRefreshUser(true)
      return retrievedEvent
    },
    refetchInterval: transcriptsInProgress && 5000,
  })

  useDocumentTitle({
    name: event?.name,
    type: event?.start ? 'Live Event' : 'Library Event',
  })

  useEffect(() => {
    if (shouldRefreshUser && event && noneInProgress(event.Transcripts)) {
      refreshUser()
      setShouldRefreshUser(false)
    }
  }, [refreshUser, shouldRefreshUser, event])

  if (error) throw error
  return {
    event,
    isFetching,
  }
}

function noneInProgress(transcripts: APITypes.Transcript[]): boolean {
  return transcripts.every(t => t.status !== 'in_progress')
}

function anyInProgress(transcripts: APITypes.Transcript[]): boolean {
  return transcripts.some(t => t.status === 'in_progress')
}
