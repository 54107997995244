import { API as _API } from 'aws-amplify'
import * as frontegg from '../../auth/frontegg'

export async function injectAuth(extraParams: Record<string, any>) {
  const headers = extraParams?.headers ?? {}

  const authorization = await frontegg.getJwtToken()
  headers.Authorization = `Bearer ${authorization}`
  return { ...extraParams, headers }
}
export const publicAPI = _API
export const secureAPI: Pick<
  typeof _API,
  'post' | 'get' | 'del' | 'patch' | 'put'
> = {
  post: async (apiName, path, extraParams) =>
    _API.post(apiName, path, await injectAuth(extraParams)),
  get: async (apiName, path, extraParams) =>
    _API.get(apiName, path, await injectAuth(extraParams)),
  del: async (apiName, path, extraParams) =>
    _API.del(apiName, path, await injectAuth(extraParams)),
  patch: async (apiName, path, extraParams) =>
    _API.patch(apiName, path, await injectAuth(extraParams)),
  put: async (apiName, path, extraParams) =>
    _API.put(apiName, path, await injectAuth(extraParams)),
}
