import { DetailedEvent, User } from '@skribe/web/core'
import { notification } from 'antd'
import { useEffect, useState } from 'react'
import { eventDb, liaisonDb } from '@skribe/web/api'
import { SelectOption } from '../components/antd/types'

export function useAddLiaisonField(event: DetailedEvent) {
  const [sendNotification, notificationComponent] =
    notification.useNotification()
  const [availableLiaisons, setAvailableLiaisons] = useState<SelectOption[]>([])
  const [liaison, setLiaison] = useState<SelectOption | undefined>(undefined)
  const placeholder = event.liaisonEmail ?? 'Liaison email'

  useEffect(() => {
    liaisonDb
      .fetchLiaisons()
      .then(liaisons => setAvailableLiaisons(convertToSelectOptions(liaisons)))
  }, [])

  async function submitAddLiaison() {
    if (liaison) {
      try {
        await eventDb.addLiaison(event.id, liaison.value)
        sendNotification.success({
          message: `Liaison added: ${liaison.value}`,
        })
      } catch (e: any) {
        console.error(e)
        sendNotification.error({
          message: e.response?.data ?? 'Error submitting liaison',
        })
      }
    }
  }

  return {
    notificationComponent,
    availableLiaisons,
    liaison,
    setLiaison,
    submitAddLiaison,
    placeholder,
  }
}

export function convertToSelectOptions(liaisons: User[]) {
  return liaisons.map(liaison => ({
    label: liaison.email,
    value: liaison.email,
  }))
}

export function sortAlphabetically(
  optionA: SelectOption,
  optionB: SelectOption,
) {
  const firstEmail = (optionA?.label ?? '').toLowerCase()
  const secondEmail = (optionB?.label ?? '').toLowerCase()
  return firstEmail.localeCompare(secondEmail)
}
