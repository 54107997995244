import { useCallback, useEffect, useState } from 'react'
import { Monologue, extractTextElements } from '@skribe/web/core'
import {
  addSearchHighlight,
  scrollAndHighlightCurrent,
  removeCurrentHighlight,
} from '@skribe/web'
import { useFuzzySearch } from '@skribe/web/search'
import { trackEvent } from '@skribe/web/tracking'

export const useSearch = (monologues?: Monologue[]) => {
  const [text, setText] = useState<string>()
  const [index, setIndex] = useState<number | undefined>(undefined)
  const [startTimes, setStartTimes] = useState<number[] | undefined>(undefined)

  const textElements = extractTextElements(monologues)
  const { fuse } = useFuzzySearch(textElements, {
    keys: ['value'],
    threshold: 0.1,
  })

  const resetState = useCallback(_resetState, [])
  useEffect(() => {
    resetState()
  }, [monologues, resetState])

  useEffect(() => {
    if (!startTimes || index === undefined) return

    scrollAndHighlightCurrent(startTimes[index])
  }, [index, startTimes])

  return {
    hasUserSearched: !!startTimes,
    searchItemCount: index === undefined ? 0 : index + 1,
    resultCount: startTimes?.length,
    onSearch,
    searchPrevious,
    searchNext,
  }

  function onSearch(rawValue: string) {
    const value = rawValue.trim()
    const isEmpty = !value.trim()
    const isNewText = value !== text

    if (isEmpty) {
      _resetState()
    } else if (isNewText) {
      prepareSearchAndGoToFirst(value)
    } else {
      searchNext()
    }
  }

  function prepareSearchAndGoToFirst(value: string) {
    if (!monologues) return

    const times = fuse
      .search(value)
      .map((i: any) => i.item?.ts)
      .filter(Boolean) as number[]

    addSearchHighlight(times)
    setState({ value, times, index: times.length === 0 ? undefined : 0 })

    trackEvent('searchOnTranscript', { searchValue: value })
  }

  function searchPrevious() {
    if (!startTimes || index === undefined) return
    if (emptySearchResults()) return

    const lastItemIndex = startTimes.length - 1
    const previousItemIndex = index - 1
    setIndex(index === 0 ? lastItemIndex : previousItemIndex)
  }

  function searchNext() {
    if (!startTimes || index === undefined) return
    if (emptySearchResults()) return

    setIndex((index + 1) % startTimes.length)
  }

  function emptySearchResults() {
    return startTimes?.length === 0
  }

  function setState(props: {
    value: string
    times?: number[]
    index?: number
  }) {
    setText(props.value)
    setStartTimes(props.times)
    setIndex(props.index)
  }

  function _resetState() {
    removeCurrentHighlight()
    addSearchHighlight([])
    setState({ value: '' })
  }
}
