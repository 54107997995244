import { Col, Input, Row } from 'antd'
import { ChatButton } from './ChatButton'
import './PromptBox.css'
import { QuickQuestionButtons } from './QuickQuestionButtons'

export function PromptBox(props: {
  question: string
  onChange: (text: string) => void
  askQuestion: (directQuestion?: string) => void
  isLoading: boolean
  noQuestion: boolean
}) {
  function handleKeyPress(event: React.KeyboardEvent<HTMLTextAreaElement>) {
    if (event.key === 'Enter') {
      if (event.altKey) {
        createNewLineWithoutAskingQuestion(event)
      } else {
        event.preventDefault()
        props.askQuestion()
      }
    }
  }

  function askAi(question: string) {
    return () => {
      props.askQuestion(question)
    }
  }

  return (
    <div className="prompt-box">
      <Input.TextArea
        onKeyDown={handleKeyPress}
        className="input"
        placeholder="Ask a question or search for info on the media..."
        value={props.question}
        onChange={e => props.onChange(e.target.value)}
        disabled={props.isLoading}
        autoSize={{ minRows: 1, maxRows: 6 }}
      />
      <Row justify={'space-between'}>
        <Col>
          <QuickQuestionButtons askAi={askAi} isLoading={props.isLoading} />
        </Col>
        <Col>
          <ChatButton
            onClick={() => props.askQuestion()}
            disabled={props.noQuestion}
          />
        </Col>
      </Row>
    </div>
  )
}

function createNewLineWithoutAskingQuestion(
  event: React.KeyboardEvent<HTMLTextAreaElement>,
) {
  const textArea = event.currentTarget
  const currentText = textArea.value
  const selectionStart = textArea.selectionStart
  const textBeforeCursor = currentText.slice(0, selectionStart)
  const textAfterCursor = currentText.slice(textArea.selectionEnd)
  textArea.value = `${textBeforeCursor}\n${textAfterCursor}`
  textArea.selectionStart = textArea.selectionEnd = selectionStart + 1
}
