import { useState } from 'react'

export function useSelectAiClip() {
  const [selectedClip, setSelectedClip] = useState<number | undefined>(
    undefined,
  )

  function isClipSelected(index: number) {
    return selectedClip === index
  }

  function selectClip(index: number) {
    return () => setSelectedClip(index)
  }

  return { isClipSelected, selectClip }
}
