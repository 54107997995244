import { FC } from 'react'
import { isRouteErrorResponse, useRouteError } from 'react-router-dom'
import { ErrorComponent } from '../components'
import { useDocumentTitle } from '../utilities'

export const ErrorPage: FC = () => {
  useDocumentTitle({ name: 'Error' })
  const error = useRouteError()

  if (isRouteErrorResponse(error)) {
    return (
      <ErrorComponent error={{ name: error.statusText, message: error.data }} />
    )
  }
  return <ErrorComponent error={error instanceof Error ? error : null} />
}
