import { FC } from 'react'
import { Avatar, Button, Menu, MenuProps, Space } from 'antd'
import { Link } from 'react-router-dom'
import './Navigation.css'
import { useVendorUser } from '../Auth/useVendorUser'
import { MenuItems } from '../components/antd/types'

interface NavigationProps {}

export const Navigation: FC<NavigationProps> = () => {
  const { signOut, isAuthenticated, email, loggedInOnFrontegg } =
    useVendorUser()

  const authenticatedItems: MenuItems = [
    {
      label: (
        <Avatar data-testid="userAvatar" size="large">
          {email?.charAt(0).toUpperCase()}
        </Avatar>
      ),
      style: { paddingRight: 0 },
      key: 'UserMenu',
      children: [
        {
          key: 'User',
          type: 'group',
          label: email,
        },
        { type: 'divider' },

        {
          label: (
            <Link to="/matters" className="no-underline">
              Matters
            </Link>
          ),
          key: 'matters',
        },
        {
          label: (
            <Link to="/pricing" className="no-underline">
              Pricing
            </Link>
          ),
          key: 'pricing',
        },
        {
          label: (
            <Link to="https://skribe.ai/general-faq" className="no-underline">
              Frequently Asked Questions
            </Link>
          ),
          key: 'faq',
        },
        loggedInOnFrontegg && {
          label: (
            <Link to="/settings" className="no-underline">
              Settings
            </Link>
          ),
          key: 'settings',
        },
        { type: 'divider' },
        {
          label: 'Log out',
          key: 'logout',
        },
      ].filter(Boolean) as MenuItems,
    },
  ]

  const onMenuClick: MenuProps['onClick'] = event => {
    switch (event.key) {
      case 'logout':
        signOut()
        break
    }
  }

  if (!isAuthenticated) {
    return (
      <Space size="large" className="user-actions-menu">
        <Link className="login" to={'/account/login'}>
          Login
        </Link>
        <Link className="create-account" to="/account/sign-up">
          <Button type="primary">Try Skribe Free</Button>
        </Link>
      </Space>
    )
  }
  return (
    <Menu
      mode="horizontal"
      items={authenticatedItems}
      onClick={onMenuClick}
      selectable={false}
      className="user-menu"
    />
  )
}
export default Navigation
