import { ReactNode } from 'react'
import { SubMenuType } from 'antd/lib/menu/hooks/useItems'
import { Typography } from 'antd'

export function createAddToCategoryDropdownItem(props: {
  categories: { id: string; name: string }[]
  onSelect: (groupId: string) => Promise<void>
  text: string
  icon: ReactNode
}): SubMenuType | null {
  return {
    key: 'share',
    icon: props.icon,
    label: (
      <Typography.Text data-testid="shareSubMenu">{props.text}</Typography.Text>
    ),
    children: props.categories.map(category => ({
      key: category.id,
      onClick: () => props.onSelect(category.id),
      label: (
        <Typography.Text data-testid={`shareTo${category.id}`}>
          {category.name}
        </Typography.Text>
      ),
    })),
  }
}
