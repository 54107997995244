import { secureAPI } from './client'
import { LiaisonEvent, User } from '@skribe/web/core'

export async function fetchEventsForLiaison() {
  return (await secureAPI.get('rev', `/liaison/events`, {})) as LiaisonEvent[]
}

export async function fetchLiaisons() {
  return (await secureAPI.get('rev', '/liaison/users', {})) as User[]
}
