import {
  ERROR_INVALID_MONOLOGUE,
  MonologueElement,
  RawMonologueElement,
} from '@skribe/web/core'
import {
  convertStartToPositiveNumber,
  convertToPositiveNumber,
} from '../../app/utilities/timeUtilities'

export function backfillTimestamps(
  elements: RawMonologueElement[],
): MonologueElement[] {
  return elements.map((element, index, array) => {
    const newElement = { ...element }
    if (element.type === 'punct') {
      const startTime = getEndFromPreviousElement(array, index)
      newElement.ts = roundStartToHundredths(startTime)
      newElement.end_ts = roundEndToHundredths(startTime)
    } else {
      newElement.ts = roundStartToHundredths(element.ts)
      newElement.end_ts = roundEndToHundredths(element.end_ts)
    }
    return newElement as MonologueElement
  })
}

function roundToHundredths(validatedTimeStamp: number) {
  return Math.round((validatedTimeStamp + Number.EPSILON) * 100) / 100
}

export function roundStartToHundredths(timeStamp: number | undefined) {
  const validatedTimeStamp = convertStartToPositiveNumber(timeStamp)
  return roundToHundredths(validatedTimeStamp)
}

export function roundEndToHundredths(timeStamp: number | undefined) {
  const validatedTimeStamp = convertToPositiveNumber(timeStamp)
  return roundToHundredths(validatedTimeStamp)
}

export function getEndFromPreviousElement(
  elements: RawMonologueElement[],
  index: number,
): number {
  const previousIndex = Math.max(index - 1, 0)
  const previousElement = elements[previousIndex]
  const isPreviousFirst = previousIndex === 0

  return (
    previousElement.end_ts ??
    (isPreviousFirst ? firstStartTime() : lookBackward())
  )

  function lookBackward() {
    return getEndFromPreviousElement(elements, previousIndex)
  }

  function firstStartTime() {
    const result = elements.find(e => !!e.ts)?.ts
    if (!result) throw Error(ERROR_INVALID_MONOLOGUE)
    return result
  }
}
