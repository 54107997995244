import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Drawer, Tabs } from 'antd'
import { Event } from '@skribe/web/core'
import { LibraryForm } from '../components/library/LibraryForm'
import { defaultDrawerProps, defaultTabsProps } from '../components'

export type LibraryDrawerProps = {
  event: Event
}

export const EventLibraryDrawer: React.FC<LibraryDrawerProps> = ({ event }) => {
  enum TAB {
    LIBRARY = 'Library',
  }

  const navigate = useNavigate()

  const [tab, setTab] = useState<undefined | TAB>()
  const openDrawer = () => setTab(TAB.LIBRARY)
  const closeDrawer = () => setTab(undefined)

  return (
    <>
      {!event.start && (
        <Button onClick={openDrawer} data-testid="openLibraryDrawer">
          Upload to Library
        </Button>
      )}
      <Drawer
        {...defaultDrawerProps}
        onClose={closeDrawer}
        open={!!tab}
        className="tab-drawer"
      >
        <Tabs
          {...defaultTabsProps}
          defaultActiveKey={tab}
          items={[
            {
              key: TAB.LIBRARY,
              label: TAB.LIBRARY,
              children: (
                <LibraryForm
                  getEventId={() => Promise.resolve(event.id)}
                  onClose={closeDrawer}
                  onSuccessfulSave={() => navigate(0)}
                  metadata={{
                    matterId: event.matterID,
                    parentEventName: event.name ?? '',
                    eventId: event.id,
                  }}
                />
              ),
            },
          ]}
        />
      </Drawer>
    </>
  )
}
