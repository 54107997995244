import { FC } from 'react'
import { Row, Tabs, Typography } from 'antd'
import AddMatterButton from './AddMatterButton'
import { MatterList } from './MatterList'
import { useMatterSelector } from './useMatterSelector'
import { trackEvent } from '@skribe/web/tracking'
import { useDocumentTitle } from '../utilities'
import './MatterPage.css'
import { displayGroupTabs } from './DisplayGroupTabs'
import { MatterWithEvents } from 'src/core/types'

export const MatterSelector: FC<{ matters: MatterWithEvents[] }> = ({
  matters,
}) => {
  const viewModel = useMatterSelector()

  useDocumentTitle(viewModel.tabTitle)

  const privateMatters = (
    <>
      <AddMatterButton
        data-testid="addPrivateMatter"
        onCreateMatter={viewModel.onCreateMatter}
      />
      <MatterList
        data-testid="PrivateMatters"
        matters={matters?.filter(matter => !matter.groups?.length)}
        selected={viewModel.selectedMatter}
        onDelete={viewModel.onDeleteMatter}
      />
    </>
  )

  return (
    <>
      <Row justify="space-between" data-testid="matterSelector">
        <Typography.Title className="subtitle-1" level={2}>
          Matters
        </Typography.Title>
      </Row>
      {!viewModel.groups.length ? (
        privateMatters
      ) : (
        <Tabs
          data-testid="matterTabs"
          defaultActiveKey={viewModel.defaultTab}
          activeKey={viewModel.selectedGroup ?? undefined}
          onChange={groupId => {
            viewModel.navigate({
              pathname: '/matters',
              search: `?group=${encodeURIComponent(groupId)}`,
            })
            trackEvent('selectMatter', { name: groupId })
          }}
          items={displayGroupTabs(matters, viewModel, privateMatters)}
        />
      )}
    </>
  )
}
