import { Clip } from '@skribe/web/core'
import { TAB } from '../transcriptStrings'
import { useEffect, useState } from 'react'
import { useFeature } from 'flagged'
import { addSearchHighlight, removeCurrentHighlight } from '@skribe/web'
import { ReloadOutlined } from '@ant-design/icons'
import { Typography } from 'antd'
import { clipDb } from '@skribe/web/api'

export function useTranscriptControls({
  clips,
  tab,
}: {
  clips: Clip[]
  tab: TAB
}) {
  const [value, setValue] = useState<string>('')
  const useImproveQualityOfClips = useFeature('improveQualityOfClips')
  const regenerateClips = () =>
    clipDb.generateClips(clips?.map(v => v.id) ?? [])

  useEffect(() => {
    setValue('')
  }, [tab])
  useEffect(() => {
    if (value === '') {
      removeCurrentHighlight()
      addSearchHighlight([])
    }
  }, [value])
  let improveQualityOfClips = null
  if (useImproveQualityOfClips) {
    improveQualityOfClips = {
      key: 'regenerate',
      icon: <ReloadOutlined />,
      label: (
        <Typography.Text data-testid="regenerateButton">
          Improve Quality of Clips
        </Typography.Text>
      ),
      onClick: regenerateClips,
    }
  }
  return { value, setValue, improveQualityOfClips }
}
