import Icon, { IconComponentProps } from '@ant-design/icons/lib/components/Icon'
import { Col, Row, Typography } from 'antd'

import { ReactComponent as AiPrep } from '../icons/ai-prep.svg'
import { ReactComponent as AiSummary } from '../icons/ai-summary.svg'
import { ReactComponent as AiTarget } from '../icons/ai-target.svg'
import './ChatPlaceholder.css'
import { AiDisclaimer } from './AiDisclaimer'

const { Text, Title } = Typography

export const ChatPlaceholder = () => (
  <Col style={{ padding: '1rem' }}>
    <Row>
      <Title level={5} style={{ fontWeight: 'bold', paddingBottom: '1.2rem' }}>
        Explore the 'Chat with Transcript' (Beta) <AiDisclaimer />
      </Title>
    </Row>

    <Col>
      <Row>
        <Text className="chat-placeholder-text">
          Get started by typing your questions or needs in the box below.
        </Text>
      </Row>
      <Row>
        <Text className="chat-placeholder-title">Type Out What You Need: </Text>
      </Row>
      <Row>
        <Text className="chat-placeholder-text">
          Ask in normal language, just like you were sending a message to a
          member of your team.
        </Text>
      </Row>
    </Col>
    <Row>
      <Text className="chat-placeholder-title">What Can You Do?</Text>
    </Row>
    <Row>
      <AiPerkItem
        icon={AiTarget}
        title="Quickly Locate Key Points:"
        perks={[
          'A smarter search.',
          `Try "Find all mentions of 'evidence' in John Doe's deposition."`,
        ]}
      />
      <AiPerkItem
        icon={AiPrep}
        title="Create Summaries:"
        perks={[
          'No more getting lost in pages!',
          `Try, "Summarize the key points of this deposition."`,
        ]}
      />
      <AiPerkItem
        icon={AiSummary}
        title="Prep for Review: "
        perks={[
          'Want to see what else is possible?',
          `Try "What follow-up questions for this case should I ask”`,
        ]}
      />
    </Row>
    <Row>
      <Text className="chat-placeholder-text" style={{ paddingBottom: '1rem' }}>
        Remember, our chat tool is here to assist you by analyzing the media
        transcripts and providing sourced answers with clickable links.
      </Text>
      <Text className="chat-placeholder-text">
        Any suggestions or questions please let us know{' '}
        <a href="mailto:contact@skribe.ai?subject=Tell me more about Skribe">
          product@skribe.ai
        </a>
      </Text>
    </Row>
  </Col>
)

const AiPerkItem: React.FC<{
  icon: IconComponentProps['component']
  title: string
  perks: string[]
}> = props => (
  <Row>
    <Col span={24}>
      <Row>
        <Icon
          component={props.icon}
          style={{ fontSize: '24px', paddingRight: '0.5rem' }}
        />
        <Text className="chat-placeholder-title">{props.title}</Text>
      </Row>
    </Col>

    <Row>
      <ul>
        {props.perks.map((perk, index) => (
          <li key={index}>
            <Text className="chat-placeholder-text">{perk}</Text>
          </li>
        ))}
      </ul>
    </Row>
  </Row>
)
