import { Settings } from '@skribe/web/core'
import { secureAPI } from './client'

export async function postSettings(settings: Settings): Promise<Settings> {
  return await secureAPI.post('rev', '/settings', { body: settings })
}

export async function fetchSettings(): Promise<Settings> {
  return await secureAPI.get('rev', '/settings', {})
}
