import { GetTranscriptResponse, STATUS } from '@skribe/web/core'
import { Button } from 'antd'
import { FilePdfOutlined, ReloadOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import { addCacheBuster } from '@skribe/web'
import { trackEvent } from '@skribe/web/tracking'

export function PdfButtons(props: {
  transcript: GetTranscriptResponse
  onRegeneratePDF: () => Promise<void>
}) {
  const { transcript, onRegeneratePDF } = props
  return (
    <>
      {transcript.pdf_url &&
        (transcript.pdf_url === STATUS.Processing ? (
          <>
            <Button icon={<FilePdfOutlined />} loading>
              Download PDF
            </Button>
            <Button icon={<ReloadOutlined />} loading>
              Refresh PDF
            </Button>
          </>
        ) : (
          <>
            <Link
              to={addCacheBuster(transcript.pdf_url)}
              target="_blank"
              onClick={() => {
                trackEvent('downloadPDF', { id: transcript.id })
              }}
            >
              <Button icon={<FilePdfOutlined />}>Download PDF</Button>
            </Link>
            <Button icon={<ReloadOutlined />} onClick={onRegeneratePDF}>
              Refresh PDF
            </Button>
          </>
        ))}
    </>
  )
}
