import { GetTranscriptResponse, truncateText } from '@skribe/web/core'
import { useInvalidateData } from '../../state'
import { changeTranscriptName } from '../transcripts'
import { Typography } from 'antd'

export function TranscriptTitle(props: { transcript: GetTranscriptResponse }) {
  const { refreshTranscript } = useInvalidateData()

  const { transcript } = props

  const onChangeName = async (value: string): Promise<void> => {
    await changeTranscriptName(transcript.id, value)
    await refreshTranscript()
  }
  return (
    <Typography.Title
      level={3}
      data-testid="nameInput"
      className="subtitle-1 d-block"
      style={{ paddingBottom: 8 }}
      editable={{
        onChange: value => onChangeName(value),
        text: transcript.name ?? '',
      }}
    >
      {truncateText(transcript.name, 50)}
    </Typography.Title>
  )
}
