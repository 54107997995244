import React from 'react'
import { Form, Row, List, Switch, Typography, Alert, Input, Col } from 'antd'
import { Transcript } from '@skribe/web/core'

import {
  FormButtons,
  FormError,
  LoadingComponent,
  HelpTooltip,
} from '../../components'
import { useUpgradeTranscriptionForm } from './useUpgradeTranscriptionForm'
import {
  calculateUpgradeCost,
  formatCost,
  rushDescription,
} from './upgradeCost'
import {
  OnSubmitUpgradeTranscriptionForm,
  TRANSCRIPTION_FORMAT,
  UpgradeTranscriptionFormShape,
} from './UpgradeTranscriptionFormTypes'
import './UpgradeTranscriptionForm.css'

type UpgradeTranscriptionFormProps = {
  transcript?: Transcript
  onClose: () => void
  onSubmit: OnSubmitUpgradeTranscriptionForm
  errorMessage: string
  defaultEventName?: string | null
}
export const UpgradeTranscriptionForm: React.FC<
  UpgradeTranscriptionFormProps
> = ({ transcript, onClose, onSubmit, errorMessage, defaultEventName }) => {
  const {
    upgrade,
    loading,
    shouldShowForm,
    shouldShowError,
    initialFormValues,
    isSubmitEnabled,
  } = useUpgradeTranscriptionForm({
    transcript,
    onSubmit,
  })

  const [form] = Form.useForm<UpgradeTranscriptionFormShape>()
  const rushValue = Form.useWatch('rush', form)

  return (
    <>
      {loading && <LoadingComponent />}
      {shouldShowForm && (
        <Form
          form={form}
          onFinish={upgrade}
          initialValues={initialFormValues}
          disabled={loading}
          labelAlign="left"
          className="schedule-form"
        >
          <Form.Item labelCol={{ span: 24 }} name="name" label="Transcript">
            <Input disabled />
          </Form.Item>
          <Form.Item
            labelCol={{ span: 24 }}
            name="duration_in_seconds"
            label="Duration"
          >
            <Input disabled />
          </Form.Item>
          <Row align={'middle'} justify={'start'}>
            <Typography.Text className="form-title">
              Upgrade Options
            </Typography.Text>
          </Row>
          <List
            bordered
            style={{ marginBottom: '2rem' }}
            size="small"
            dataSource={[
              {
                name: 'includeNames',
                label: 'Include Names',
                enabled: true,
                tooltip: 'Include Names will reuse existing speaker names.',
              },
              {
                name: 'rush',
                label: 'Rush',
                enabled: true,
                tooltip: rushDescription,
              },
            ]}
            renderItem={({ name, label, enabled, tooltip }) => (
              <List.Item className="switch-list">
                <Row justify={'space-between'} style={{ width: '100%' }}>
                  <Typography.Text style={{ alignSelf: 'center' }}>
                    {label} <HelpTooltip title={tooltip} />
                  </Typography.Text>
                  <Form.Item
                    name={name}
                    valuePropName="checked"
                    style={{ marginBottom: 0 }}
                  >
                    <Switch disabled={!enabled} />
                  </Form.Item>
                </Row>
              </List.Item>
            )}
          />
          <Form.Item
            labelCol={{ span: 24 }}
            name="custom"
            label="Corrections"
            tooltip="Separate new words by commas or spaces"
          >
            <Input.TextArea autoSize={{ minRows: 2, maxRows: 6 }} />
          </Form.Item>
          {false && (
            <Row
              style={{ marginTop: '2rem', marginBottom: '1rem' }}
              align="middle"
            >
              <Col style={{ textAlign: 'start' }} span={8}>
                {TRANSCRIPTION_FORMAT.UNFORMATTED}
              </Col>
              <Col span={8} style={{ textAlign: 'center' }}>
                <Form.Item
                  name={'isLegalFormat'}
                  valuePropName="checked"
                  style={{ marginBottom: 0 }}
                >
                  <Switch disabled />
                </Form.Item>
              </Col>
              <Col span={8} style={{ textAlign: 'end' }}>
                {TRANSCRIPTION_FORMAT.LEGAL}
              </Col>
            </Row>
          )}
          <Alert
            style={{ marginBottom: '1rem', marginTop: '3rem' }}
            message={`The cost of this upgrade is ${formatCost(
              calculateUpgradeCost({
                durationInSeconds: transcript?.duration_in_seconds ?? NaN,
                rush: rushValue,
              }),
            )}`}
            type="info"
          />
          <FormButtons
            onClose={onClose}
            saveDisabled={!isSubmitEnabled}
            saveTestId="upgradeTranscriptionButton"
            onSave={form.submit}
          />
        </Form>
      )}
      {shouldShowError && (
        <FormError onClose={onClose} message={errorMessage} />
      )}
    </>
  )
}
