import axios, { AxiosInstance } from 'axios'

const key = process.env.REACT_APP_SPEAKAI_API_KEY

// Duplicated code in rev and recurringCleanup lambdas
export class SpeakAI {
  private client: AxiosInstance
  private token: string | undefined

  protected constructor() {
    this.client = axios.create({
      baseURL: 'https://api.speakai.co/v1',
      maxBodyLength: Infinity,
      headers: { 'x-speakai-key': key, 'Content-Type': 'application/json' },
    })
  }

  public static async getInstance(): Promise<SpeakAI> {
    const instance = new SpeakAI()
    await instance.setToken()
    return instance
  }

  private async setToken(): Promise<void> {
    try {
      const tokenResponse =
        await this.client.post<PostAuthAccessTokenBodyResponse>(
          'auth/accessToken',
        )
      const token = tokenResponse.data.data.accessToken
      this.client.defaults.headers.common['x-access-token'] = `${token}`
      this.token = token
    } catch (error) {
      console.error('Error fetching or setting token:', error)
      throw error
    }
  }

  async scheduleMeetingAssistant(body: PostScheduleEventRequestBody) {
    try {
      const result = await this.client.post<PostScheduleEventResponseBody>(
        'meeting-assistant/events/schedule',
        JSON.stringify({ ...body }),
      )
      return result.data
    } catch (error) {
      console.error(error)
      throw error
    }
  }
}

interface PostAuthAccessTokenBodyResponse {
  status: string
  data: {
    email: string
    accessToken: string
    refreshToken: string
  }
}

export interface PostScheduleEventRequestBody {
  meetingURL: string
  meetingDate?: string
  title: string
  meetingLanguage?: string
}
interface PostScheduleEventResponseBody {
  status: string
  data: {
    companyId: string
    userId: string
    platform: string
    source: string
    meetingURL: string
    startTime: string
    title: string
    meetingLanguage: string
    currentStatus: string
    _id: string
    meetingAssistantEventId: string
    attendees: any[]
    history: any[]
    createdAt: string
    updatedAt: string
    __v: number
    message: string
  }
}
