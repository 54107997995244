import { FC, ReactNode, useEffect, useState } from 'react'
import { FlagsProvider as BaseFlagsProvider } from 'flagged'
import { featureDb } from '@skribe/web/api'
import { FeatureFlags } from 'flagged'
import { LoadingComponent } from '../components'
import { persistFlagsToSession } from '@skribe/web'
import { useVendorUser } from '../Auth/useVendorUser'

interface FlagsProviderProps {
  children: ReactNode
}

export const FlagsProvider: FC<FlagsProviderProps> = ({ children }) => {
  const { isStillLoading, email } = useVendorUser()
  const [features, setFeatures] = useState<FeatureFlags | undefined>()
  useEffect(() => {
    if (isStillLoading) {
      setFeatures({})
      return
    }
    getFlags(email ?? '').then(setFeatures)
  }, [isStillLoading, email])

  if (!features) return <LoadingComponent />
  return <BaseFlagsProvider features={features}>{children}</BaseFlagsProvider>
}

async function getFlags(username: string) {
  let flags
  try {
    flags = await featureDb.fetchFeatureFlags({
      username,
    })
  } catch (error) {
    flags = {}
  }
  persistFlagsToSession(flags)
  return flags
}
