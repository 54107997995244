import React from 'react'
import ReactDOM from 'react-dom/client'

import 'antd/dist/reset.css'
import './index.css'

import AntTheme from './app/AntTheme'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { routes } from './routes'
import { onStartup } from './startup'
import { QueryClientContextProvider } from './app/QueryClientContextProvider'
import { ContextProviders } from './app/context/ContextProviders'

onStartup()

export const router = createBrowserRouter(routes)
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <QueryClientContextProvider>
    <ContextProviders>
      <AntTheme>
        <RouterProvider router={router} />
      </AntTheme>
    </ContextProviders>
  </QueryClientContextProvider>,
)
