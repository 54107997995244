import { injectAuth } from './client'

const askQuestionUrl = process.env.REACT_APP_ASK_QUESTION_URL ?? ''

export async function askTranscriptQuestion(
  transcriptId: string,
  question: string,
  model: string = 'gpt-4o',
  history: Message[] = [],
) {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    timeout: 100000,
    body: JSON.stringify({
      transcriptId,
      question,
      model,
      history,
    }),
  }

  const finalParameters = await injectAuth(requestOptions)

  return await fetch(askQuestionUrl, finalParameters)
}
export type Message = {
  content: string
  role: 'user' | 'assistant'
}
