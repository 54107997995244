export enum TRANSCRIPTION_FORMAT {
  UNFORMATTED = 'Unformatted',
  LEGAL = 'Legal Format',
}

export type UpgradeTranscriptionFormShape = {
  name?: string | null
  duration_in_seconds?: string
  rush: boolean
  isLegalFormat: boolean
  includeNames: boolean
  custom?: string
}

export type OnSubmitUpgradeTranscriptionForm = (
  formResult: UpgradeTranscriptionFormShape,
) => Promise<void>
