import React from 'react'

export function createContext<T>(errorMessage: string, defaultValue?: T) {
  const context = React.createContext<T | undefined>(defaultValue)

  const useContext = () => {
    const value = React.useContext(context)

    if (value === undefined) {
      throw new Error(errorMessage)
    }

    return value
  }
  return { useContext, Provider: context.Provider } as const
}
