import React from 'react'
import { Switch, Tooltip } from 'antd'
import { useTranscriptPageContext } from '../context/TranscriptPageContext'
import { SwitchChangeEventHandler } from 'antd/es/switch'
import { trackEvent } from '@skribe/web/tracking'
import { useChatPanel } from './UseChatPanel'

export const ChatPanelSwitch = () => {
  const shouldUseChatPanel = useChatPanel()
  const context = useTranscriptPageContext()

  const onToggle: SwitchChangeEventHandler = checked => {
    trackEvent(checked ? 'openAIPanel' : 'closeAIPanel')
    context.setIsDrawerOpen(checked)
  }

  if (!shouldUseChatPanel) {
    return <></>
  }
  return (
    <Tooltip title={'Chat with Transcript'}>
      <Switch value={context.isDrawerOpen} onChange={onToggle} />
    </Tooltip>
  )
}
