import React from 'react'
import { Col, Row, Typography, Card } from 'antd'
import { truncateText } from '@skribe/web/core'
import { ClockCircleOutlined } from '@ant-design/icons'
import { LoadingComponent } from '../components'

import { Dialogue } from '../TranscriptPage/Dialogue/Dialogue'
import { timestamp } from '../utilities/timeUtilities'
import { useDocumentTitle } from '../utilities'
import { useClearInterval } from '../UseClearInterval'

import { ClipPlayer } from './ClipPlayer'
import { useClipPage } from './useClipPage'
import './ClipPage.css'

export function ClipPage() {
  const { clip } = useClipPage()
  useClearInterval()
  useDocumentTitle({ name: clip?.name, type: 'Clip' })

  const breakpoints = {
    xs: 20,
    sm: 18,
    md: 12,
    lg: 10,
  }

  if (!clip) return <LoadingComponent />

  return (
    <div className="clip-page">
      <Row justify="center">
        <Col {...breakpoints}>
          <Row justify="space-around" align="middle" className="clip-title">
            <Col>
              <Typography.Title level={4}>
                {truncateText(clip.name)}
              </Typography.Title>
            </Col>
            <Col>
              <Typography.Title level={5}>
                <ClockCircleOutlined />
                {'  '}
                {timestamp(clip.start)} - {timestamp(clip.end)}
              </Typography.Title>
            </Col>
          </Row>
          <div className="clip-video">
            {clip?.media_url && (
              <ClipPlayer
                end={clip?.end}
                start={clip?.start}
                transcriptUrl={clip?.media_url}
                clipUrl={clip?.download_url}
                data-testid="clip-player"
              />
            )}
          </div>
        </Col>
      </Row>
      <Row justify="center">
        <Col {...breakpoints}>
          <Card className="clip-dialogue">
            <Dialogue
              monologues={clip.monologues ?? []}
              speakers={clip.speakers}
            />
          </Card>
        </Col>
      </Row>
    </div>
  )
}
