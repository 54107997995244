import { createContext, useState, useContext, ReactNode } from 'react'

const TranscriptPageContext = createContext<
  | {
      isDrawerOpen: boolean
      setIsDrawerOpen: (open: boolean) => void
      transcriptId: string | undefined
      setTranscriptId: (id: string) => void
    }
  | undefined
>(undefined)

export const useTranscriptPageContext = () => {
  const ctx = useContext(TranscriptPageContext)
  if (!ctx)
    throw Error('Trying to use context outside of TranscriptPageContext')
  return ctx
}

export const TranscriptPageContextProvider: React.FC<{
  children: ReactNode
}> = ({ children }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(true)
  const [transcriptId, setTranscriptId] = useState<string | undefined>(
    undefined,
  )

  return (
    <TranscriptPageContext.Provider
      value={{ isDrawerOpen, setIsDrawerOpen, setTranscriptId, transcriptId }}
    >
      {children}
    </TranscriptPageContext.Provider>
  )
}
