import { Col, List, Row, Typography } from 'antd'
import React from 'react'
import { LiaisonEvent } from '@skribe/web/core'
import {
  CalendarOutlined,
  ClockCircleOutlined,
  UserOutlined,
} from '@ant-design/icons'
import { ZoomLiveMeetingButton } from '../Events/ZoomLiveMeetingButton'
import { toLocalDate, toLocalTime } from '@skribe/web/date'

const iconStyle = { color: '6C757D', paddingInlineEnd: '0.5rem' }

export const LiaisonEventItem: React.FC<{ item: LiaisonEvent }> = ({
  item,
}) => {
  return (
    <List.Item>
      <Col flex="auto">
        <Row justify={'space-between'} align={'middle'}>
          <Col span={22}>
            <Row wrap={false} gutter={4}>
              <Col span={8}>
                <Row wrap={false} gutter={8}>
                  <CalendarOutlined style={iconStyle} />
                  <Typography.Text ellipsis>{item.name}</Typography.Text>
                </Row>
              </Col>
              <Col span={8}>
                {item.group && (
                  <>
                    <UserOutlined style={iconStyle} />
                    <Typography.Text ellipsis>
                      {item.group.name}
                    </Typography.Text>
                  </>
                )}
              </Col>
              <Col span={4}>
                <CalendarOutlined style={iconStyle} />
                {toLocalDate(item.start)}
              </Col>

              <Col span={4}>
                <ClockCircleOutlined style={iconStyle} />
                <Typography.Text ellipsis>
                  {toLocalTime(item.start)}
                </Typography.Text>
              </Col>
            </Row>
          </Col>
          <Col>
            {item?.zoom_event?.startUrl && (
              <ZoomLiveMeetingButton
                startDate={item.start}
                startUrl={item?.zoom_event?.startUrl}
              />
            )}
          </Col>
        </Row>
      </Col>
    </List.Item>
  )
}
