import { useEffect, useState } from 'react'
import { Button, Result, Row, Steps, Typography } from 'antd'
import { GetTranscriptResponse, Nullable } from '@skribe/web/core'
import { toLocalDateTime } from '@skribe/web/date'
import { TAB } from '../transcriptStrings'
import { useFeature } from 'flagged'
import { UpgradeTranscriptDrawer } from '../Upgrade/UpgradeTranscriptDrawer'
import { PostHumanTranscriptBody } from '@skribe/web/api'

export const OrderHumanTranscription: React.FC<{
  date: Nullable<string>
  loading: boolean
  upgrade: (body?: PostHumanTranscriptBody) => void
  transcript: GetTranscriptResponse
}> = props => {
  const useHumanDrawer = useFeature('humanDrawer')

  const steps = [
    {
      title: 'Upgrade',
      content: useHumanDrawer ? (
        <UpgradeTranscriptDrawer
          transcript={props.transcript}
          orderHumanTranscript={props.upgrade}
        />
      ) : (
        <Button
          type="primary"
          style={{ margin: '13rem 0rem', width: '100%' }}
          loading={props.loading}
          onClick={() => props.upgrade()}
        >
          Order {TAB.Human}
        </Button>
      ),
    },
    {
      title: 'In Progress',
      content: (
        <Row justify="center" align="middle">
          <Result
            status="success"
            title="Order in Progress"
            subTitle={
              <Typography.Text>
                Your Professional Proofread order was requested on{' '}
                {toLocalDateTime(props.date)}.<br />
                This could take up to 2-3 days.
              </Typography.Text>
            }
          />
        </Row>
      ),
    },
    {
      title: 'Finished',
      content: <></>,
    },
  ]

  const [current, setCurrent] = useState(0)

  useEffect(() => {
    if (props.date) {
      setCurrent(1)
    }
  }, [props.date])

  const items = steps.map(item => ({ key: item.title, title: item.title }))

  return (
    <>
      <Steps current={current} items={items} />
      <Row justify={'center'}>{steps[current].content}</Row>
    </>
  )
}
