import { playlistDb } from '@skribe/web/api'
import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'

export function usePlaylistPage() {
  const { id } = useParams()

  const result = usePublicPlaylistQuery(id)
  return result
}

function usePublicPlaylistQuery(id: string | undefined) {
  const { data: playlist, isLoading } = useQuery({
    queryKey: ['playlist', id],
    refetchOnWindowFocus: false,
    queryFn: () => playlistDb.fetchPublicPlaylist(id),
  })
  return { playlist, isLoading }
}
