import { ChatClip } from '@skribe/web/core'
import {
  CloudOutlined,
  CopyOutlined,
  DislikeOutlined,
  LikeOutlined,
} from '@ant-design/icons'
import { ChatText } from './ChatText'
import { RelevantClips } from './RelevantClips'
import { Button, Flex, Tooltip } from 'antd'
import { copyToClipboard } from '@skribe/web'
import { useOnCopyNotification } from '../../../Notifications/onCopy'
import { trackEvent } from '@skribe/web/tracking'

export function ChatAnswer(props: {
  question?: string
  response: string
  error: string
}) {
  if (props.error) return <ChatError message={props.error} />
  const answer = getAnswer(props.response)
  return (
    <ChatText
      iconClassName={'chat-answer-avatar'}
      cardClassName={'chat-answer-card'}
      icon={<CloudOutlined />}
      text={answer}
    >
      <RelevantClips clips={parseClipsFrom(props.response)} />
      <Flex justify={'space-between'}>
        <span>
          <Tooltip title={'Helpful answer'}>
            <Button
              icon={<LikeOutlined />}
              className={'button-discrete'}
              style={{ marginRight: '0.25rem' }}
              onClick={() =>
                trackEvent('likeAiAnswer', { question: props.question, answer })
              }
            />
          </Tooltip>
          <Tooltip title={'Could be better'}>
            <Button
              icon={<DislikeOutlined />}
              className={'button-discrete'}
              onClick={() =>
                trackEvent('dislikeAiAnswer', {
                  question: props.question,
                  answer,
                })
              }
            />
          </Tooltip>
        </span>
        <CopyButton response={props.response} />
      </Flex>
    </ChatText>
  )
}

function ChatError(props: { message: string }) {
  return (
    <ChatText
      iconClassName={'chat-answer-avatar'}
      cardClassName={'chat-answer-card'}
      textClassName={'chat-error-text'}
      icon={<CloudOutlined />}
      text={`Error: ${props.message}`}
    />
  )
}

function CopyButton(props: { response: string }) {
  const { onCopy, notificationContext } = useOnCopyNotification('Answer')

  function copyAnswer() {
    copyToClipboard(getAnswer(props.response).trim())
    onCopy()
  }
  return (
    <>
      {notificationContext}
      <Button
        icon={<CopyOutlined />}
        className={'button-discrete'}
        onClick={copyAnswer}
      >
        Copy
      </Button>
    </>
  )
}

export function parseClipsFrom(answer: string): ChatClip[] {
  const jsonMarkdownBlock = answer.substring(answer.indexOf('```json'))
  const clipsAsString = jsonMarkdownBlock.substring(
    jsonMarkdownBlock.indexOf('['),
    jsonMarkdownBlock.lastIndexOf('```'),
  )

  try {
    return JSON.parse(clipsAsString)
  } catch (e) {
    return []
  }
}

export function getAnswer(answer: string): string {
  if (!answer.includes('```json')) return answer

  return answer.substring(0, answer.indexOf('```json'))
}
