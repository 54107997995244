import { useState } from 'react'
import { clipDb, playlistDb } from '@skribe/web/api'
import { useInvalidateData } from '../../../state'
import { trackEvent } from '@skribe/web/tracking'
import { Clip, Playlist } from 'src/core/types'

export function useClipItem(props: ClipItemProps) {
  const [editModeSelected, setEditModeSelected] = useState(false)
  const { refreshClips, refreshPlaylists } = useInvalidateData()
  const openEditMode = () => {
    setEditModeSelected(true)
  }

  async function editClipLength(startTime: number, endTime: number) {
    await clipDb.patchClipLength(props.clip.id, startTime, endTime)
    await refreshClips()
    closeEditMenu()
  }

  async function requestDownload() {
    await clipDb.postClipDownload(props.clip.id)
    await refreshClips()

    trackEvent('requestClipDownload', { clip: props.clip })
  }

  function closeEditMenu() {
    setEditModeSelected(false)
  }

  async function addToPlaylist(playlistId: string) {
    const matchingPlaylist = props.playlists?.find(pl => pl.id === playlistId)
    if (!matchingPlaylist) throw new Error('No matching playlist found')

    if (!matchingPlaylist.clipIds) {
      matchingPlaylist.clipIds = [props.clip.id]
    } else {
      matchingPlaylist.clipIds.push(props.clip.id)
    }

    await playlistDb.patchPlaylist(playlistId, matchingPlaylist?.clipIds)
    await refreshPlaylists()
  }

  return {
    editModeSelected,
    openEditMode,
    editClipLength,
    closeEditMenu,
    addToPlaylist,
    requestDownload,
  }
}

export type ClipItemProps = {
  clip: Clip
  onCopy: () => void
  onDelete: () => void
  selectedClipId: undefined | string
  isPlaying: boolean
  onPlay: () => void
  selectClip: (clip: Clip) => void
  editTitle?: (value: any) => void
  transcriptDuration: number
  onPause: () => void
  playlists?: Playlist[]
}
