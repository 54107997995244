import { Clip, Playlist } from '@skribe/web/core'
import { Col, List, Row, theme } from 'antd'
import {
  ActionMenu,
  createDeleteDropdownItem,
  EditableText,
} from 'src/app/components'
import { DownloadItem } from '../../ClipManager/ClipListItem/DownloadItem'
import { trackEvent } from '@skribe/web/tracking'
import { PlaylistClipList } from '../../ClipManager/PlaylistClipList'
import { useTranscriptPlayerContext } from 'src/app/context'
import { PlayPauseButton } from '../../ClipManager/ClipListItem/PlayPauseButton'

export type PlaylistItemProps = {
  clips: Clip[]
  playlist: Playlist
  delete: (id: string) => Promise<void>
  requestDownload?: (id: string) => Promise<void>
  update: (
    playlistId: string,
    clipIds?: string[],
    name?: string,
  ) => Promise<void>
}

export function PlaylistItem(props: PlaylistItemProps) {
  const { token } = theme.useToken()
  const {
    playClips,
    stoppedPlaying,
    playing,
    selectedPlaylistId,
    setSelectedPlaylistId,
  } = useTranscriptPlayerContext()
  const updateClipOrder = (clips: Clip[]) =>
    props.update(
      props.playlist.id,
      clips.map(clip => clip.id),
      undefined,
    )
  const updateName = (newName: string) =>
    props.update(props.playlist.id, undefined, newName)
  const deletePlaylist = () => props.delete(props.playlist.id)
  const requestDownload = async () => {
    if (props.requestDownload) {
      return props.requestDownload(props.playlist.id)
    }
  }
  const trackDownload = () =>
    trackEvent('DownloadPlaylist', { playlist: props.playlist })
  const play = () => {
    playClips(props.clips)
  }

  function selectPlaylist() {
    setSelectedPlaylistId(props.playlist.id)
  }
  const playlistIsSelected = props.playlist.id === selectedPlaylistId

  function showSelectedStyle() {
    return playlistIsSelected
      ? { backgroundColor: token.colorBgTextActive }
      : {}
  }

  return (
    <List.Item>
      <div
        style={{ ...showSelectedStyle(), width: '100%' }}
        onClick={selectPlaylist}
      >
        <Row className="playlist-header" style={{ width: '100%' }}>
          <Col span={2} style={{ alignContent: 'center' }}>
            <PlayPauseButton
              isSelected={playlistIsSelected}
              playing={playing}
              onPause={stoppedPlaying}
              onPlay={play}
            />
          </Col>
          <Col span={21} offset={1}>
            <Row justify={'space-between'}>
              <Col style={{ alignSelf: 'center' }}>
                <Row justify={'space-between'}>
                  {`${props.playlist.clipIds?.length} Clip(s)`}
                </Row>
              </Col>
              <Col>
                <Row>
                  {props.requestDownload && (
                    <DownloadItem
                      tooltipMessage="Download Playlist"
                      requestDownload={requestDownload}
                      trackDownload={trackDownload}
                      item={props.playlist}
                    />
                  )}
                  <ActionMenu
                    id="clipActionMenu"
                    items={[createDeleteDropdownItem(deletePlaylist)]}
                  />
                </Row>
              </Col>
            </Row>
            <Row align={'middle'} style={{ width: '100%' }}>
              <Col span={12}>
                <EditableText
                  startingValue={props.playlist.name ?? 'name'}
                  onEdit={updateName}
                  testId="playlistName"
                />
              </Col>
              <Col></Col>
            </Row>
          </Col>
        </Row>
        {playlistIsSelected && (
          <Row className="playlist-clip-list" style={{ width: '100%' }}>
            <PlaylistClipList
              playlistId={props.playlist.id}
              clips={props.clips}
              onUpdateSequence={updateClipOrder}
            />
          </Row>
        )}
      </div>
    </List.Item>
  )
}
