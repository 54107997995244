import { FC } from 'react'
import { Button, Card, Col, Result, Row, Image } from 'antd'
import { useUserAcknowledgeBrowserIsIncompatible } from './useBrowserWarning'
import { useDocumentTitle } from '../utilities'

export const BrowserWarning: FC = () => {
  useDocumentTitle({ name: 'Browser Warning' })

  const { onContinue } = useUserAcknowledgeBrowserIsIncompatible()

  return (
    <Row style={{ height: '100vh' }} justify="center" align="middle">
      <Col>
        <Card>
          <Result
            icon={
              <Image
                src="/illustration.svg"
                preview={false}
                alt="Browser Warning"
              />
            }
            title="Your browser is incompatible"
            subTitle="Skribe.ai beta only supports Google Chrome. You might experience unexpected errors."
            extra={[
              <a
                key="download"
                href="https://www.google.com/chrome/dr/download"
              >
                <Button type="primary" danger>
                  Download Chrome
                </Button>
              </a>,
              <Button key="continue" onClick={onContinue}>
                Continue anyway
              </Button>,
            ]}
          />
        </Card>
      </Col>
    </Row>
  )
}
