import { trackPageView } from '@skribe/web/tracking'
import { FC, useEffect } from 'react'
import { Outlet, useLocation } from 'react-router-dom'

export const Setup: FC = () => {
  const location = useLocation()

  useEffect(() => {
    trackPageView()
  }, [location.pathname])

  return <Outlet />
}
