import { FC } from 'react'
import { OnSpeakerChange, SkribeMonologue } from '@skribe/web/core'
import { EditableText } from '../../../components'
import { PlayCircleOutlined } from '@ant-design/icons'

export const MonologueTitle: FC<{
  onSpeakerChange?: OnSpeakerChange
  skribeMonologue: SkribeMonologue
  seek?: (amount: number) => void
}> = ({ skribeMonologue, onSpeakerChange, seek }) => {
  return (
    <div className="monologue-title">
      {onSpeakerChange ? (
        <EditableText
          startingValue={skribeMonologue.speakerName}
          onEdit={text =>
            onSpeakerChange(skribeMonologue.speaker, text, 'onTranscript')
          }
        />
      ) : (
        <div className="dialogue-speaker">{skribeMonologue.speakerName}</div>
      )}
      {seek && (
        <button
          onClick={() => seek(skribeMonologue.start)}
          className="dialogue-seek"
        >
          <PlayCircleOutlined />
          &nbsp;
          {skribeMonologue?.timestamp}
        </button>
      )}
    </div>
  )
}
