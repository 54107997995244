import { Tabs } from 'antd'
import { useFeature } from 'flagged'
import { Clip, isAudioUrl } from '@skribe/web/core'
import { ManualClipControls } from '../ClipManager/ManualClipControls'
import { ClipListContainer } from '../ClipManager/ClipListContainer'
import { TabType } from '../../components'
import { PlaylistManager } from './Playlist/PlaylistManager'

export function canDownloadPlaylist(media_url: string | undefined | null) {
  return Boolean(media_url && !isAudioUrl(media_url ?? ''))
}

export function ClipTabs(props: {
  clips: Clip[]
  transcriptId: string
  createClip: any
  duration: number | null | undefined
  clipError: Error | null
  mediaUrl: string | null | undefined
}) {
  const playlistFlag = useFeature('playlist')
  const manualClipMoveFlag = useFeature('manualClipMove')

  const clipTabs: TabType[] = [
    {
      key: 'clips',
      label: 'Clips',
      children: (
        <>
          {!manualClipMoveFlag && (
            <ManualClipControls
              createClip={props.createClip('manual')}
              durationInSeconds={props.duration}
            />
          )}
          <ClipListContainer
            transcriptId={props.transcriptId}
            clips={props.clips}
            clipError={props.clipError}
            transcriptDuration={props.duration}
          />
        </>
      ),
    },
  ]
  if (playlistFlag) {
    clipTabs.push({
      key: 'playlist',
      label: 'Playlist',
      children: (
        <PlaylistManager
          canDownloadPlaylist={canDownloadPlaylist(props.mediaUrl)}
          clips={props.clips}
          transcriptId={props.transcriptId}
        />
      ),
    })
  }

  return (
    <Tabs
      className="clip-tabs"
      data-testid="clipTabs"
      defaultActiveKey={'clips'}
      items={clipTabs as React.ComponentProps<typeof Tabs>['items']}
    />
  )
}
