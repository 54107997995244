import { Clip, MonologueElement } from '@skribe/web/core'
import { MonologueElementContainer } from './MonologueElementContainer'

export function MonologueContent(props: {
  currentMonologueElements: MonologueElement[]
  clips: Clip[] | undefined
  onElementClick?: (clipID?: string) => void
  previousMonologueElement?: MonologueElement
  nextMonologueElement?: MonologueElement
}) {
  return (
    <span className="monologue-content enable-select">
      {props.currentMonologueElements.map((element, index) => {
        return (
          <MonologueElementContainer
            key={index}
            clips={props.clips}
            elements={props.currentMonologueElements}
            index={index}
            element={element}
            onElementClick={props.onElementClick}
            previousMonologueElement={props.previousMonologueElement}
            nextMonologueElement={props.nextMonologueElement}
          />
        )
      })}
    </span>
  )
}
