import { FC } from 'react'
import { Col, Row, Tabs } from 'antd'
import './TranscriptPage.css'
import { TranscriptPageProps, useTranscriptPage } from './useTranscriptPage'
import { useSearch } from './useSearch'
import { useFeature } from 'flagged'
import { useIsTrial } from '../User/useIsTrial'
import { TAB } from './transcriptStrings'
import { TranscriptControls } from './TranscriptControls/TranscriptControls'
import { MediaPlayer } from './MediaPlayer'
import { RoughTab } from './Dialogue/RoughTab'
import { HumanTab } from './Dialogue/HumanTab'
import { DialogueContainer } from './Dialogue/DialogueContainer'
import { ClipTabs } from './ClipTabs/ClipTabs'
import { TabType } from '../components'

const leftBreakpoints = { xs: 10, sm: 10, md: 10, lg: 14, xl: 14, xxl: 14 }
const rightBreakpoints = { xs: 14, sm: 14, md: 14, lg: 10, xl: 10, xxl: 10 }

export const TranscriptPage: FC<TranscriptPageProps> = ({ transcript }) => {
  const testId = 'transcriptPage'
  const {
    clips,
    transcriptHeaderRef,
    clipError,
    back,
    seek,
    onDeleteClick,
    onRegeneratePDF,
    onSpeakerChange,
    createClip,
    speakersMachine,
    speakersHuman,
    onProgressUpdate,
    onElementClick,
    monologueTab,
    onMonologueTabChange,
    machineMonologues,
    machineMonologuesError,
    humanMonologues,
    humanMonologuesError,
    orderHumanTranscript,
    setTranscriptDuration,
    duration,
  } = useTranscriptPage({ transcript })

  const {
    onSearch,
    resultCount,
    searchItemCount,
    searchNext,
    searchPrevious,
    hasUserSearched,
  } = useSearch(
    monologueTab === TAB.Human ? humanMonologues : machineMonologues,
  )
  const isTrial = useIsTrial()
  const useHumanAddon = useFeature('humanAddon')

  const canUpgradeToHuman = !transcript.meeting_uuid

  const tabs: TabType[] = [
    {
      key: TAB.Rough,
      label: TAB.Rough,
      children: (
        <RoughTab>
          <DialogueContainer
            monologuesError={machineMonologuesError}
            monologues={machineMonologues}
            clips={clips}
            speakers={speakersMachine}
            seek={seek}
            onClip={createClip}
            onSpeakerChange={onSpeakerChange}
            onElementClick={onElementClick}
          />
        </RoughTab>
      ),
    },
  ]
  if (canUpgradeToHuman) {
    tabs.push({
      key: TAB.Human,
      label: TAB.Human,
      children: (
        <HumanTab
          useHumanAddon={!!useHumanAddon}
          transcript={transcript}
          isTrial={isTrial}
          orderHumanTranscript={orderHumanTranscript}
        >
          <DialogueContainer
            monologuesError={humanMonologuesError}
            monologues={humanMonologues}
            clips={clips}
            speakers={speakersHuman}
            seek={seek}
            onClip={createClip}
            onSpeakerChange={onSpeakerChange}
            onElementClick={onElementClick}
          />
        </HumanTab>
      ),
    })
  }

  return (
    <Row className={'disable-select'} gutter={32} data-testid={testId}>
      <Col {...leftBreakpoints}>
        <div className="transcript-dialogue-container">
          <TranscriptControls
            transcriptHeaderRef={transcriptHeaderRef}
            clips={clips}
            back={back}
            onDeleteClick={onDeleteClick}
            transcript={transcript}
            tab={monologueTab}
            onRegeneratePDF={onRegeneratePDF}
            speakers={
              monologueTab === TAB.Human ? speakersHuman : speakersMachine
            }
            onSpeakerChange={onSpeakerChange}
            onSearch={onSearch}
            searchPrevious={searchPrevious}
            searchNext={searchNext}
            searchItemCount={searchItemCount}
            resultCount={resultCount}
            hasUserSearched={hasUserSearched}
          />

          <Tabs
            data-testid="dialogueTabs"
            activeKey={monologueTab}
            items={tabs as React.ComponentProps<typeof Tabs>['items']}
            onChange={onMonologueTabChange}
          />
        </div>
      </Col>
      <Col {...rightBreakpoints}>
        <div
          data-testid="transcript-media-container"
          className="transcript-media-container"
        >
          <MediaPlayer
            mediaUrl={transcript.media_url}
            onProgressUpdate={onProgressUpdate}
            setTranscriptDuration={setTranscriptDuration}
          />
          <ClipTabs
            mediaUrl={transcript.media_url}
            transcriptId={transcript.id}
            clips={clips}
            createClip={createClip}
            duration={duration}
            clipError={clipError}
          />
        </div>
      </Col>
    </Row>
  )
}
