import { DetailedUser } from '@skribe/web/core'
import posthog from 'posthog-js'

export function initialize() {
  posthog.init(process.env.REACT_APP_POSTHOG_TOKEN || '', {
    api_host: 'https://app.posthog.com',
    opt_in_site_apps: true,
    on_xhr_error: e => {
      console.error(
        'Some errors happened while trying to upload to Posthog',
        JSON.stringify(e),
      )
    },
  })
}

export const trackPageView = () => {
  posthog.capture('$pageview')
}

export const trackEvent = (eventName: string, properties = {}) => {
  posthog.capture(eventName, properties)
}

export async function setTrackedUser(user?: DetailedUser) {
  if (!user) return

  posthog.identify(user.email)
  posthog.setPersonProperties(user)
}
