import { Button, Row, Tooltip } from 'antd'
import { CheckCircleTwoTone, CloseCircleTwoTone } from '@ant-design/icons'

import { Clip, colors } from '@skribe/web/core'

import { useClipControls } from '../UseClipControls'
import { ClipEndInput, ClipStartInput } from '../ClipInput'

export function EditableClipInput(props: {
  max: number
  clip: Clip
  onConfirm: (start: number, end: number) => void
  onCancel: () => void
}) {
  const duration = props.max
  const initialRange = [props.clip.start, props.clip.end]
  const { range, step, startMax, onStartInputChange, onEndInputChange } =
    useClipControls({ initialRange, duration })

  function editClip() {
    props.onConfirm(range[0], range[1])
  }

  return (
    <Row align="middle">
      <ClipStartInput
        step={step}
        range={range}
        max={startMax}
        onChange={onStartInputChange}
      />
      -
      <ClipEndInput
        step={step}
        range={range}
        max={duration}
        onChange={onEndInputChange}
      />
      <ClipConfirmOrCancel onConfirm={editClip} onCancel={props.onCancel} />
    </Row>
  )
}

function ClipConfirmOrCancel(props: {
  onConfirm: () => void
  onCancel: () => void
}) {
  return (
    <div>
      <Tooltip title="Confirm">
        <Button
          onClick={props.onConfirm}
          icon={<CheckCircleTwoTone twoToneColor={colors.green} />}
          type="text"
        />
      </Tooltip>
      <Tooltip title="Cancel">
        <Button
          onClick={props.onCancel}
          icon={<CloseCircleTwoTone twoToneColor={colors.error} />}
          type="text"
        />
      </Tooltip>
    </div>
  )
}
