import React from 'react'
import { Button, Col, Image, Result, Row } from 'antd'

export const FormError: React.FC<{
  onClose: () => void
  message: string
}> = props => {
  return (
    <Row justify="center" align="middle">
      <Col>
        <Result
          icon={
            <Image src="/error-illustration.svg" alt="Error" preview={false} />
          }
          title="Please try later"
          subTitle={props.message}
        />
        <Row justify={'center'}>
          <Button type="primary" htmlType="submit" onClick={props.onClose}>
            Close
          </Button>
        </Row>
      </Col>
    </Row>
  )
}
