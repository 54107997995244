import first from 'lodash/first'
import last from 'lodash/last'

// TODO: merge with ClipTime
export type TimeSpan = { start: number; end: number }

export const extractTimeSpanForWord = (node: Element): TimeSpan => {
  const attrStart = node.getAttribute('data-ts')
  const attrEnd = node.getAttribute('data-end-ts')
  // console.log('extractTimeSpanForWord', { attrEnd, attrStart })
  const start = parseFloat(attrStart ?? '0')
  const end = parseFloat(attrEnd ?? '0')
  return { start, end }
}
export function getTimeSpanOf(_timeSpans: Array<TimeSpan>): TimeSpan {
  const timeSpans = _timeSpans.filter(tS => !(tS.end === 0 && tS.start === 0))
  const start = first(timeSpans)?.start
  let end = last(timeSpans)?.end
  if (end === 0 && timeSpans.length > 1) {
    end = timeSpans.at(timeSpans.length - 2)?.end
  }

  return { start: start ?? 0, end: end ?? 0 }
}
