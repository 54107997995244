import { Breadcrumb as AntDBreadcrumb } from 'antd'
import { BreadcrumbProps, useBreadcrumb } from './useBreadcrumb'
import { Link } from 'react-router-dom'

export function Breadcrumb(props: BreadcrumbProps) {
  const { items } = useBreadcrumb(props)

  if (items.length === 3) {
    return <AntDBreadcrumb items={items} />
  }

  return <Link to={props.back}>Back to event</Link>
}
