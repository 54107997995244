import { Button } from 'antd'
import { VideoCameraAddOutlined } from '@ant-design/icons'
import { Event, LiveMeetingDetails } from '@skribe/web/core'
import { liveRecorderDb } from '@skribe/web/api'
import { isBeforeDate } from '@skribe/web/date'

export const RequestLiveMeetingAssistantButton: React.FC<{
  zoomEvent: LiveMeetingDetails
  event: Event
}> = ({ event, zoomEvent }) => {
  const isBeforeEvent = isBeforeDate(event.start)

  return (
    <Button
      icon={<VideoCameraAddOutlined />}
      onClick={async () => {
        if (zoomEvent.joinUrl) {
          await liveRecorderDb.attachLiveRecorder(event.id)
        }
      }}
      disabled={isBeforeEvent}
    >
      Invite Meeting Backup
    </Button>
  )
}
