import { Amplify } from 'aws-amplify'
import awsExports from './aws-exports'
import { initialize } from '@skribe/web/tracking'
import { isDevelopment } from './core'

export function onStartup() {
  Amplify.configure(awsExports)
  initialize()
  removeLogsForProd()
}

function removeLogsForProd() {
  if (!isDevelopment) {
    console.log = () => {}
    console.warn = () => {}
    console.error = () => {}
  }
}
