import React, { ReactNode } from 'react'
import { AuthProvider } from '../User/AuthProvider'
import { TranscriptPageContextProvider } from './TranscriptPageContext'
import { TranscriptPlayerProvider } from './TranscriptPlayerContext'

export const ContextProviders: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  return (
    <AuthProvider>
      <TranscriptPageContextProvider>
        <TranscriptPlayerProvider>{children}</TranscriptPlayerProvider>
      </TranscriptPageContextProvider>
    </AuthProvider>
  )
}
