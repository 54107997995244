import { useState } from 'react'
import { toHMS } from '@skribe/web/date'
import {
  OnSubmitUpgradeTranscriptionForm,
  UpgradeTranscriptionFormShape,
} from './UpgradeTranscriptionFormTypes'
import { Transcript } from '@skribe/web/core'

export function useUpgradeTranscriptionForm({
  transcript,
  onSubmit,
}: {
  transcript?: Transcript
  onSubmit: OnSubmitUpgradeTranscriptionForm
}) {
  const [isSubmitEnabled, setIsSubmitEnabled] = useState(true)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<any>(false)

  const initialFormValues: UpgradeTranscriptionFormShape = {
    name: transcript?.name,
    duration_in_seconds: transcript?.duration_in_seconds
      ? toHMS(transcript?.duration_in_seconds)
      : 'Unknown',
    rush: false,
    includeNames: false,
    isLegalFormat: false,
  }

  return {
    upgrade,
    loading,
    shouldShowForm: !error && !loading,
    shouldShowError: error && !loading,
    isSubmitEnabled,
    initialFormValues,
  }

  async function upgrade(formData: UpgradeTranscriptionFormShape) {
    try {
      setLoading(true)
      setIsSubmitEnabled(false)

      await onSubmit(formData)
    } catch (e) {
      console.error(e)
      setError(e)
    } finally {
      setLoading(false)
      setIsSubmitEnabled(true)
    }
  }
}
