import { Clip } from '@skribe/web/core'
import { theme } from 'antd'

export type ClipSelectionProps = {
  clip: Clip
  selectedClipId: undefined | string
}

export function useClipSelection(props: ClipSelectionProps) {
  const isSelectedClip = props.selectedClipId === props.clip.id
  const { token } = theme.useToken()

  function showSelectedStyle() {
    return isSelectedClip ? { backgroundColor: token.colorBgTextActive } : {}
  }

  return { isSelectedClip, showSelectedStyle }
}
