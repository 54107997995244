import inRange from 'lodash/inRange'
import { Nullable } from '@skribe/web/core'

export function spotlightWord(cursor: number | undefined) {
  const element = Array.from(document.querySelectorAll('[data-ts]')).find(e =>
    inRange(
      cursor ?? 0,
      Number(e.getAttribute('data-ts')) ?? 0,
      Number(e.getAttribute('data-end-ts')),
    ),
  )

  document
    .querySelectorAll('.spotlight-word')
    .forEach(e => e.classList.remove('spotlight-word'))

  element?.classList.add('spotlight-word')
}

export function addSearchHighlight(times: Number[]) {
  document
    .querySelectorAll('.highlight-search-result')
    .forEach(e => e.classList.remove('highlight-search-result'))

  const allDomElements = Array.from(document.querySelectorAll('[data-ts]'))

  allDomElements
    .filter(e => times.includes(Number(e.getAttribute('data-ts'))))
    .forEach(e => e.classList.add('highlight-search-result'))
}

// TODO: This would be part of virtuoso
export function scrollAndHighlightCurrent(ts: number) {
  removeCurrentHighlight()

  addHighlightAndScrollTo(document.querySelector(`[data-ts='${ts}']`))
}

export function removeCurrentHighlight() {
  Array.from(document.getElementsByClassName('current-search-result')).forEach(
    r => r.classList.remove('current-search-result'),
  )
}

function addHighlightAndScrollTo(element: Nullable<Element>) {
  if (!element) return
  element.scrollIntoView({ block: 'center' })
  element.classList.add('current-search-result')
}
