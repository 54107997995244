import { NavigateFunction, useNavigate } from 'react-router-dom'
import { SearchItem, SearchResult } from '@skribe/web/api'
import { truncateText } from '@skribe/web/core'
import { useQuery } from '@tanstack/react-query'
import { Tooltip } from 'antd'
import { ReactNode } from 'react'

export interface BreadcrumbProps {
  transcriptId: string
  back?: any
}
type BreadcrumbItem = {
  title: ReactNode
  onClick?: () => void
}

export function useBreadcrumb({ transcriptId: id }: BreadcrumbProps) {
  const to = useNavigate()
  const { data } = useQuery<SearchResult>({ queryKey: ['search'] })

  return {
    items: convertSearchItemsToBreadcrumbItems(id, data, to),
  }
}

export function convertSearchItemsToBreadcrumbItems(
  transcriptId: string,
  search: SearchResult | undefined,
  navigateTo: NavigateFunction,
): BreadcrumbItem[] {
  if (!search) return []

  const transcript = search.transcripts.find(found => found.id === transcriptId)
  const transcriptItem = transcript
    ? { title: tooltip(transcript.name) }
    : undefined

  const event = search.events.find(event => event.id === transcript?.parentId)
  const eventItem = event ? eventToItem(event, navigateTo) : undefined

  const matter = search.matters.find(matter => matter.id === event?.parentId)
  const matterItem = matter ? matterToItem(matter, navigateTo) : undefined

  if (matterItem && eventItem && transcriptItem) {
    return [matterItem, eventItem, transcriptItem]
  }
  return []
}

function eventToItem(foundEvent: SearchItem, to: (path: string) => void) {
  return {
    title: tooltip(foundEvent?.name),
    onClick: () => to(`/events/${foundEvent?.id}`),
  }
}

function matterToItem(matter: SearchItem, to: (path: string) => void) {
  return {
    title: tooltip(matter?.name),
    onClick: () => {
      to(`/matters/${matter?.id}`)
    },
  }
}

function tooltip(name: string) {
  return <Tooltip title={name}>{truncateText(name, 20)}</Tooltip>
}
