import { useContext, useEffect, useState } from 'react'
import { userDb } from '@skribe/web/api'
import {
  DetailedUser,
  ERROR_LOGIN_USER,
  ROLE,
  isLiaison,
} from '@skribe/web/core'
import { setTrackedUser } from '@skribe/web/tracking'
import { AuthContext } from './AuthProvider'

export function useUserProvider(isAuthenticated: boolean) {
  const [user, setUser] = useState<DetailedUser>()
  const [loadUserError, setLoadUserError] = useState<Error | undefined>(
    undefined,
  )
  const { setRole, setIsLoading } = useContext(AuthContext)

  useEffect(() => {
    if (isAuthenticated) {
      load()
    }
    // eslint-disable-next-line
  }, [isAuthenticated])

  return {
    user,
    loadUserError,
    groups: user?.groups ?? [],
    refreshUser: load,
  }

  function load() {
    setIsLoading(true)
    userDb
      .fetchUser()
      .then(user => {
        setUser(user)
        setTrackedUser(user)
        const role = isLiaison(user) ? ROLE.LIAISON : ROLE.USER
        setRole(role)
      })
      .catch(e => {
        console.error(e)
        setLoadUserError(new Error(ERROR_LOGIN_USER))
      })
      .finally(() => {
        setIsLoading(false)
        sessionStorage.removeItem('enhanced')
      })
  }
}
