import { useState } from 'react'
import { useUserContext } from '../../User/UserProvider'
import { userDb } from '@skribe/web/api'

export function useUpgrade() {
  const { user, refreshUser } = useUserContext()
  const [modalOpen, setModalOpen] = useState(false)
  const isTrial = user.trial
  const isTrialExpired = !user.trialRemainingSeconds

  return {
    isTrialExpired,
    upgrade,
    openModal,
    closeModal,
    modalOpen,
    isTrial,
    remaining: {
      minutes: Math.floor((user.trialRemainingSeconds ?? 0) / 60),
      seconds: (user.trialRemainingSeconds ?? 0) % 60,
    },
  }

  function openModal() {
    setModalOpen(true)
  }

  function closeModal() {
    setModalOpen(false)
  }

  async function upgrade() {
    try {
      await userDb.upgradeUser()
      refreshUser()
    } catch (error) {
      console.error(error)
      closeModal()
    }
  }
}
