import { Clip } from '@skribe/web/core'
import { trackEvent } from '@skribe/web/tracking'
import { clipDb } from '@skribe/web/api'
import { useInvalidateData, usePlaylistQuery } from '../../state'

import { ClipList, SkeletonClipList } from './ClipList'
import { BasicErrorComponent } from '../../components'

export function ClipListContainer(props: {
  clipError: Error | null
  clips: Clip[] | undefined
  transcriptId: string
  transcriptDuration: number | null | undefined
}) {
  const { refreshClips } = useInvalidateData()
  const { playlists } = usePlaylistQuery({
    transcriptId: props.transcriptId,
    clips: props.clips ?? [],
  })

  async function onUpdateClipName(id: string, name: string): Promise<void> {
    if (props.clips) {
      const foundClip = props.clips.find(c => c.id === id)
      trackEvent('renameClip', {
        clip: { old: foundClip?.name, new: name, clip: foundClip },
      })
      await clipDb.patchClipName(id, name)
      await refreshClips()
    }
  }

  function onUpdateSequence(items: Clip[]) {
    trackEvent('reorderedClips')
    clipDb.updateClipSequence(items)
  }

  if (props.clipError)
    return <BasicErrorComponent message="Unable to load Clips" />
  if (!(props.clips && props.transcriptDuration)) return <SkeletonClipList />

  return (
    <div className="list-container">
      <ClipList
        onUpdateSequence={onUpdateSequence}
        playlists={playlists}
        clips={props.clips}
        onUpdateName={onUpdateClipName}
        transcriptDuration={props.transcriptDuration}
      />
    </div>
  )
}
