import awsExports from '../../../aws-exports'
import { PutObjectCommand, S3Client } from '@aws-sdk/client-s3'
import { Credentials } from '@aws-sdk/types'
import { PART_SIZE, uploadMultipartFile } from './uploadMultipartFile'

export async function putObject(
  file: File,
  credentials: Credentials,
  uniqueFileName: string,
  metadata?: Record<string, string>,
) {
  const bucketName = awsExports.aws_user_files_s3_bucket
  const key = 'public/inbox/' + uniqueFileName
  const s3Client = new S3Client({
    region: awsExports.aws_user_files_s3_bucket_region,
    credentials,
  })

  if (file.size < PART_SIZE) {
    await uploadFile(s3Client, bucketName, key, file, metadata ?? {})
  } else {
    await uploadMultipartFile(s3Client, bucketName, key, file, metadata ?? {})
  }
}

async function uploadFile(
  s3Client: S3Client,
  Bucket: string,
  Key: string,
  file: File,
  Metadata: Record<string, string>,
) {
  const buffer = new Uint8Array(await file.arrayBuffer())
  await s3Client.send(
    new PutObjectCommand({
      Bucket,
      Key,
      Body: buffer,
      Metadata,
    }),
  )
}
