import { useEffect, useState } from 'react'
import { LiaisonEvent } from '@skribe/web/core'
import { liaisonDb } from '@skribe/web/api'

export function useLiaisonPage() {
  const [events, setEvents] = useState<LiaisonEvent[]>([])
  const [error, setError] = useState<unknown>()

  useEffect(() => {
    liaisonDb
      .fetchEventsForLiaison()
      .then((response: any) => {
        setEvents(response)
      })
      .catch(e => {
        setError(e)
      })
  }, [setEvents, setError])

  if (error) throw error
  return { events }
}
