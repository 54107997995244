import { useState } from 'react'
import { SkribeDate, now, toDate, formatDefaultTitle } from '@skribe/web/date'
import { Event } from '@skribe/web/core'
import {
  OnSubmitLiveMeetingForm,
  ScheduleLiveMeetingFormShape,
} from './ScheduleLiveMeetingFormTypes'

export function useScheduleLiveMeetingForm({
  event,
  onSubmit,
  defaultEventName,
}: {
  event?: Event
  onSubmit: OnSubmitLiveMeetingForm
  defaultEventName?: string | null
}) {
  const [isSubmitEnabled, setIsSubmitEnabled] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<any>(false)

  const liveCreatedAt = event?.liveCreatedAt
    ? toDate(event?.liveCreatedAt)
    : undefined
  const lastDay = (liveCreatedAt ?? now()).add(89, 'day')
  const initialFormValues: ScheduleLiveMeetingFormShape = {
    eventName: defaultEventName && formatDefaultTitle(defaultEventName),
    start: event?.start ? toDate(event.start) : undefined,
    durationInMinutes: event?.estimated_duration_in_minutes ?? 120,
    hasAI: true,
    hasHumanProofreading: true,
    hasLiaison: true,
  }

  return {
    scheduleMeeting,
    isOutOfBounds,
    loading,
    shouldShowForm: !error && !loading,
    shouldShowError: error && !loading,
    isSubmitEnabled,
    isLimit,
    initialFormValues,
    setIsSubmitEnabled,
  }

  async function scheduleMeeting(formData: ScheduleLiveMeetingFormShape) {
    try {
      if (!formData.start) throw new Error('Start date is required')
      if (!formData.durationInMinutes)
        throw new Error('Estimated Duration is required')

      setLoading(true)
      setIsSubmitEnabled(false)

      await onSubmit(formData)
    } catch (e) {
      console.error(e)
      setError(e)
    } finally {
      setLoading(false)
      setIsSubmitEnabled(true)
    }
  }

  function isOutOfBounds(date: SkribeDate): boolean {
    return date.isBefore(now(), 'day') || date.isAfter(lastDay, 'day')
  }

  function isLimit(cell: SkribeDate): boolean {
    if (!liveCreatedAt) return false

    return cell.isSame(liveCreatedAt, 'day') || cell.isSame(lastDay, 'day')
  }
}
