import { useNavigate, useSearchParams } from 'react-router-dom'
import { useContext, useEffect, useState } from 'react'
import { useUserContext } from '../User/UserProvider'
import { trackEvent } from '@skribe/web/tracking'
import { MatterWithEvents } from '@skribe/web/core'
import { matterDb } from '@skribe/web/api'
import { MatterContext } from './MatterProvider'
import { Nullable } from '@skribe/web/core'
import { TabTitle } from '../utilities'
import { useVendorUser } from '../Auth/useVendorUser'
import { useInvalidateData } from '../state'

export const PRIVATE: string = 'Private'

export function useMatterSelector() {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const group = searchParams.get('group')
  const userInfo = useVendorUser()
  const { groups } = useUserContext()

  const { selectedMatter } = useContext(MatterContext)

  const [selectedGroup, setSelectedGroup] = useState<Nullable<string>>(group)
  const [tabTitle, setTabTitle] = useState<TabTitle | undefined>()
  const defaultTab = groups.length === 1 ? groups[0].id : PRIVATE
  const { refreshMatters } = useInvalidateData()

  useEffect(() => {
    if (selectedMatter?.name) {
      setTabTitle({ name: selectedMatter?.name, type: 'Matter' })
    } else if (selectedGroup) {
      setTabTitle({ name: selectedGroup, type: 'Group' })
    }
  }, [selectedGroup, selectedMatter?.name])

  useEffect(() => {
    if (selectedMatter) {
      setSelectedGroup(extractGroup(selectedMatter))
    }
  }, [selectedMatter])

  useEffect(() => {
    if (group) setSelectedGroup(group)
  }, [group])

  return {
    tabTitle,
    defaultTab,
    selectedGroup,
    navigate,
    groups,
    selectedMatter,
    onCreateMatter,
    onDeleteMatter,
    isCognitoUser: !userInfo.loggedInOnFrontegg,
  }

  async function onCreateMatter(groupId?: string) {
    trackEvent('createMatter', { name: groupId })
    const matterResponse = await matterDb.createMatter(groupId)

    await refreshMatters()
    navigate(`/matters/${matterResponse?.id}`)
  }
  async function onDeleteMatter(id: string | undefined) {
    trackEvent('matterDb.deleteMatter', { id: selectedMatter?.id })
    await matterDb.deleteMatter(id)

    await refreshMatters()
    navigate({
      pathname: '/matters',
      search: `?group=${encodeURIComponent(selectedGroup ?? PRIVATE)}`,
    })
  }

  function extractGroup(matter: MatterWithEvents) {
    return matter?.groups?.at(0)
  }
}
