import { Matter, MatterPin, SkribeUser } from '@skribe/web/core'
import { ItemType } from 'antd/es/menu/hooks/useItems'
import { PushpinOutlined } from '@ant-design/icons'
import { Typography } from 'antd'
import { matterDb, userDb } from '@skribe/web/api'
import { useUserContext } from '../User/UserProvider'
import { nowEpoch } from '@skribe/web/date'

export function useMatterListItem(props: {
  matter: Matter
  onMove: () => any
}) {
  const { user, refreshUser } = useUserContext()
  const onShare = async (groupId: string) => {
    await matterDb.moveMatter(props.matter.id, groupId)
    props.onMove()
  }

  const pinMatter = async () => {
    const currentlyPinnedMatters = (user as SkribeUser).pinnedMatters
    const newPinnedMatter: MatterPin = {
      id: props.matter.id,
      pinTime: nowEpoch(),
    }
    if (currentlyPinnedMatters) {
      const matterPins = currentlyPinnedMatters.filter(
        matterPin => matterPin.id !== props.matter.id,
      )

      await userDb.updateMatterPinTime([newPinnedMatter, ...matterPins])
    } else {
      await userDb.updateMatterPinTime([newPinnedMatter])
    }
    refreshUser()
  }
  const unpinMatter = async () => {
    const currentlyPinnedMatters = (user as SkribeUser).pinnedMatters || []
    const matterPins: MatterPin[] = currentlyPinnedMatters.map(
      (matterPin: MatterPin) => {
        return matterPin.id === props.matter.id
          ? { ...matterPin, pinTime: 0 }
          : matterPin
      },
    )
    await userDb.updateMatterPinTime(matterPins)
    refreshUser()
  }

  function shouldShowPinIcon(pinFlag: boolean) {
    return Boolean(pinFlag && props.matter.pinTime && props.matter.pinTime > 0)
  }

  return { pinMatter, onShare, unpinMatter, shouldShowPinIcon }
}

export function createPinMatterDropdownItem(
  onPin: () => void,
): ItemType | null {
  return {
    key: 'pin',
    icon: <PushpinOutlined />,
    label: (
      <Typography.Text data-testid="pinMatterItem">Pin Matter</Typography.Text>
    ),
    onClick: onPin,
  }
}
