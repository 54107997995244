import { Playlist, PublicItem } from '@skribe/web/core'
import { publicAPI, secureAPI } from './client'

export type PublicPlaylist = Playlist & PublicItem

export async function fetchPlaylists(
  transcriptId: string,
): Promise<Playlist[]> {
  return (await secureAPI.get(
    'rev',
    `/playlists?transcriptId=${transcriptId}`,
    {},
  )) as Playlist[]
}

export async function addPlaylist(
  transcriptId: string,
  clipIds: string[],
  name: string,
) {
  return (await secureAPI.post('rev', `/playlists`, {
    body: {
      transcriptId,
      name,
      clipIds,
    },
  })) as Playlist
}
export async function deletePlaylist(transcriptId: string) {
  await secureAPI.del('rev', `/playlists/${transcriptId}`, {})
}
export async function patchPlaylist(
  playlistId: string,
  clipIds?: string[],
  name?: string,
) {
  return (await secureAPI.patch('rev', `/playlists/${playlistId}`, {
    body: {
      name,
      clipIds,
    },
  })) as Playlist
}

export async function removeClipFromPlaylist(
  playlistId: string,
  clipId: string,
) {
  return (await secureAPI.post('rev', `/playlists/${playlistId}/remove`, {
    body: {
      clipId,
    },
  })) as Playlist
}
export async function postPlaylistDownload(playlistId: string) {
  return secureAPI.post('rev', `/playlists/${playlistId}/download`, {})
}
export async function fetchPublicPlaylist(id: string | undefined) {
  return (await publicAPI.get(
    'rev',
    `/playlists/${id}/public`,
    {},
  )) as PublicPlaylist
}
