import { FC } from 'react'
import { List, Typography } from 'antd'
import { CalendarOutlined, UploadOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import { Event } from '@skribe/web/core'
import { toDate } from '@skribe/web/date'
import { ActionMenu, createDeleteDropdownItem } from '../components'
import { trackEvent } from '@skribe/web/tracking'

type EventItemProps = {
  event: Event
  onDelete: (id: string | undefined) => Promise<void>
}

export const EventItem: FC<EventItemProps> = ({
  event: { id, name, start },
  onDelete,
}) => {
  return (
    <List.Item
      actions={[
        <ActionMenu
          id={id}
          items={[createDeleteDropdownItem(() => onDelete(id))]}
        />,
      ]}
    >
      <List.Item.Meta
        avatar={
          start ? (
            <CalendarOutlined style={{ color: '6C757D' }} />
          ) : (
            <UploadOutlined style={{ color: '6C757D' }} />
          )
        }
        title={
          <Typography.Text ellipsis>
            <Link
              to={`/events/${id}`}
              onClick={() => {
                trackEvent('selectEvent', { id })
              }}
            >
              {name}
            </Link>
          </Typography.Text>
        }
        description={start && toDate(start).format('LLLL z')}
      />
    </List.Item>
  )
}
