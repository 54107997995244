import { useFeature } from 'flagged'
import { useLocation } from 'react-router-dom'
import { useIsTrial } from '../User/useIsTrial'

export function useChatPanel() {
  const useAiChat = useFeature('aiChat')
  let location = useLocation()
  const isTrial = useIsTrial()

  return useAiChat && location.pathname.includes('/transcripts') && !isTrial
}
