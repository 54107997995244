import Fuse from 'fuse.js'
import { useMemo } from 'react'

export function useFuzzySearch<T>(
  list: T[],
  { keys, threshold }: { keys: string[]; threshold: number },
) {
  const fuse = useMemo(() => {
    return new Fuse(list, {
      shouldSort: false,
      findAllMatches: true,
      threshold,
      ignoreLocation: true,
      keys,
    })
  }, [list, keys, threshold])

  return { fuse }
}
