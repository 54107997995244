import {
  toDuration,
  toHourString,
  toLocalDate,
  toLocalTime,
} from '@skribe/web/date'
import { Avatar, Button, Col, Row, Space } from 'antd'
import {
  CalendarOutlined,
  ClockCircleOutlined,
  HourglassOutlined,
  LinkOutlined,
} from '@ant-design/icons'
import { DetailedEvent } from '@skribe/web/core'
import { ZoomLiveMeetingButton } from './ZoomLiveMeetingButton'
import { useLiveEventDetails } from './useLiveEventDetails'
import { useFeature } from 'flagged'
import { useOnCopyNotification } from '../Notifications/onCopy'
import { RequestLiveMeetingAssistantButton } from './RequestLiveMeetingAssistantButton'
import { AddLiaisonField } from '../Liaison/AddLiaisonField'

export const LiveEventDetails: React.FC<{
  event: DetailedEvent
}> = ({ event }) => {
  const isSupport = useFeature('supportRole')
  const { onCopy, notificationContext } = useOnCopyNotification()
  const { copyShareableLink, onAddToCalendar } = useLiveEventDetails({
    event,
    onCopy,
  })

  const zoomEvent = event.zoom_event

  return (
    <>
      {notificationContext}
      <Row gutter={30}>
        {event.start && (
          <>
            <Col>
              <Space>
                <Avatar icon={<CalendarOutlined />} shape="square" />
                {toLocalDate(event.start)}
              </Space>
            </Col>
            <Col>
              <Space>
                <Avatar icon={<ClockCircleOutlined />} shape="square" />
                {toLocalTime(event.start)}
              </Space>
            </Col>
            {event.estimated_duration_in_minutes && (
              <Col>
                <Space>
                  <Avatar icon={<HourglassOutlined />} shape="square" />
                  {toHourString(
                    toDuration(event.estimated_duration_in_minutes),
                  )}
                </Space>
              </Col>
            )}
          </>
        )}
        <Col>
          {zoomEvent?.startUrl && (
            <ZoomLiveMeetingButton
              startDate={event.start}
              startUrl={zoomEvent.startUrl}
            />
          )}
        </Col>
        <Col>
          {event.start && (
            <Button onClick={onAddToCalendar}>Add to Calendar</Button>
          )}
          <Button
            icon={<LinkOutlined />}
            onClick={() => copyShareableLink(zoomEvent)}
            type="link"
          >
            Copy Live Event Link
          </Button>
          {isSupport && zoomEvent && (
            <RequestLiveMeetingAssistantButton
              zoomEvent={zoomEvent}
              event={event}
            />
          )}
          {isSupport && <AddLiaisonField event={event} />}
        </Col>
      </Row>
    </>
  )
}
