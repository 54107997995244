import React from 'react'
import { Alert, Col, Input, Row, Typography } from 'antd'
import { LoadingComponent, FormError, FormButtons } from '..'
import { useLibraryForm } from './useLibraryForm'
import { Uploader } from '../upload'
import { useUserContext } from '../../User/UserProvider'

type LibraryFormProps = {
  defaultMatterName?: string | null
  getEventId: (eventName: string) => Promise<string>
  onClose: () => void
  onSuccessfulSave: (eventId: string) => void
  metadata?: Record<string, string>
}

export const LibraryForm: React.FC<LibraryFormProps> = props => {
  const {
    loading,
    isTrial,
    fileTypeMessage,
    allowUpload,
    disabledUploadWarning,
    shouldShowForm,
    shouldShowError,
    name,
    removeUploadedFiles,
    onNameChange,
    onUploadStart,
    onUploadSuccess,
    onSave,
    onClose,
    disableSave,
    resetLoader,
  } = useLibraryForm(props)
  const { user } = useUserContext()
  const metadata = { ...props.metadata, eventName: name ?? '', userId: user.id }

  const messages = (
    <>
      {isTrial && (
        <Alert
          message={
            'Trial uploads are limited to one at a time and may be trimmed.'
          }
          type="warning"
          data-testid="isTrialWarning"
          showIcon
          style={{ marginTop: '1rem' }}
        />
      )}
      <Alert
        message={fileTypeMessage}
        type="info"
        showIcon
        style={{ marginTop: '1rem' }}
      />
    </>
  )

  return (
    <>
      {loading && <LoadingComponent />}
      {shouldShowForm && (
        <>
          <Row>
            <Col span={24}>
              {props.defaultMatterName && (
                <>
                  <Row>
                    <Typography.Text
                      className="form-title"
                      style={{ marginTop: '0.4rem' }}
                    >
                      Event Name
                    </Typography.Text>
                  </Row>
                  <Input
                    onChange={onNameChange}
                    value={name}
                    style={{ marginBottom: '2.4rem' }}
                  />
                </>
              )}
              <Row>
                <Typography.Text className="form-title">
                  Upload Media
                </Typography.Text>
              </Row>
              {allowUpload ? (
                <Uploader
                  maxCount={isTrial ? 1 : undefined}
                  onUploadStart={onUploadStart}
                  onUploadSuccess={onUploadSuccess}
                  resetLoader={resetLoader}
                  onRemove={removeUploadedFiles}
                  metadata={metadata}
                >
                  {messages}
                </Uploader>
              ) : (
                <Alert
                  type="warning"
                  message={disabledUploadWarning?.message}
                  description={disabledUploadWarning?.description}
                  showIcon
                />
              )}
            </Col>
          </Row>
          <FormButtons
            onClose={onClose}
            saveDisabled={disableSave}
            saveTestId="uploadScheduleEventButton"
            onSave={onSave}
          />
        </>
      )}
      {shouldShowError && (
        <FormError
          onClose={onClose}
          message="Something wrong happened when creating your event"
        />
      )}
    </>
  )
}
