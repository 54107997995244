import { FC } from 'react'
import { MonologueContent } from './MonologueContent'
import { Clip, MonologueTriad, OnSpeakerChange } from '@skribe/web/core'
import { MonologueTitle } from './MonologueTitle'

export const MonologueComponent: FC<{
  onSpeakerChange?: OnSpeakerChange
  monologueTriad: MonologueTriad
  seek?: (amount: number) => void
  clips?: Clip[]
  onElementClick?: (clipID?: string) => void
}> = props => {
  const currentMonologue = props.monologueTriad.currentMonologue

  function getFirstElementOnNextMonologue() {
    const nextMonologue = props.monologueTriad.nextMonologue
    if (nextMonologue === undefined) return undefined
    return nextMonologue.elements[0]
  }

  function getLastElementOnPreviousMonologue() {
    const previousMonologue = props.monologueTriad.previousMonologue
    if (previousMonologue === undefined) return undefined
    return previousMonologue?.elements[previousMonologue?.elements.length - 1]
  }

  return (
    <div
      className="dialogue-monologue"
      data-line-start={currentMonologue.start}
    >
      <MonologueTitle
        onSpeakerChange={props.onSpeakerChange}
        skribeMonologue={currentMonologue}
        seek={props.seek}
      />
      <MonologueContent
        currentMonologueElements={currentMonologue.elements}
        clips={props.clips}
        onElementClick={props.onElementClick}
        previousMonologueElement={getLastElementOnPreviousMonologue()}
        nextMonologueElement={getFirstElementOnNextMonologue()}
      />
    </div>
  )
}
