import { useFeature } from 'flagged'
import { useSelectionV1 } from './useSelectionV1'
import { useSelectionV2 } from './useSelectionV2'

export const useSelection = () => {
  const useV2 = useFeature('smartSelection')
  const v1 = useSelectionV1
  const v2 = useSelectionV2
  return useV2 ? v2() : v1()
}
