import {
  CopyOutlined,
  PlayCircleOutlined,
  VideoCameraAddOutlined,
} from '@ant-design/icons'
import { ItemType, MenuItemType } from 'antd/es/menu/hooks/useItems'
import { copyToClipboard, useSelection } from '@skribe/web'
import { DialogueProps } from './Dialogue'

export function dialogueRightClickActions(
  props: ReturnType<typeof useSelection>,
  seek: DialogueProps['seek'],
  clip: DialogueProps['clip'],
) {
  const { selection, transcriptText } = props
  //TODO: Fix typescript when flag is removed and useSelectionV1 deleted
  const fullText = (props as any)?.fullText

  return [
    clip && {
      key: '1',
      icon: <VideoCameraAddOutlined />,
      label: 'Create Clip',
      onClick: () =>
        selection &&
        transcriptText &&
        clip(selection.start, selection.end, transcriptText),
    },
    seek && {
      key: '2',
      icon: <PlayCircleOutlined />,
      label: 'Play',
      onClick: () => selection && seek(selection.start),
    },
    fullText && {
      key: '3',
      icon: <CopyOutlined />,
      label: 'Copy',
      onClick: () => transcriptText && copyToClipboard(fullText),
    },
  ].filter(Boolean) as ItemType<MenuItemType>[]
}
