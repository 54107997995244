import { Drawer, Tabs } from 'antd'
import React from 'react'

export const defaultDrawerProps: React.ComponentProps<typeof Drawer> = {
  placement: 'right',
  closable: false,
  destroyOnClose: true,
  maskClosable: false,
}
export const defaultTabsProps: React.ComponentProps<typeof Tabs> = {
  size: 'large',
}
