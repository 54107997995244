import { Col, List, Row, Slider, Tooltip } from 'antd'
import { EditableText } from 'src/app/components'
import { ClipEndInput, ClipStartInput } from './ClipInput'
import { getDefaultClipRangeEnd } from './getDefaultClipRangeEnd'
import { useClipControls } from './UseClipControls'
import { timestamp } from 'src/app/utilities/timeUtilities'
import React, { useState } from 'react'
import { ClipCreationButton } from './ManualClipCreator'

const DEFAULT_CLIP_NAME = 'Untitled Manual Clip'
export function ManualClipCreator2(props: {
  onCreate: (start: number, end: number, name: string) => void
  duration: number
}) {
  const duration = Math.floor(props.duration)
  const initialRange = [0, getDefaultClipRangeEnd(duration)]
  const [name, setName] = useState(DEFAULT_CLIP_NAME)
  const {
    range,
    setRange,
    step,
    startMax,
    onStartInputChange,
    onEndInputChange,
  } = useClipControls({ initialRange, duration })

  function onCreate() {
    props.onCreate(range[0], range[1], name)
    setName(DEFAULT_CLIP_NAME)
  }

  return (
    <List.Item>
      <Row style={{ width: '100%' }}>
        <Col
          span={2}
          style={{
            display: 'flex',
            justifyContent: 'space-around',
            alignItems: 'center',
          }}
        >
          <Tooltip title="Create new Playlist">
            <ClipCreationButton createClip={onCreate} />
          </Tooltip>
        </Col>
        <Col offset={1} span={20}>
          <Row style={{ width: '100%' }} justify={'space-between'} wrap={true}>
            <Col span={12} style={{ width: '100%' }}>
              <Row wrap={false}>
                <ClipStartInput
                  step={step}
                  range={range}
                  max={startMax}
                  onChange={onStartInputChange}
                />
                -
                <ClipEndInput
                  step={step}
                  range={range}
                  max={duration}
                  onChange={onEndInputChange}
                />
              </Row>
            </Col>
            <Col span={11}>
              <Slider
                range
                min={0}
                className="manual-clip-creator-slider"
                step={step}
                data-testid="slider"
                max={duration}
                onChange={setRange}
                value={range}
                tooltip={{ formatter: timestamp }}
              />
            </Col>
          </Row>
          <Row>
            <EditableText startingValue={name} onEdit={setName} />
          </Row>
        </Col>
      </Row>
    </List.Item>
  )
}
