export enum ERROR_STREAM {
  UNKNOWN = 'Err(0)',
  MAX_TOKEN = 'Err(1)',
}

export async function writeAnswer(
  reader: ReadableStreamDefaultReader<Uint8Array>,
  setAnswer: (value: (prevState: string) => string) => void,
) {
  try {
    while (true) {
      const { done, value } = await reader.read()
      if (done) return

      const partialAnswerChunkText = parseText(value)
      if (partialAnswerChunkText === ERROR_STREAM.UNKNOWN) {
        throw new Error('There was a problem processing your question')
      }
      if (partialAnswerChunkText === ERROR_STREAM.MAX_TOKEN) {
        throw new Error('Transcript size exceeded limit')
      }

      setAnswer((currentText: string) => currentText + partialAnswerChunkText)
    }
  } catch (error) {
    console.error('Error on writeAnswer [AI]', error)
    throw error
  }
}
function parseText(value: Uint8Array | undefined) {
  return new TextDecoder('utf-8').decode(value)
}
