import * as GA from '../GoogleAnalytics'
import * as PH from '../PostHog'
import { DetailedUser } from 'src/core/user'

export function initialize() {
  PH.initialize()
  GA.initialize()
}

export const trackPageView = () => {
  PH.trackPageView()
  GA.trackPageView()
}

export const trackEvent = (eventName: string, properties = {}) => {
  PH.trackEvent(eventName, properties)
  GA.trackEvent(eventName, properties)
}

export function setTrackedUser(user?: DetailedUser) {
  PH.setTrackedUser(user)
  GA.setTrackedUser(user)
}
