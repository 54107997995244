export function scrollToElementWithClass(
  className: string,
  options: ScrollIntoViewOptions = { block: 'center', behavior: 'auto' },
) {
  const element = document.getElementsByClassName(className)[0]
  if (!element) {
    console.warn('No element found to scroll to', { className, options })
    return
  }
  element.scrollIntoView(options)
}
