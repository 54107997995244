import React, { useState } from 'react'
import { Button, Modal, Tooltip } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import { trackEvent } from '@skribe/web/tracking'

export function AiDisclaimer() {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const showModal = () => {
    setIsModalOpen(true)
  }

  const handleOk = () => {
    setIsModalOpen(false)
  }

  return (
    <>
      <Tooltip title={'AI Information'}>
        <Button
          icon={<InfoCircleOutlined />}
          className={'button-discrete'}
          onClick={() => {
            trackEvent('viewAiDisclaimer')
            showModal()
          }}
        />
      </Tooltip>
      <Modal
        width={'20%'}
        title="AI Usage Information"
        style={{ left: '33.5%' }}
        open={isModalOpen}
        okText={'Close'}
        onOk={handleOk}
        closeIcon={null}
        footer={(_, { OkBtn }) => <OkBtn />}
      >
        <div className="chat-placeholder-text">
          <p>
            Skribe uses a customized version of OpenAI's GPT-4o for post event
            analysis and answering your questions. Our chat-based Generative AI
            assistant ensures you get accurate, high-quality responses focused
            on the details in your event. You will also be shown direct links to
            your media content so you can you can check the sources for your
            information easily.
          </p>
          <p>
            We are focused on protecting the security and privacy of your data
            by using dedicated servers, ensuring your information isn't used for
            any external training or shared with third parties.
          </p>
        </div>
      </Modal>
    </>
  )
}
