export function querySelectorFirst(q: string) {
  return window.document.querySelectorAll(q)[0]
}
export function removeEventListener(
  e: string,
  handleSelection: EventListenerOrEventListenerObject,
) {
  window.document.removeEventListener(e, handleSelection)
}
export function addEventListener(
  e: string,
  handleSelection: EventListenerOrEventListenerObject,
) {
  window.document.addEventListener(e, handleSelection)
}
export function getWordAndSignElements(ref: React.RefObject<HTMLDivElement>) {
  return ref?.current?.getElementsByTagName('span')
}
export function getSelectedElements(
  children: HTMLCollectionOf<HTMLSpanElement>,
  selection: Selection,
) {
  return Array.from(children)
    .reduce<Element[]>((elements, element) => {
      return selection.containsNode(element, true)
        ? [...elements, element]
        : elements
    }, [])
    .filter(ts => ts !== null)
}

export function getSelection() {
  return window.document.getSelection()
}

export function getSelectedText(allSelectedElements: Element[]) {
  return allSelectedElements
    .filter(node => node.getAttribute('data-type'))
    .map(node => node?.childNodes[0]?.textContent)
    .join('')
}

export function forceClick(end: number) {
  const element = querySelectorFirst(`[data-end-ts='${end}']`)

  const ev3 = new MouseEvent('contextmenu', {
    bubbles: true,
    cancelable: false,
    view: window,
    button: 2,
    buttons: 0,
    clientX: element.getBoundingClientRect().x,
    clientY: element.getBoundingClientRect().y,
  })
  element.dispatchEvent(ev3)
}
