import { Card } from 'antd'
import React, { ReactElement } from 'react'
import { useAiChat } from './useAiChat'
import { ChatBody } from './components/ChatBody'
import './AiChat.css'
import { PromptBox } from './components/PromptBox'

export interface AiChatProps {
  transcriptId: string
}

export function AiChat(props: AiChatProps): ReactElement {
  const {
    history,
    question,
    setQuestion,
    completeQuestion,
    isLoading,
    noQuestion,
    askQuestion,
    error,
    response,
  } = useAiChat(props.transcriptId)

  return (
    <div className="chat-tab">
      <Card id="chat-card">
        <ChatBody
          response={response}
          showSpinner={isLoading}
          question={completeQuestion}
          error={error}
          history={history}
        />
      </Card>
      <PromptBox
        askQuestion={askQuestion}
        isLoading={isLoading}
        noQuestion={noQuestion}
        onChange={setQuestion}
        question={question}
      />
    </div>
  )
}
