import { useFeature } from 'flagged'
import { ClipItemProps, useClipItem } from './UseClipItem'
import { EditableClipTime } from './ClipTime'
import { ClipActionButtons } from './ClipActionButtons'
import { BaseClipItem } from './BaseClipItem'

export function ClipItem(props: ClipItemProps) {
  const shouldUseEditMode = useFeature('editClips')
  const {
    editModeSelected,
    openEditMode,
    editClipLength,
    closeEditMenu,
    addToPlaylist,
    requestDownload,
  } = useClipItem(props)

  return (
    <BaseClipItem
      selectedClipId={props.selectedClipId}
      onSelect={() => props.selectClip(props.clip)}
      clip={props.clip}
      playing={props.isPlaying}
      onPause={props.onPause}
      onPlay={props.onPlay}
      editText={props.editTitle}
      clipTime={
        <EditableClipTime
          clip={props.clip}
          max={props.transcriptDuration}
          shouldUseEditMode={!!shouldUseEditMode}
          editModeSelected={editModeSelected}
          onCancel={closeEditMenu}
          onConfirm={editClipLength}
        />
      }
      clipActionButtons={
        <ClipActionButtons
          clip={props.clip}
          onCopy={props.onCopy}
          onDelete={props.onDelete}
          playlists={props.playlists}
          requestDownload={requestDownload}
          shouldUseEditMode={!!shouldUseEditMode}
          editModeSelected={editModeSelected}
          openEditMode={openEditMode}
          addToPlaylist={addToPlaylist}
        />
      }
    />
  )
}
