import { PauseCircleFilled, PlayCircleOutlined } from '@ant-design/icons'

export function PlayPauseButton(props: {
  isSelected: boolean
  playing: boolean
  onPause: () => void
  onPlay: () => void
}) {
  return (
    <div className={'play-pause'}>
      {props.isSelected && props.playing ? (
        <PauseCircleFilled
          data-testid={'clipPlayingIcon'}
          onClick={props.onPause}
        />
      ) : (
        <PlayCircleOutlined
          data-testid={'playClipIcon'}
          onClick={props.onPlay}
        />
      )}
    </div>
  )
}
