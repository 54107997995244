import { Clip, MonologueElement } from '@skribe/web/core'
import classnames from 'classnames'

export const MonologueElementComponent: React.FC<{
  element: MonologueElement
  index: number
  clip?: Clip
  onElementClick?: (clipID?: string) => void
}> = ({ element, index, clip, onElementClick }) => {
  const className = classnames({
    'highlight-clip': !!clip,
  })
  return element.type === 'text' ? (
    <span
      className={className}
      key={index}
      data-type={element.type}
      data-ts={element.ts}
      data-end-ts={element.end_ts}
      onClick={() => onElementClick && onElementClick(clip?.id)}
    >
      {element.value}
    </span>
  ) : (
    <span key={index} data-type={element.type} className={className}>
      {element.value}
    </span>
  )
}
