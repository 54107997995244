import { useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { Playlist, areAnyProcessing, Clip } from '@skribe/web/core'
import { playlistDb } from '@skribe/web/api'

export function usePlaylistQuery(props: {
  transcriptId: string | undefined
  clips: Clip[]
}) {
  const [playlistIsDownloading, setPlaylistIsDownloading] = useState(false)

  const query = useQuery({
    queryKey: [
      'playlists',
      props.transcriptId,
      `refresh-when-deleting-clip-ids-${props.clips.length}`,
    ],
    initialData: [],
    refetchOnWindowFocus: false,
    queryFn: async (): Promise<Playlist[]> => {
      return await retrievePlaylist(
        props.transcriptId,
        props.clips,
        setPlaylistIsDownloading,
      )
    },
    refetchInterval: playlistIsDownloading && 5000,
  })
  return {
    playlists: query.data,
    ...query,
  }
}

export async function retrievePlaylist(
  transcriptId: string | undefined,
  allClips: Clip[],
  setPlaylistIsDownloading: (value: boolean) => void,
) {
  if (!transcriptId) return []
  const playlistList = await playlistDb.fetchPlaylists(transcriptId)
  setPlaylistIsDownloading(areAnyProcessing(playlistList))

  return playlistList.map(filterOutDeletedClips(allClips))
}

function filterOutDeletedClips(allClips: Clip[]) {
  return (playlist: Playlist): Playlist => {
    const matchingClipIds = playlist.clipIds?.filter(id =>
      allClips.find(existingClip => existingClip.id === id),
    )
    return { ...playlist, clipIds: matchingClipIds }
  }
}
