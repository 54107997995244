import { Empty, ListProps } from 'antd'

export function generateEmptyPlaceholder(
  description: string,
): ListProps<unknown>['locale'] {
  return {
    emptyText: (
      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={description} />
    ),
  }
}
