import { AdminPortal, useAuth, useLoginActions } from '@frontegg/react'
import { useEffect, useState } from 'react'

export const useUser = () => {
  const auth = useAuth()
  const token = auth.user?.accessToken
  const [isStillLoading, setIsStillLoading] = useState(true)
  const signOut = useSignOut()
  useEffect(() => {
    if (token) {
      saveAccessToken(token)
      setIsStillLoading(false)
    }
  }, [token, setIsStillLoading])

  return {
    email: auth.user?.email,
    signOut,
    isStillLoading,
    isAuthenticated: !isStillLoading && auth.isAuthenticated,
    isUnAuthenticated: !auth.isAuthenticated,
    openSettings: () => {
      AdminPortal.show()
    },
  }
}

function useSignOut() {
  const { logout } = useLoginActions()
  return () => {
    logout()
    deleteAccessToken()
  }
}

export async function getJwtToken(): Promise<string> {
  const token = localStorage.getItem('fronteggAccessToken')
  if (!token) throw Error('No access token set up')
  return token
}

function saveAccessToken(accessToken: string) {
  localStorage.setItem('fronteggAccessToken', accessToken)
}
export function deleteAccessToken() {
  localStorage.removeItem('fronteggAccessToken')
}
