import { Tooltip } from 'antd'
import './Handle.css'
import { RiArrowLeftFill, RiArrowRightFill } from '@remixicon/react'

type HandleProps = {
  onLeft: () => void
  leftStyle?: React.CSSProperties
  leftTooltip: string
  onRight: () => void
  rightStyle?: React.CSSProperties
  rightTooltip: string
}

export function Handle(props: HandleProps) {
  return (
    <span className={'dialogue-handle'}>
      <Tooltip title={props.leftTooltip}>
        <button
          className="handle handle-left"
          style={{ ...props.leftStyle }}
          onClick={props.onLeft}
        >
          <RiArrowLeftFill className="icon" />
        </button>
      </Tooltip>
      <span className="stick"></span>
      <Tooltip title={props.rightTooltip}>
        <button
          style={{ ...props.rightStyle }}
          className="handle handle-right"
          onClick={props.onRight}
        >
          <RiArrowRightFill className="icon" />
        </button>
      </Tooltip>
    </span>
  )
}
