import { Button, Card, Col, Image, Popover, Row, Typography } from 'antd'
import { trackEvent } from '@skribe/web/tracking'

export const PaintedDoor: React.FC = () => {
  return (
    <Row justify="center" align="middle" style={{ marginTop: '100px' }}>
      <Card bodyStyle={{ padding: '1rem' }} style={{ width: '65rem' }}>
        <Row justify="space-around" align="middle">
          <Col>
            <Image src="/icosagon.svg" alt="Icosagon" preview={false} />
          </Col>
          <Col>
            <Row>
              <Typography.Text style={{ fontWeight: 'bold' }}>
                Coming soon...
              </Typography.Text>
            </Row>
            <Row>
              <Typography.Text>
                Do you want your transcripts to have a human touch? <br />
                We'll have them proofread by a real person.
                <br />
              </Typography.Text>
            </Row>
          </Col>
          <Col>
            <Popover
              content={
                <div>
                  We will let you know when this is ready to try out, Thanks!
                </div>
              }
              title="Request Granted!"
              trigger="click"
            >
              <Button
                type="primary"
                onClick={() => {
                  trackEvent('wantsHumanProofRead')
                }}
              >
                Notify me
              </Button>
            </Popover>
          </Col>
        </Row>
      </Card>
    </Row>
  )
}
