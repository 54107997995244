import { DetailedEvent } from '@skribe/web/core'
import { useUserContext } from '../User/UserProvider'
import { trackEvent } from '@skribe/web/tracking'
import { atcb_action } from 'add-to-calendar-button'
import { addMinutesToDate, SkribeDateStarter, toDate } from '@skribe/web/date'
import { copyToClipboard } from '@skribe/web'

export type LiveEventDetailsProps = {
  event: DetailedEvent
  onCopy?: () => void
}

export function useLiveEventDetails({ event, onCopy }: LiveEventDetailsProps) {
  const { name, start, zoom_event, estimated_duration_in_minutes } = event
  const { user } = useUserContext()

  return {
    copyShareableLink,
    onAddToCalendar,
  }

  function copyShareableLink(zoom_event: any): void {
    if (zoom_event) {
      trackEvent('copyMeetingLink', { id: event.id })
      const link = getLink(zoom_event)
      copyToClipboard(link)
      onCopy && onCopy()
    } else {
      console.warn('No zoom_event')
    }
  }

  function getLink(zoom_event: any) {
    if (zoom_event.joinUrl) return zoom_event.joinUrl

    let meetingNumber = zoom_event.meetingNumber
    let password = zoom_event.password
    let url = new URL(`https://skribe-ai.zoom.us/j/${meetingNumber}`)
    url.searchParams.append('pwd', password)
    const link = url.toString()
    return link
  }

  async function onAddToCalendar() {
    if (zoom_event && start && estimated_duration_in_minutes) {
      trackEvent('addToCalendar', {
        id: event.id,
        start,
        duration: estimated_duration_in_minutes,
      })
      addToCalendar(
        name ?? 'Skribe Event',
        start,
        estimated_duration_in_minutes,
        getLink(zoom_event),
        event?.invitationData,
        user.email,
      )
    } else {
      console.warn('No live event details')
    }
  }
}

function addToCalendar(
  name: string,
  start: string,
  minutes: number,
  meetingUrl: string,
  invitationData: null | string | undefined,
  email: string,
) {
  const startDate = toDayFormat(start)
  const endDate = addMinutesToDate(toDate(start), minutes)

  atcb_action({
    name,
    startDate,
    startTime: toTimeFormat(start),
    endDate: toDayFormat(endDate),
    endTime: toTimeFormat(endDate),
    timeZone: 'currentBrowser',
    location: meetingUrl,
    description: invitationData
      ? `Skribe${invitationData}`
      : `You are invited to a Skribe Event for ${name}.`,
    organizer: 'Skribe|contact@skribe.ai',
    attendee: `Client|${email}`,
    iCalFileName: `${name} ${startDate} Skribe Event `,
    listStyle: 'overlay',
    hideBackground: true,
    hideCheckmark: true,
    options: [
      'Microsoft365',
      'Google',
      'Apple',
      'MicrosoftTeams',
      'Outlook.com',
      'Yahoo',
      'iCal',
    ],
  })
}

const toDayFormat = (value: SkribeDateStarter) =>
  toDate(value).format('YYYY-MM-DD')

const toTimeFormat = (value: SkribeDateStarter) => toDate(value).format('HH:mm')
