import { Dropdown } from 'antd'
import { FC } from 'react'
import './Dialogue.css'
import {
  buildSkribeMonologues,
  Clip,
  createSkribeMonologues,
  Monologue,
  OnSpeakerChange,
  Speaker,
} from '@skribe/web/core'
import { MonologueComponent } from './Monologue/MonologueComponent'
import { useSelection } from '@skribe/web'
import { dialogueRightClickActions } from './dialogueRightClickActions'

export type DialogueProps = {
  monologues: Monologue[]
  clips?: Clip[]
  speakers: Speaker[]
  textSize?: string
  seek?: (amount: number) => void
  clip?: (start: number, end: number, name: string) => void
  onSpeakerChange?: OnSpeakerChange
  onElementClick?: (clipID?: string) => void
}

export const Dialogue: FC<DialogueProps> = ({
  monologues,
  clips,
  speakers,
  seek,
  clip,
  onSpeakerChange,
  onElementClick,
}) => {
  const dialogueMonologues = createSkribeMonologues(monologues, speakers)
  const selectionProps = useSelection()

  return (
    <Dropdown
      menu={{ items: dialogueRightClickActions(selectionProps, seek, clip) }}
      trigger={['contextMenu']}
    >
      <div className="dialogue" data-testid="dialogue" ref={selectionProps.ref}>
        {dialogueMonologues.map((skribeMonologue, index) => (
          <MonologueComponent
            key={index}
            onSpeakerChange={onSpeakerChange}
            monologueTriad={buildSkribeMonologues(index, dialogueMonologues)}
            seek={seek}
            clips={clips}
            onElementClick={onElementClick}
          />
        ))}
      </div>
    </Dropdown>
  )
}
