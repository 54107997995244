import React, { useState } from 'react'
import { Form, Input, Button, Drawer, Tabs, notification } from 'antd'
import { addMeetingAssistantToLiveMeeting } from '@skribe/web/recorder'
import { defaultDrawerProps, defaultTabsProps } from '../antd'
import { FormButtons } from '../FormButtons'

interface FormShape {
  title: string
  meetingUrl: string
}
enum TAB {
  ADD_MEETING = 'Add external meeting',
}

export const AddMeetingAssistantDrawer: React.FC = () => {
  const [tab, setTab] = useState<TAB | undefined>(undefined)
  const [form] = Form.useForm<FormShape>()
  const [api, contextHolder] = notification.useNotification()

  const openNotificationWithIcon = () => {
    api.error({
      message: 'There was an error adding the meeting assistant',
    })
  }

  const onClose = () => setTab(undefined)
  const onOpen = () => setTab(TAB.ADD_MEETING)

  const onFinish = async (values: FormShape) => {
    try {
      await addMeetingAssistantToLiveMeeting({
        title: values.title,
        meetingURL: values.meetingUrl,
      })
      onClose()
    } catch (error) {
      console.error(error)
      openNotificationWithIcon()
    }
  }
  return (
    <>
      {contextHolder}
      <Button onClick={onOpen} style={{ marginLeft: '1rem' }}>
        Add External Meeting
      </Button>
      <Drawer
        {...defaultDrawerProps}
        onClose={onClose}
        open={!!tab}
        className="tab-drawer one-tab-drawer"
      >
        <Tabs
          {...defaultTabsProps}
          defaultActiveKey={tab}
          items={[
            {
              key: TAB.ADD_MEETING,
              label: TAB.ADD_MEETING,
              children: (
                <Form
                  form={form}
                  onFinish={onFinish}
                  layout="vertical"
                  autoComplete="off"
                >
                  <Form.Item
                    label="Title"
                    name="title"
                    rules={[
                      { required: true, message: 'Please input a title' },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Meeting URL"
                    name="meetingUrl"
                    rules={[
                      {
                        required: true,
                        message: 'Please input the meeting URL!',
                      },
                      { type: 'url' },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <FormButtons
                    onClose={onClose}
                    saveDisabled={false}
                    saveTestId="assistantSaveButton"
                    onSave={form.submit}
                  />
                </Form>
              ),
            },
          ]}
        />
      </Drawer>
    </>
  )
}
