import { FC } from 'react'
import { Image, List, Tag, Typography } from 'antd'
import { Link } from 'react-router-dom'
import { Transcript, colors } from '@skribe/web/core'

export const TranscriptItem: FC<{
  item: Transcript
  navigateToTranscript: () => void
}> = ({ item, navigateToTranscript }) => {
  let tag = renderTag(item)

  return (
    <List.Item actions={[tag]} onClick={navigateToTranscript}>
      <List.Item.Meta
        avatar={
          <Image
            src="/transcript-item.svg"
            alt="Transcript"
            preview={false}
            style={{ color: '6C757D' }}
          />
        }
        title={
          <Typography.Text ellipsis>
            <Link to={''}>{item.name}</Link>
          </Typography.Text>
        }
      />
    </List.Item>
  )
}

function renderTag(item: Transcript) {
  let tag

  switch (item.status) {
    case 'pending':
      tag = <Tag color={colors.processing}>call started</Tag>
      break
    case 'in_progress':
      tag = <Tag color={colors.processing}>processing</Tag>
      break
    case 'failed':
      tag = <Tag color={colors.error}>error</Tag>
      break
    case 'transcribed':
    case 'completed':
      tag = <Tag color={colors.primary}>complete</Tag>
      break
  }
  return tag
}
